import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import admin from "./admin";
import reviewer from "./reviewer"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        admin,
        reviewer
      },

    state:{
        userData:"",
        token:sessionStorage.getItem("bmgaToken"),
        applications:[],
        application:"",
        adminToken:sessionStorage.getItem("adminToken"),
        reviewerToken: sessionStorage.getItem("reviewerToken"),
        drawer:false,
        settings:""
    },
    mutations:{
        fetchUser(state){
            axios({
                method:"GET",
                url:process.env.VUE_APP_API_URL + "/user",
                headers:{
                    Authorization:"Bearer "+state.token,
                    ContentType:"application/json",
                    Accept:"*/*"
                },
            }).then((response)=>{
                console.log("userData", response.data)
                state.userData = response.data.data
                console.log(state.userData)
            })
        },
        fetchApplications(state){
            axios({
                method:"GET",
                url:process.env.VUE_APP_API_URL + "/fetch-applications",
                headers:{
                    Authorization:"Bearer "+state.token,
                    ContentType:"application/json",
                    Accept:"*/*"
                }
            }).then((response)=>{
                console.log("applications", response.data)
                state.applications = response.data.data
                state.application = response.data.data[0]
                
            })
        },

        fetchAdminApplication(state,value) {
            state.loading = true
            axios({
              method: "GET",
              url: process.env.VUE_APP_API_URL + "/admin/fetch-application?id="+value,
              headers: {
                Accept: "*/*",
                ContentType: "application/json",
                Authorization: `Bearer ${state.adminToken}`,
              },
            })
              .then((response) => {
                state.application = response.data.data
              })
              .catch((error) => {
                console.log(error);
              });
          },

          fetchReviewerApplication(state,value) {
            state.loading = true
            axios({
              method: "GET",
              url: process.env.VUE_APP_API_URL + "/reviewer/fetch-application?id="+value,
              headers: {
                Accept: "*/*",
                ContentType: "application/json",
                Authorization: `Bearer ${state.reviewerToken}`,
              },
            })
              .then((response) => {
                state.application = response.data.data
              })
              .catch((error) => {
                console.log(error);
              });
          },

          fetchSettings(state){
            axios({
              method:"GET",
              url:process.env.VUE_APP_API_URL + "/fetch-settings",
            }).then((response)=>{
              state.settings = response.data.data
            })
          },

          showMenu(state){
            state.drawer = !state.drawer
          }
    },
    actions:{
        fetchUser:(context,value)=>{
            context.commit("fetchUser", value);
        },
        fetchApplications:(context,value)=>{
            context.commit("fetchApplications",value)
        },

        fetchAdminApplication:(context,value)=>{
            context.commit("fetchAdminApplication",value)
        },
        fetchReviewerApplication:(context,value)=>{
            context.commit("fetchReviewerApplication",value)
        },
        showMenu:(context,value)=>{
          context.commit("showMenu",value)
      },
      fetchSettings:(context,value)=>{
        context.commit("fetchSettings",value)
      }
    }
})