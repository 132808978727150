<template>
    <v-app>
      <v-main class="hidden-md-and-down">
        <v-row style="background: #f8f8f9; height: 101%">
          <v-col cols="12" md="1" sm="1" lg="1">
            <AdminSideBar />
          </v-col>
          <v-col cols="12" md="11" sm="11" lg="11">
            <AppBar />
            <ApplicationComponent :settings="admin.settings"/>
          
          </v-col>
        </v-row>
      </v-main>
    </v-app>
  </template>
  
  
  <script lang="ts">
  import AdminSideBar from "../../components/Admin/AdminSideBar.vue";
  import ApplicationComponent from "@/components/Admin/Application/ApplicationStage/ApplicationComponent.vue";
  import AppBar from "@/components/Admin/AppBar.vue";
  import { mapState } from 'vuex';
  
  export default {
    components: {
      AdminSideBar,
      ApplicationComponent,
      AppBar,
    },

    computed:{
      ...mapState(
        ['admin']
      )
    },
  
    created() {
        this.$store.dispatch("admin/fetchAdminApplicationStatus", "Submitted")
        this.$store.dispatch("admin/fetchAdminReviewers")
        this.$store.dispatch("admin/fetchSettings")
      if(sessionStorage.getItem("adminToken") == null){
            this.$router.push("/admin/login")
          }
    },
  };
  </script>
  
  <style scoped>
  </style>