<template>
  <div>
    <div class="sideBar hidden-md-and-down">
      <div class="text-center">
        <v-card
          tile
          :class="page.substring(1) == 'dashboard' ? 'active' : ''"
          @click="$router.push('/dashboard')"
          class="pa-5"
        >
          <v-icon :color="page.substring(1) == 'dashboard' ? 'white' : ''"
            >mdi-view-grid-outline</v-icon
          >
          <p>Dashboard</p>
        </v-card>

        <v-card
          tile
          :class="page.substring(1) == 'applications' ? 'active' : ''"
          @click="goToApplications"
          class="pa-5 my-6"
        >
          <v-icon :color="page.substring(1) == 'applications' ? 'white' : ''"
            >mdi-email</v-icon
          >
          <p>Application {{ userData.status }}</p>
        </v-card>

        <v-card
          :class="page.substring(1) == 'profile' ? 'active' : ''"
          tile
          @click="$router.push('/profile')"
          class="pa-5"
        >
          <v-icon :color="page.substring(1) == 'profile' ? 'white' : ''"
            >mdi-account</v-icon
          >
          <p>Profile</p>
        </v-card>
      </div>
    </div>

    <v-navigation-drawer :value="drawer" absolute left temporary>
      <v-list>
        <v-list-item-group v-model="group">
          <v-list-item @click="goToPage('/dashboard')">
           Dashboard
          </v-list-item>

          <v-list-item @click="goToPage('/applications')">
              Application
          </v-list-item>

          <v-list-item @click="goToPage('/profile')">
              Profile
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>



<script>
import { mapState } from 'vuex';
export default {
  data:()=>({
    group:null
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  props: {
    page: {
      type: String,
    },
    userData: {
      type: [Object,String]
    },
    application:{
      type:[Object,String]
    }
  },
  computed:{
    ...mapState({
        drawer:"drawer",
        settings:"settings"
    })
  },
  methods: {
    goToPage(e) {
      this.$router.push(e);
      this.$store.dispatch("showMenu")
    },
    goToApplications() {
      if (this.userData.eligibilityStatus === "") {
        this.$router.push("/eligibility-check");
        return;
      }

      const time = Date.parse(this.settings.applicationDeadline)
      console.log(time)
      if(Date.now() > time){
        this.$swal({
          icon:"info",
          title:"Deadline Passed",
          text:"You cannot proceed with this application because the application deadline has passed. Kindly try again next year.",
          confirmButtonText:`<p class='brandcolor'>Ok</p>`
        })
        return
      }

  

      if (this.userData.eligibilityStatus === "false") {
        this.$swal({
          icon: "error",
          title: "Not Eligible",
          text: "You're not eligible to apply for the BMGA Fellowship. Kindly try again later",
          confirmButtonText: `<p class="brandcolor">Ok</p>`,
        });
        return;
      }

      if (this.userData.eligibilityStatus == "true") {
        this.$router.push("/applications");
      }
    },
  },
};
</script>

<style scoped>
.sideBar {
  position: absolute;
  left: 0;
  top:20%
}

.active {
  background: #4c248a !important;
  color: white !important;
}
</style>