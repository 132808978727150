<template>
  <v-main style="margin: 50px 0px 0px 200px">
    <div>
      <h1 class="my-3" style="color: #310059">
        Applications:
        {{
          $route.params.id == "1"
            ? "Review - Level 1"
            : $route.params.id == "2"
            ? "Semi-Final"
            : $route.params.id == "3"
            ? "Review - Level 2"
            : $route.params.id == "4"
            ? "Final"
            : $route.params.id == "5"
            ? "Enrolled"
            : $route.name == "PendingApplication"
            ? "Pending"
            : "Submitted"
        }}
      </h1>
      
      <TableFilter
        :showStatus="false"
        @assignReviewer="assignReviewer"
        @emailWaitlist="emailWaitlist"
        @emailReminder="emailReminder"
        :btnloading="loading"
        @searchList="searchList"
        @filterBy="filterBy"
        :stage="$route.params.id"
        @emailAcceptance="emailAcceptance"
        @emailRejection="emailRejection"
        @emailEnrolled="emailEnrolled"
        :hideBtn="hideBtn"
      />
      <ApplicationTable
        :reviewers="admin.reviewers"
        :applications="admin.stageapplications"
        @showSelected="showSelected"
        :selectedApp="selectedApp"
        @assignReviewer="assignReviewer"
        :assignreviewer="assignreviewer"
      />
      <v-progress-circular
        color="#310059"
        title="fetching data"
        v-if="admin.statusloading"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-main>
</template>



<script lang="ts">
import ApplicationTable from "./../ApplicationTable.vue";
import TableFilter from "./../TableFilter.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
  components: { ApplicationTable, TableFilter },
  computed: {
    ...mapState(["admin"]),
  },
  data: () => ({
    number: 1,
    selected: [],
    loading: false,
    assignreviewer: false,
    selectedApp: [],
  }),

  props: {
    applications: {
      type: Array,
    },
    settings: {
      type: [Object, String],
    },
    hideBtn: {
      type: Boolean
    }
  },
  methods: {
    emailAcceptance() {
      console.log(this.selected);
      this.selected = this.selected.filter(Boolean);
      if (this.selected.length == 0) {
        this.$swal({
          icon: "info",
          title: "Invalid Request",
          text: "You need to select candidates you would like to email",
          confirmButtonText: `<p class='brandcolor'>Ok</p>`,
        });
        return;
      }
      //send acceptance email in bulk
      console.log(this.settings.acceptanceEmail);
      this.loading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/send-emails",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adminToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: {
          title: "[Update] Your Application for the BMGA Fellows Program",
          body: this.settings.acceptanceEmail,
          recipients: this.selected,
          status: "Accepted",
        },
      })
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Email sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          }).then(() => {
            location.reload();
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Something went wrong!",
            text: "Email was not sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          });
        });
    },

    emailRejection() {
      console.log(this.selected);
      this.selected = this.selected.filter(Boolean);
      if (this.selected.length == 0) {
        this.$swal({
          icon: "info",
          title: "Invalid Request",
          text: "You need to select candidates you would like to email",
          confirmButtonText: `<p class='brandcolor'>Ok</p>`,
        });
        return;
      }
      console.log(this.settings.rejectionEmail);
      this.loading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/send-emails",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adminToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: {
          title: "[Update] Your Application for the BMGA Fellows Program",
          body: this.settings.rejectionEmail,
          recipients: this.selected,
          status: "Rejected",
        },
      })
        .then((response) => {
          this.loading = false;
          this.$swal({
            icon: "success",
            title: "Success",
            text: response.data.message,
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          }).then(() => {
            location.reload();
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Something went wrong!",
            text: "Email was not sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          });
        });
    },

    emailWaitlist() {
      console.log(this.selected);
      this.selected = this.selected.filter(Boolean);
      if (this.selected.length == 0) {
        this.$swal({
          icon: "info",
          title: "Invalid Request",
          text: "You need to select candidates you would like to email",
          confirmButtonText: `<p class='brandcolor'>Ok</p>`,
        });
        return;
      }
      //send acceptance email in bulk
      this.loading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/send-emails",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adminToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: {
          title: "Waitlist Notification for the BMGA Fellows Program",
          body: this.settings.waitlistEmail,
          recipients: this.selected,
          status: "Waitlisted",
        },
      })
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Email sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          }).then(() => {
            location.reload();
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Something went wrong!",
            text: "Email was not sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          });
        });
    },

    emailReminder() {
      console.log(this.selected);
      this.selected = this.selected.filter(Boolean);
      if (this.selected.length == 0) {
        this.$swal({
          icon: "info",
          title: "Invalid Request",
          text: "You need to select candidates you would like to email",
          confirmButtonText: `<p class='brandcolor'>Ok</p>`,
        });
        return;
      }
      //send acceptance email in bulk
      this.loading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/send-reminder-emails",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adminToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: {
          title: "Waitlist Notification for the BMGA Fellows Program",
          body: this.settings.waitlistEmail,
          recipients: this.selected,
          status: "in-progress",
        },
      })
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Email sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          }).then(() => {
            location.reload();
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Something went wrong!",
            text: "Email was not sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          });
        });
    },

    emailEnrolled() {
      console.log(this.selected);
      this.selected = this.selected.filter(Boolean);
      console.log(this.selected);
      if (this.selected.length == 0) {
        this.$swal({
          icon: "info",
          title: "Invalid Request",
          text: "You need to select candidates you would like to email",
          confirmButtonText: `<p class='brandcolor'>Ok</p>`,
        });
        return;
      }
      //send acceptance email in bulk
      this.loading = true;
      console.log(this.selected);
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/send-emails",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adminToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: {
          title: "Welcome to the BMGA Fellows Program: You are now enrolled!",
          body: this.settings.enrollmentEmail,
          recipients: this.selected,
          status: "Enrolled",
        },
      })
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Email sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          }).then(() => {
            location.reload();
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Something went wrong!",
            text: "Email was not sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          });
        });
    },

    showSelected(e) {
      this.selected = e;
      this.selectedApp = e;
    },
    searchList(e) {
      console.log(e);
      this.$store.dispatch("admin/searchStageApplications", e);
    },
    filterBy(e) {
      this.$store.dispatch("admin/filterBy", e);
    },
    setPagination(e) {
      window.scrollTo(0, 0);
      this.$store.dispatch("admin/setApplicationPagination", e);
    },

    assignReviewer() {
      if (this.selectedApp.length == 0) {
        this.$swal({
          icon: "info",
          title: "Application Not Selected",
          text: "You need to select atleast one application",
          confirmButtonText: `<p class='brandcolor'>Ok</p>`,
        });
        return;
      }
      this.assignreviewer = !this.assignreviewer;
    },

    prevPagination(e) {
      window.scrollTo(0, 0);
      if (e > 1) {
        let number = e - 1;
        console.log(number);
        this.number == e - 1;
        this.$store.dispatch("admin/setApplicationPagination", number);
      }
    },

    nextPagination(e) {
      window.scrollTo(0, 0);
      if (e < Math.floor(this.admin.applications.length / 20)) {
        let number = e + 1;
        this.number = e + 1;
        this.$store.dispatch("admin/setApplicationPagination", number);
      }
    },
  },
};
</script>

<style scoped>
.horizontalScroll {
  overflow-x: scroll;
  cursor: pointer;
}

.pagination {
  border-radius: 50%;
  background: #310059;
  padding: 0 6px;
  color: white;
  cursor: pointer;
}
</style>