<template>
    <v-app>
        <MarketingInformationComponent />
    </v-app>
</template>

<script>
import MarketingInformationComponent from "../../components/Applicant/MarketingInformation.vue"

export default {
    components:{
        MarketingInformationComponent
    },
    created(){
        this.$store.dispatch("fetchApplications")
    },
    metaInfo() {
    return {
      title: `Marketing Information`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content:
            "Apply to the BMGA fellows program"
        },
        {
          property: "og:title",
          content: "Marketing Information | BMGA Foundation"
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Marketing Information | BMGA Foundation"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/signup" 
        },
      ]
    };
  },
}
</script>


<style scoped>

</style>