<template>
  <v-app class="backgroundColor">
    <AppBar />
    <v-container>
      <v-card color="white" flat class="pa-6 my-7">
        <h2 class="brandcolor">Enrollment Form</h2>
        <p>The BMGA Fellows Program tuition fee is non-refundable.</p>

        <p>
          As stated in the payment guide, your spot in the program is not
          guaranteed until you complete the tuition payment process before the
          stipulated deadline.
        </p>

        <p>
          Kindly refer to the payment guide on your dashboard for detailed
          guidance on how to pay the required fee.
        </p>

        <p>
          Once payment has been made, please send proof of payment to our team
          via
          <a href="mailto:info@bmgafoundation.org">info@bmgafoundation.org</a>
          before proceeding to enroll below:
        </p>

        <h3>Terms & Conditions</h3>

        <p>
          Kindly ensure that you have carefully reviewed our Policy Statement in
          the Terms of Reference on your dashboard.
        </p>

        <v-form>
          <v-checkbox
            value="yes"
            color="#4c248a"
            v-model="proofOfPayment"
            label="I have sent proof of payment to the BMGA Team"
          ></v-checkbox>
          <v-checkbox
            value="yes"
            color="#4c248a"
            v-model="nonRefundable"
            label="I understand that the BMGA Fellows Program enrollment fee is non-refundable."
          ></v-checkbox>
          <v-checkbox
            value="yes"
            color="#4c248a"
            v-model="commit"
            label="I commit to fully participating in the program to the best of my ability (an average of 48 hours bi-weekly), and proactively developing relationships with my cohort members, my mentor, and the BMGA Team."
          ></v-checkbox>
          <v-checkbox
            value="yes"
            color="#4c248a"
            v-model="active"
            label="I will be an active participant in the weekly programming, group assignments, and my BMGA Fellows Program cohort."
          ></v-checkbox>
          <v-checkbox
            color="#4c248a"
            value="yes"
            v-model="eventAttendance"
            label="I understand that event attendance is required and absence can only be granted based on prior permission requests."
          ></v-checkbox>
          <v-checkbox
            value="yes"
            color="#4c248a"
            v-model="confirm"
            label="I have carefully read the BMGA Fellows Program Terms of Reference provided on my dashboard. I understand and agree to its conditions."
          ></v-checkbox>
        </v-form>

        <p>
          BMGA Foundation reserves the right to withdraw participants from this
          program at any time for violation of any of these policies and
          agreements.
        </p>

        <v-btn
          block
          @click="handleSubmit"
          color="#4c248a"
          class="white--text"
          large
          :loading="loading"
          >Submit</v-btn
        >
      </v-card>
    </v-container>
  </v-app>
</template>
  
  
<script lang="js">
import AppBar from "@/components/Applicant/AppBar.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      proofOfPayment: "",
      nonRefundable: "",
      commit: "",
      active: "",
      eventAttendance: "",
      confirm: "",
      loading:false
    };
  },
  metaInfo() {
    return {
      title: `Eligibility Check`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content: "Apply to the BMGA fellows program",
        },
        {
          property: "og:title",
          content: "Eligibility Check | BMGA Foundation",
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Eligibility Check | BMGA Foundation",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/signup",
        },
      ],
    };
  },

  components: {
    AppBar,
  },

  created() {
    window.scrollTo(0, 0);
    this.$store.dispatch("fetchApplications")
    if (sessionStorage.getItem("bmgaToken") == null) {
      this.$router.push("/login");
    }
  },
  computed: {
    ...mapState({
      userData: "userData",
      application:"application"
    }),
  },
  methods: {
    handleSubmit() {
       if(this.proofOfPayment == "yes" &&
      this.nonRefundable =="yes" &&
      this.commit == "yes" &&
      this.active == "yes" &&
      this.eventAttendance == "yes" &&
      this.confirm =="yes"){
        this.loading = true
            axios({
            method:"POST",
            url:process.env.VUE_APP_API_URL + "/change-status",
            headers:{
                Authorization:"Bearer "+sessionStorage.getItem("bmgaToken"),
                ContentType:"application/json",
                Accept:"*/*"
            },
            data:{
               applicationId:this.application._id,
               status:"Pending Enrollment",
               stage:4
            }
        }).then(()=>{
            this.loading = false
            this.$swal({
            icon:"success",
            title:"Enrollment Complete",
            text:"You have successfully completed your enrollment to the BMGA Fellows Program.",
            confirmButtonText:`<p class='brandcolor'>Ok</p>`
        }).then(()=>{
            window.location.href="/dashboard"
        })
        }).catch((error)=>{
            this.loading = false
            this.$swal({
                icon:"error",
                title:"Something's wrong",
                text:error.message,
                confirmButtonText:`<p class='brandcolor'>Ok</p>`
            })
        })
      }
else{
      this.$swal({
          icon:"info",
          title:"Incomplete",
          text:"Please ensure you have checked all boxes and met all the requirements for enrollment",
          confirmButtonText:`<p class='brandcolor'>Ok</p>`
        })
        this.loading = false
       
}
    },
  },
};
</script>
  
  
  <style scoped>
.noMargin {
  margin-top: -10px;
  padding: 0px;
}

.label {
  font-size: 18px;
}
</style>