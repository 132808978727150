<template>
    <v-app>
      <v-main class="signupForm">
      <v-card elevation="8" width="500px" class="rounded-lg mx-auto">
        <div class="signupCard" tile>
          <v-alert v-model="alert" type="error">{{ message }}</v-alert>
          <h2 class="brandcolor">Reset Password</h2>
          <div class="mb-4">Enter your new password below:</div>
          <v-form ref="form" v-model="valid" lazy-validation>
        
         <label>New Password</label>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            dense
            color="#4c248a"
            single-line
            :type="show ? 'text' : 'password'"
            outlined
            @click:append="show = !show"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          />

          <label>Confirm New Password</label>
          <v-text-field
            v-model="confirmPassword"
            required
            dense
            color="#4c248a"
            single-line
            :type="showConfirm ? 'text' : 'password'"
            outlined
            @click:append="showConfirm = !showConfirm"
            :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          />

          </v-form>
  
          <div class="my-3">
            <v-btn :loading="loading" @click="signup" block x-large color="#4c248a" class="white--text"
              > Change Password</v-btn
            >
          </div>
      </div>
  </v-card>
      </v-main>
    </v-app>
  </template>
  
  
  
  <script>
  import axios from 'axios'
  
  export default {
    data() {
      return {
        valid: true,
        email: "",
        passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be greater than 6 characters",
      ],
        alert:false,
        message:"",
        loading:false,
        show: false,
      showConfirm:false,
      };
    },
    methods: {

      goBack(){
        this.$router.go(-1)
      },
  
      async signup() {
        await this.$refs.form.validate();
        if(!this.valid){
        return
      }

        if(this.password == '' || this.confirmPassword == ''){
          this.alert=true
          this.message = "Please enter your new password"
          setTimeout(()=>{
            this.alert = false
          },2000)
          return
        }

        if(this.password != this.confirmPassword){
            this.alert=true
            this.message = "New password and confirm password not same"
          setTimeout(()=>{
            this.alert = false
          },2000)
            return
        }

        this.loading = true
        axios({
          method:"POST",
          url:process.env.VUE_APP_API_URL + "/reset-password",
          headers:{
              Accept:"*/*",
              ContentType:"application/json"
          },
          data:{
              id:this.$route.query.id,
              password:this.password
          }
        }).then((response)=>{
          this.loading = false
            this.$swal({
              icon:"success",
              title:"Password Reset Successful",
              text:response.data.message,
              confirmButtonText:`<p class="brandcolor">Login</p>` 
          }).then((result)=>{
            if(result.isConfirmed){
                this.$router.push("/login")
            }
          })

         
        }).catch((error)=>{
          this.loading = false
          console.log(error)
          this.$swal({
              icon:"error",
              title:"Email Reset Unsuccessful",
              text:error.response.data.message,
              confirmButtonText:`<p class="brandcolor">Ok</p>` 
          })
        })
  
  
      },
    },
  };
  </script>
  
  
  <style scoped>
  .signupForm {
    background-image:linear-gradient(to bottom, #8b3694ad, #4d248a9e),
    url('../../../assets/background-image.jpg');
    background-size: cover;
    padding: 120px !important;
  }
  
  .signupCard {
    padding: 20px 40px;
    background:white
  }
  
  label {
    font-size: 14px;
    margin-top:10px
  }
  
  .v-text-field {
    height: 54px !important;
  }
  
  span {
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    line-height: 12px;
  }
  
  h2 {
    margin-bottom: -3px;
  }
  
  .image::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(120deg, #8b3694, #4c248a);
      opacity: .7;
  }
  
  
  
  @media screen and (max-width: 600px) {
    .signupForm {
      background: #feeaf3;
      padding: 60px 15px !important;
    }
    .signupCard {
      padding: 20px 20px;
    }
  }
  </style>