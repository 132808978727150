<template>
    <v-card class="pa-6">
      <div class="text-right d-flex justify-space-between">
          <h2 class="brandcolor">Assign Reviewer</h2>
          <v-btn @click="showReviewer" small fab depressed><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <form>
        <label>Choose Reviewer</label>
        <v-select color="#310059" v-model="reviewerEmail" @change="enable" outlined :items="reviewers" item-text="name" item-value="email"/>
      </form>
  
      <v-btn @click="assignReviewer" :disabled="disabled" :loading="loading" block large color="#310059" class="white--text">Assign Reviewer</v-btn>
    </v-card>
  </template>
  
  
<script>
  import axios from 'axios'

  export default {
    data: () => ({
      name: "",
      email:"",
      password:"",
      show: false,
      loading:false,
      reviewerEmail:"",
      disabled:true
    }),
    props:{
        reviewers:{
            type:Array
        },
        applicationId:{
          type:String
        }
    },
    methods:{
      showReviewer(){
          this.$emit("showReviewer")
      },
      enable(){
        this.disabled = false
      },
      assignReviewer(){
        console.log(this.applicationId)
        console.log(this.reviewerEmail)
        this.loading = true
        axios({
        method:"POST",
        url:process.env.VUE_APP_API_URL + "/admin/assign-applicant",
        headers:{
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: "Bearer "+sessionStorage.getItem("adminToken"),
        },
        data:{
          email:this.reviewerEmail,
          id:this.applicationId
        }
      }).then((response)=>{
        console.log(response)
        this.loading = false
        this.$swal({
          icon:"success",
          title:"Reviewer Assigned Successfully",
          text:"You have successfully assigned a reviewer to this application",
          confirmButtonText:"<p class='brandcolor'>Ok</p>"
        }).then(()=>{
          location.reload()
        })
      }).catch(()=>{
        this.loading = false
        this.$swal({
          icon:"error",
          title:"Something went wrong",
          text:"Reviewer not assigned",
          confirmButtonText:"<p class='brandcolor'>Try again</p>"
        })
      })
      }
    }
  };
  </script>
  
  
  
  <style scoped>
  </style>