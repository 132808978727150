<template>
  <v-main style="margin: 50px 0px 0px 200px">
    <div>
      <h1 class="my-3" style="color: #310059">Reviewers</h1>

      <div class="applicationTable d-flex justify-space-between">
        <TableFilter
          @filterBy="filterBy"
          @searchList="searchList"
          @reminderEmail="reminderEmail"
        />

        <div>
          <v-btn
            :loading="loading"
            class="mr-4"
            color="#310059"
            large
            outlined
            @click="reminderEmail"
          >
            Send Reminder
          </v-btn>
          <v-btn @click="showReviewer" large color="#310059" class="white--text"
            ><v-icon>mdi-plus</v-icon>Add Reviewer</v-btn
          >
        </div>
      </div>

      <ReviewerTable
        @showSelected="showSelected"
        :reviewer="reviewer"
        @showReviewer="showReviewer"
        :reviewers="admin.filteredReviewers"
        :loading="admin.loading"
      />
    </div>
  </v-main>
</template>



<script lang="ts">
import ReviewerTable from "./ReviewerTable.vue";
import TableFilter from "./TableFilter.vue";
import axios from "axios";

import { mapState } from "vuex";

export default {
  components: { ReviewerTable, TableFilter },
  computed: {
    ...mapState(["admin"]),
  },
  props: {
    settings: {
      type: [Array, Object, String],
    },
  },
  data: () => ({
    search: "",
    reviewer: false,
    selected: [],
    loading: false,
  }),
  methods: {
    searchList(e) {
      console.log(e);
      this.$store.dispatch("admin/searchReviewers", e);
    },

    filterBy(e) {
      this.$store.dispatch("admin/filterBy", e);
    },

    showReviewer() {
      this.reviewer = !this.reviewer;
    },
    showSelected(e) {
      console.log(e);
      this.selected = e;
    },
    reminderEmail() {
      if (this.selected.length == 0) {
        this.$swal({
          icon: "info",
          title: "Invalid Request",
          text: "You need to select candidates you would like to email",
          confirmButtonText: `<p class='brandcolor'>Ok</p>`,
        });
        return;
      }
      //send acceptance email in bulk
      this.loading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/send-reviewer-emails",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adminToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: {
          title: "BMGA Fellows Program Application Review: Your Progress",
          body: this.settings.reviewerReminder,
          recipients: this.selected,
          status: "Waitlisted",
        },
      })
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Email was sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Something went wrong!",
            text: "Email was not sent successfully",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          });
        });
    },
  },
};
</script>

<style scoped>
.applicationTable {
  margin: 0px 60px 0px 0px;
}
</style>