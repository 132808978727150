<template>
  <v-app class="backgroundColor">
    <AppBar />
    <v-container class="my-5 container">
      <SideBar :page="$route.path" :userData="userData" />
      <h1 class="brandcolor">Profile</h1>
      <v-card flat class="pa-3">
        <label>First Name</label>
        <v-text-field outlined v-model="userData.firstName" dense />

        <label>Last Name</label>
        <v-text-field outlined v-model="userData.lastName" dense />

        <label>Email address</label>
        <v-text-field outlined v-model="userData.email" dense />

        <label>Phone Number</label>
        <v-text-field outlined v-model="userData.phone" dense />

        <div class="my-4">
          <v-btn
            @click="updateProfile"
            color="#310059"
            block
            class="white--text"
            :loading="loading"
            >Update Profile</v-btn
          >
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>


<script>
import { mapState } from "vuex";
import AppBar from "../Applicant/AppBar.vue";
import SideBar from "../Applicant/SideBar.vue";
import axios from "axios";
export default {
  components: {
    AppBar,
    SideBar,
  },
  created() {
    window.scrollTo(0, 0);
  },
  data:()=>({
    loading:false
  }),
  computed: {
    ...mapState({
      userData: "userData",
    }),
  },

  methods: {
    updateProfile() {
      this.loading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/update-profile",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          firstName: this.userData.firstName,
          lastName: this.userData.lastName,
          email: this.userData.email,
          phone: this.userData.phone,
        },
      }).then(() => {
        this.$swal({
            icon:"success",
            title:"Profile Updated",
            text:"You have successfully updated your profile",
            confirmButtonText:`<p class='brandcolor'>Ok</p>`
        })
        this.loading = false
        }).catch(()=>{
            this.$swal({
            icon:"error",
            title:"Profile Not Updated",
            text:"You experienced a network error",
            confirmButtonText:`<p class='brandcolor'>Ok</p>`
        })
        this.loading = false
      });
    },
  },
};
</script>




<style scoped>
@media only screen and (max-width: 600px) {
  .container {
    max-width: 950px;
    margin: auto;
    padding: 20px 0px;
  }
}
.container {
  max-width: 950px;
  margin: auto;
  padding: 0px 50px;
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}
</style>