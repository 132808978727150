import axios from "axios";

export default {
  namespaced: true,
  state: {
    adminToken: sessionStorage.getItem("adminToken"),
    applications: [],
    applicants: [],
    reviewers: [],
    loading: true,
    application: "",
    applicant: "",
    filteredApplications: [],
    filteredApplicants: [],
    applicationPagination: JSON.parse(sessionStorage.getItem("currentPage"))?JSON.parse(sessionStorage.getItem("currentPage")):1,
    applicantPagination: JSON.parse(sessionStorage.getItem("currentApplicantPage"))?JSON.parse(sessionStorage.getItem("currentApplicantPage")):1,
    settings: "",
    page:JSON.parse(sessionStorage.getItem("currentPage"))?JSON.parse(sessionStorage.getItem("currentPage")):1,
    limit:20,
    applicantPage:1,
    applicantLimit:20,
    total:JSON.parse(sessionStorage.getItem('stats'))?JSON.parse(sessionStorage.getItem('stats')).total:0,
    accepted:JSON.parse(sessionStorage.getItem('stats'))?JSON.parse(sessionStorage.getItem('stats')).accepted:0,
    inprogress:JSON.parse(sessionStorage.getItem('stats'))?JSON.parse(sessionStorage.getItem('stats')).inprogress:0,
    rejected:JSON.parse(sessionStorage.getItem('stats'))?JSON.parse(sessionStorage.getItem('stats')).rejected:0,
    reviewed:JSON.parse(sessionStorage.getItem('stats'))?JSON.parse(sessionStorage.getItem('stats')).reviewed:0,
    submitted:JSON.parse(sessionStorage.getItem('stats'))?JSON.parse(sessionStorage.getItem('stats')).submitted:0,
    statloading:false,
    applicantstatloading:false,
    totalApplicants:0,
    stageapplications:[],
    statusloading:true,
    allApplications:[],
    allLoading:false,
    searchstageapplications:[],
    reviewerApplications:[],
    filteredReviewerApplications:[]
  },
  getters: {},
  mutations: {
    fetchAdminUsers(state) {
      state.loading = true;
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/applications?page="+state.page+"&limit="+state.limit,
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          console.log(response)
          state.applications = response.data.applications;
          state.filteredApplications = response.data.applications.slice(0, 20)
          state.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },


    fetchAdminApplicants(state) {
      state.loading = true;
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/applicants?page="+state.applicantPage+"&limit="+state.applicantLimit,
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          console.log(response)
          state.applicants = response.data.applicants;
          state.filteredApplicants = response.data.applicants.slice(0, 20);
          state.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchAdminReviewers(state) {
      state.loading = true;
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/reviewers",
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          state.reviewers = response.data.reviewers;
          state.filteredReviewers = response.data.reviewers;
          state.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchAdminStats(state) {
      state.statloading = true;
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/stats",
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          state.total = response.data.total
          state.accepted = response.data.accepted
          state.inprogress = response.data.inprogress
          state.rejected = response.data.rejected
          state.reviewed = response.data.reviewed
          state.submitted = response.data.submitted
          state.statloading = false;
          sessionStorage.setItem("stats",JSON.stringify({
            total:response.data.total,
            accepted:response.data.accepted,
            inprogress:response.data.inprogress,
            rejected: response.data.rejected,
            reviewed:response.data.reviewed,
            submitted:response.data.submitted,
            applicantsTotal:state.totalApplicants
          }))
        })
        .catch((error) => {
          console.log(error);
        });
    },


    fetchAdminApplicantStats(state) {
      state.applicantstatloading = true;
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/applicant-stats",
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          console.log(response)
          state.totalApplicants = response.data.total
          state.applicantstatloading = false;

        })
        .catch((error) => {
          console.log(error);
        });
    },


    fetchAllAdminApplications(state) {
      state.allLoading = true;
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/all-applications",
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          console.log(response)
          state.allApplications = response.data.applications
          console.log(response)
          state.allLoading = false;

        })
        .catch((error) => {
          console.log(error);
        });
    },


    fetchAdminApplicationStage(state,value) {
      console.log(value)
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/application-stage?stage="+value,
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          state.stageapplications = response.data.applications
          state.searchstageapplications = response.data.applications
          state.statusloading = false
        })
        .catch((error) => {
          console.log(error);
        });
    },


    fetchAdminApplicationStatus(state,value) {
      console.log("value", value)
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/application-status",
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
        data:{
          status:value
        }
      })
        .then((response) => {
          state.stageapplications = response.data.data
          state.searchstageapplications = response.data.data
          state.statusloading = false
        })
        .catch((error) => {
          console.log(error);
        });
    },



    fetchApplication(state, value) {
      state.loading = true;
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/fetch-application?id=" + value,
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          state.application = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchApplicant(state, value) {
      state.loading = true;

      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/fetch-applicant?id=" + value,
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          state.applicant = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    searchApplications(state, value) {
      if (!value) {
        state.filteredApplications = state.applications.slice(0, 20);
      }
      state.filteredApplications =
        state.applications.filter(
          (application) => {
          return( 
          
          application.firstName.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
         
          application.lastName.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          
          application.email.toLowerCase().indexOf(value.toLowerCase()) !== -1
          )
          }
        ).slice(0,20)
    },


    searchReviewerApplications(state, value) {
      if (!value) {
        state.filteredReviewerApplications = state.reviewerApplications
      }
      state.filteredReviewerApplications =
        state.reviewerApplications.filter(
          (application) => {
          return( 
          
          application.firstName.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
         
          application.lastName.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          
          application.email.toLowerCase().indexOf(value.toLowerCase()) !== -1
          )
          }
        )
    },


    searchStageApplications(state, value) {
      if (!value) {
        state.stageapplications = state.searchstageapplications
      }
      state.stageapplications =
        state.searchstageapplications.filter(
          (application) => {
          return( 
          
          application.firstName.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
         
          application.lastName.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          
          application.email.toLowerCase().indexOf(value.toLowerCase()) !== -1
          )
          }
        )
    },

    searchApplicants(state, value) {
      if (!value) {
        state.filteredApplicants = state.applicants.slice(0, 20);
      }

      state.filteredApplicants = state.applicants
        .filter((applicant) => {
          return (
            applicant.firstName.toLowerCase().indexOf(value.toLowerCase()) >
              -1 ||
            applicant.lastName.toLowerCase().indexOf(value.toLowerCase()) >
              -1 ||
            applicant.email.toLowerCase().indexOf(value.toLowerCase()) > -1
          );
        })
        .slice(0, 20);
    },

    searchReviewers(state, value) {
      if (!value) {
        state.filteredReviewers = state.reviewers;
      }

      state.filteredReviewers = state.reviewers.filter((reviewer) => {
        return reviewer.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });
    },

    filterBy(state, value) {
      if (value == "Score") {
        state.filteredApplications = state.applications
          .sort(
            (a, b) =>
              parseFloat(b.assessment ? b.assessment : 0) -
              parseFloat(a.assessment ? a.assessment : 0)
          )
          .slice(0, 20);

          state.stageapplications = state.searchstageapplications
          .sort(
            (a, b) =>
              parseFloat(b.assessment ? b.assessment : 0) -
              parseFloat(a.assessment ? a.assessment : 0)
          )

          state.filteredReviewerApplications =  state.reviewerApplications
          .sort(
            (a, b) =>
              parseFloat(b.assessment ? b.assessment : 0) -
              parseFloat(a.assessment ? a.assessment : 0)
          )
      }

      if (value == "Stage") {
        state.filteredApplications = state.applications
          .sort(
            (a, b) =>
              parseFloat(b.stage ? b.stage : 0) -
              parseFloat(a.stage ? a.stage : 0)
          )
          .slice(0, 20);

          state.stageapplications = state.searchstageapplications
          .sort(
            (a, b) =>
              parseFloat(b.stage ? b.stage : 0) -
              parseFloat(a.stage ? a.stage : 0)
          )

          state.filteredReviewerApplications = state.reviewerApplications
          .sort(
            (a, b) =>
              parseFloat(b.stage ? b.stage : 0) -
              parseFloat(a.stage ? a.stage : 0)
          )
      }

      if (value == "Date") {
        state.filteredApplications = state.applications.reverse().slice(0,30);
        state.stageapplications = state.searchstageapplications.reverse()
        state.filteredReviewerApplications = state.reviewerApplications.reverse()
      }

      if(value == 'In Progress'){
        state.filteredApplications = state.applications.filter((application)=>application.status == 'in-progress').slice(0,20)
      }

      if(value == 'Reviewed'){
        state.filteredApplications = state.applications.filter((application)=>application.status == 'Reviewed' || application.status == 'Pending Review').slice(0,20)
      }

      if(value == 'Submitted'){
        state.filteredApplications = state.applications.filter((application)=>application.status == 'Submitted').slice(0,100)
      }
    },

    filterApplicants(state, value) {
      state.filteredApplicants = state.applicants
        .filter((applicant) => applicant.accountStatus == value)
        .slice(0, 20);
    },

    setApplicationPagination(state, value) {
      state.loading = false;
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/applications?page="+value+"&limit="+state.limit,
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          state.applications = response.data.applications;
          state.filteredApplications = response.data.applications.slice(0, 20)
          state.loading = false;
          state.applicationPagination = value
          sessionStorage.setItem("currentPage",value)
        })
        .catch((error) => {
          console.log(error);
        });
      
    },

    setApplicantPagination(state, value) {
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/applicants?page="+value+"&limit="+state.applicantLimit,
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      })
        .then((response) => {
          console.log(response)
          state.applicants = response.data.applicants;
          state.filteredApplicants = response.data.applicants.slice(0, 20);
          state.loading = false;
          state.applicantPagination = value
          sessionStorage.setItem("currentApplicantPage",value)
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchSettings(state) {
      axios({
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/admin/settings",
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: `Bearer ${state.adminToken}`,
        },
      }).then((response) => {
        state.settings = response.data.data;
      });
    },

    fetchReviewerApplications(state,value){
      state.loading = true
      axios({
          method:"GET",
          url:process.env.VUE_APP_API_URL + "/admin/reviewer/application?email="+value,
          headers:{
              Authorization:`Bearer ${state.adminToken}`,
              Accept:"*/*",
              ContentType:"application/json"
          },
      }).then((response)=>{
          state.loading = false    
          state.reviewerApplications = response.data.data
          state.filteredReviewerApplications = response.data.data
      })
  }
  },
  actions: {
    fetchAdminUsers: (context, value) => {
      context.commit("fetchAdminUsers", value);
    },
    fetchAdminApplicants: (context, value) => {
      context.commit("fetchAdminApplicants", value);
    },
    fetchAdminReviewers: (context, value) => {
      context.commit("fetchAdminReviewers", value);
    },
    fetchAdminStats: (context, value) => {
      context.commit("fetchAdminStats", value);
    },
    fetchAdminApplicantStats: (context, value) => {
      context.commit("fetchAdminApplicantStats", value);
    },

    fetchAdminApplicationStage: (context, value) => {
      context.commit("fetchAdminApplicationStage", value);
    },



    fetchApplication: (context, value) => {
      context.commit("fetchApplication", value);
    },

    fetchApplicant: (context, value) => {
      context.commit("fetchApplicant", value);
    },
    searchApplications: (context, value) => {
      context.commit("searchApplications", value);
    },

    searchReviewerApplications: (context, value) => {
      context.commit("searchReviewerApplications", value);
    },

    searchStageApplications: (context, value) => {
      context.commit("searchStageApplications", value);
    },

    searchApplicants: (context, value) => {
      context.commit("searchApplicants", value);
    },

    searchReviewers: (context, value) => {
      context.commit("searchReviewers", value);
    },
    filterBy: (context, value) => {
      context.commit("filterBy", value);
    },
    filterApplicants: (context, value) => {
      context.commit("filterApplicants", value);
    },
    setApplicationPagination: (context, value) => {
      context.commit("setApplicationPagination", value);
    },
    setApplicantPagination: (context, value) => {
      context.commit("setApplicantPagination", value);
    },
    fetchSettings: (context, value) => {
      context.commit("fetchSettings", value);
    },
    fetchAdminApplicationStatus: (context,value) =>{
      context.commit("fetchAdminApplicationStatus", value);
    },
    fetchAllAdminApplications:(context,value) =>{
      context.commit("fetchAllAdminApplications",value)
    },
    fetchReviewerApplications:(context,value) =>{
      context.commit("fetchReviewerApplications",value)
    }
  },
};
