<template>
  <v-app class="backgroundColor">
    <AppBar />
    <AppSidebar :page="$route.path" :userData="userData" />
    <v-container class="my-5 container">
      <h1 class="brandcolor topTitle">Additional Information</h1>
      <v-card flat class="pa-5 my-5">
        <p>
          Please enter your information in each of the sections. Fields with an
          asterisk (*) are required and must be completed before you can submit
          your application. Additional questions may become required based on
          your answers to a previous question. Please remember to save your
          application regularly to avoid losing your answers.
        </p></v-card
      >

      <AdditionalInformationComponent
        :loading="loading"
        @updateApplication="updateApplication"
        :proficiency="proficiency"
        :experiences="experiences"
        :employmentType="employmentType"
        :industryType="industryType"
        :internetAccess="internetAccess"
      />
      <div class="mt-12">
        <v-btn
          @click="updateApplication"
          :loading="loading"
          block
          x-large
          class="primaryBtn"
          >Save & Continue </v-btn
        >
      </div>
    </v-container>
  </v-app>
</template>
  
  
  <script>
import { mapState } from "vuex";
import AppBar from "./../Applicant/AppBar.vue";
import AppSidebar from "./../Applicant/AppSidebar.vue";
import AdditionalInformationComponent from "./AdditionalinformationComponent.vue";
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      proficiency: ["Basic", "Fluent", "Proficient"],
      isProfessional: "",
      experiences: [
        "Less than 1 year",
        "1-3 years",
        "More than 3 years"
      ],
      yearsOfExperience: "",
      employment: "",
      employmentType: ["Not Employed", "Internship", "Part-Time", "Full-Time"],
      industry: "",
      industryType: [
        "Asset/Investment Management",
        "Banking",
        "Consulting",
        "Consumer Durables",
        "Education",
        "Engineering/Construction",
        "Government/Public Sector",
        "Health Services/Hospitals",
        "Insurance",
        "International Development",
        "Legal Services",
        "Logistics",
        "Manufacturing",
        "Marketing/PR/Advertising",
        "Media/Entertainment",
        "Non-Profit",
        "Pharmaceuticals",
        "Research/Academia",
        "Retail",
        "Technology",
        "Telecommunications",
        "Other",
      ],
      company: "",
      internet: "",
      isTuition: "",
      internetAccess: [
        "On a regular basis",
        "Most of the time",
        "I will not have a reliable connection to the internet",
      ],
      fundingDescription: "",
    };
  },
  components: { AppBar, AppSidebar, AdditionalInformationComponent },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      userData: "userData",
      application: "application",
    }),
  },

  methods: {
    updateApplication() {
      
      this.loading = true;
      const payload = {
        applicationId: this.application._id,
        reading: this.application.reading,
        writing: this.application.writing,
        speaking: this.application.speaking,
        listening: this.application.listening,
        yearsOfExperience: this.application.yearsOfExperience,
        employed: this.application.employed,
        sector: this.application.sector,
        other:this.application.other,
        accessToInternet: this.application.accessToInternet,
        isTuition: this.application.isTuition,
        describeTuition: this.application.describeTuition,
        isProfessional: this.application.isProfessional,
        company: this.application.company,
      };
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/update-application",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: payload,
      })
        .then(() => {
          this.loading = false;
          this.$router.push("/application/supporting-documents");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
  
  
  
  
  <style scoped>
@media only screen and (max-width: 600px) {
  .topTitle {
    padding: 0px 0px 0px 15px;
  }
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 776px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 894px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 996px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.v-text-field {
  height: 54px !important;
}

.instructions {
  font-size: 10px;
  margin: 0px 0px 10px 0px;
}

.checkBoxes {
  margin-top: -27px;
}

.descriptionBox {
  margin: 0px 0px 140px 0px;
}
</style>