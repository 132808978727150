// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAXP0ZAqcn4Q3tSpD1AUzfj-k95xsuQC4c",
  authDomain: "bmga-portal-api.firebaseapp.com",
  projectId: "bmga-portal-api",
  storageBucket: "bmga-portal-api.appspot.com",
  messagingSenderId: "222435093441",
  appId: "1:222435093441:web:ec99c046df3dfd03fcdd40",
  measurementId: "G-QCSK3E9239"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);