import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import SignupView from "@/views/Auth/SignupView"
import VerifyEmail from "@/views/Auth/VerifyEmail"
import LoginView from "@/views/Auth/LoginView"
import ForgotPassword from "@/views/Auth/ForgotPassword"
import ResetPassword from "@/views/Auth/ResetPassword"
import DashboardPage from "@/views/Applicant/DashboardPage"
import ProfilePage from "@/views/Applicant/ProfilePage"
import EligibilityCheck from "@/views/Applicant/EligibilityCheck"
import ApplicationPage from "@/views/Applications/ApplicationsPage"
import PersonalInformation from "@/views/Applications/PersonalInformation"
import AcademicExperience from "@/views/Applications/AcademicExperience"
import AdditionalInformation from "@/views/Applications/AdditionalInformation"
import SupportingDocument from "@/views/Applications/SupportingDocument"
import MarketingInformation from "@/views/Applications/MarketingInformation"
import ConfidentialInformation from "@/views/Applications/ConfidentialInformation"
import SignaturePage from "@/views/Applications/SignaturePage"
import ReviewPage from "@/views/Applications/ReviewPage"
import CompleteEnrollment from "@/views/Applicant/CompleteEnrollment"
import AdminLogin from "@/views/Admin/LoginPage"
import AdminDashboard from "@/views/Admin/AdminPage"
import AdminApplicationPage from "@/views/Admin/ApplicationPage"
import AdminApplicantPage from "@/views/Admin/ApplicantsPage"
import AdminApplicationStage from "@/views/Admin/ApplicationStage"
import AdminReviewerPage from "@/views/Admin/ReviewerPage"
import AdminSingleReviewerPage from "@/views/Admin/SingleReviewerPage"
import SingleApplicationPage from "@/views/Admin/SingleApplicationPage"
import SingleApplicantPage from "@/views/Admin/SingleApplicantPage"
import ReviewerLogin from "@/views/Reviewer/LoginPage"
import ReviewerDashboard from "@/views/Reviewer/ReviewerPage"
import ReviewerApplicationPage from "@/views/Reviewer/ReviewerApplicationPage"
import SingleReviewerPage from "@/views/Reviewer/SingleReviewerPage"
import SettingsPage from "@/views/Admin/SettingsPage"
import SubmittedApplications from "@/views/Admin/SubmittedApplications"
import PendingApplications from "@/views/Admin/PendingApplications"
import AcceptanceLetter from "@/views/Applicant/AcceptanceLetter"


Vue.use(VueRouter)
Vue.use(Meta,{
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

const routes = [
  {
    path: '/',
    name: 'SignupView',
    component: SignupView
  },

  {
    path: '/signup',
    name: 'SignupView',
    component: SignupView
  },

  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/dashboard',
    name: 'DashboardPage',
    component: DashboardPage
  },
  {
    path: '/eligibility-check',
    name: 'EligibilityCheck',
    component: EligibilityCheck
  },

  {
    path: '/complete-enrollment',
    name: 'CompleteEnrollment',
    component: CompleteEnrollment
  },

  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage
  },

  {
    path: '/acceptance-letter',
    name: 'AcceptanceLetter',
    component: AcceptanceLetter
  },

  {
    path: '/applications',
    name: 'ApplicationsPage',
    component: ApplicationPage
  },

  {
    path: '/admin/settings',
    name: 'SettingsPage',
    component: SettingsPage
  },


  {
    path: '/application/personal-information',
    name: 'PersonalInformation',
    component: PersonalInformation
  },

  {
    path: '/application/academic-experience',
    name: 'AcademicExperience',
    component: AcademicExperience
  },
  {
    path: '/application/additional-information',
    name: 'AdditionalInformation',
    component: AdditionalInformation
  },

  {
    path: '/application/supporting-documents',
    name: 'SupportingDocument',
    component: SupportingDocument
  },

  {
    path: '/application/marketing-information',
    name: 'MarketingInformation',
    component: MarketingInformation
  },

  {
    path: '/application/confidential-information',
    name: 'ConfidentialInformation',
    component: ConfidentialInformation
  },

  {
    path: '/application/signature',
    name: 'SignaturePage',
    component: SignaturePage
  },

  {
    path: '/application/review',
    name: 'ReviewPage',
    component: ReviewPage
  },

  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard
  },
  {
    path: '/admin/applications',
    name: 'AdminApplicationPage',
    component: AdminApplicationPage
  },

  {
    path: '/admin/applicants',
    name: 'AdminApplicantPage',
    component: AdminApplicantPage
  },

  {
    path: '/admin/reviewers',
    name: 'AdminReviewerPage',
    component: AdminReviewerPage
  },

  {
    path: '/admin/application/:id',
    name: 'SingleApplicationPage',
    component: SingleApplicationPage
  },

  {
    path: '/admin/applicant/:id',
    name: 'SingleApplicantPage',
    component: SingleApplicantPage
  },

  {
    path: '/admin/applications/submitted',
    name: 'SubmittedApplication',
    component: SubmittedApplications
  },

  {
    path: '/admin/applications/pending',
    name: 'PendingApplication',
    component: PendingApplications
  },

  {
    path: '/reviewer/login',
    name: 'ReviewerLogin',
    component: ReviewerLogin
  },

  {
    path: '/reviewer/dashboard',
    name: 'ReviewerDashboard',
    component: ReviewerDashboard
  },

  {
    path: '/reviewer/applications',
    name: 'ReviewerApplicationPage',
    component: ReviewerApplicationPage
  },

  {
    path: '/reviewer/application/:id',
    name: 'SingleReviewerPage',
    component: SingleReviewerPage
  },

  {
    path: '/reviewer/:id',
    name: 'AdminSingleReviewerPage',
    component: AdminSingleReviewerPage
  },
  {
    path: '/admin/applications/stage/:id',
    name: 'AdminApplicationStage',
    component: AdminApplicationStage
  },
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
