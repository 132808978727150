<template>
    <v-app>
        <LoginComponent/>
    </v-app>
</template>



<script>
import LoginComponent from "../../components/Applicant/Auth/LoginComponent.vue"

export default {
  created(){
      if(sessionStorage.getItem("bmgaToken") != null){
          this.$router.push("/dashboard")
        }
    },
    components:{
        LoginComponent
    },
    metaInfo() {
    return {
      title: `Login`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content:
            "Start your application on the BMGA application portal"
        },
        {
          property: "og:title",
          content: "Login | BMGA Foundation"
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Login | BMGA Foundation"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/signup" 
        },
      ]
    };
  },
}
</script>