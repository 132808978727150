<template>
  <div class="d-flex">
    <div style="width:400px">
    <v-text-field prepend-inner-icon="mdi-magnify" v-model="search" @keyup="searchList" color="#310059" dense outlined label="Search..."/>
  </div>
        </div>
</template>


<script lang="ts">
export default {
    data:()=>({
        status:"",
        search:""
    }),
    methods:{
      searchList(){
        this.$emit("searchList",this.search)
      },
      filterBy(e){
        this.status = e
        this.$emit("filterBy",e)
      }
    },
    props:{
        name:{
            type:String
        }
    }
}
</script>