<template>
  <v-card class="pa-6">
    <div class="my-4 text-right d-flex justify-space-between">
      <h2 class="brandcolor">Review Application</h2>
      <v-btn @click="showReviewer" small fab depressed
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>

    <p>
      Please score this applicant using the categories listed below with 1 being
      Extremely Poor and 5 being Outstanding.
    </p>
    <table>
      <thead>
        <tr>
          <th>Criterion</th>
          <th></th>
          <th>Weight</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td class="pa-3">Appropriateness of Application</td>
          <td class="pa-3">
            <p class="description">
              Does the applicant make a strong case for meeting the eligibility
              requirements for the program and the objective of the BMGA Fellows
              Program?
            </p>
          </td>

          <td class="pa-3">
            <v-select
              style="width: 70px"
              dense
              :items="ratings"
              color="#310059"
              v-model="appropriateness"
              outlined
            />
          </td>
        </tr>

        <tr>
          <td class="pa-3">Commitment</td>
          <td class="pa-3">
            <p class="description">
              Does the applicant have a commitment to contribute to the social,
              economic or political development of Africa or Asia? Does the
              applicant wish to apply their skills and training to benefit the
              continent after they are done? Does the applicant have a
              commitment to advance women's matters?
            </p>
          </td>

          <td class="pa-3">
            <v-select
              style="width: 70px"
              dense
              :items="ratings"
              color="#310059"
              v-model="commitment"
              outlined
            />
          </td>
        </tr>

        <tr>
          <td class="pa-3">Potential Impact</td>
          <td class="pa-3">
            <p class="description">
              Will the applicant have the ability to identify and address ways
              to advance women equality in their country?
            </p>
          </td>

          <td class="pa-3">
            <v-select
              style="width: 70px"
              dense
              :items="ratings"
              color="#310059"
              v-model="impact"
              outlined
            />
          </td>
        </tr>

        <tr>
          <td class="pa-3">Strong Communication</td>
          <td class="pa-3">
            <p class="description">
              Do the responses consistently and effectively show genuine
              interest with attached personal connection? Do they respond
              appropriately with a unique and interesting perspective? Does the
              essay maintain a clear, specific, and prompt appropriate focus
              that develops a clear, consistent main idea throughout? Are the
              responses written in a clear and concise manner? Does the
              applicant use intentional and vivid language choices that make the
              writer’s voice rich, personal, honest and very distinctive?
            </p>
          </td>

          <td class="pa-3">
            <v-select
              style="width: 70px"
              dense
              :items="ratings"
              color="#310059"
              v-model="communication"
              outlined
            />
          </td>
        </tr>

        <tr>
          <td class="pa-3">Aptitude</td>
          <td class="pa-3">
            <p class="description">
              Does the applicant demonstrate that they possess some of the core
              foundational skills required to succeed i.e., problem solving,
              critical thinking, adaptability etc?
            </p>
          </td>

          <td class="pa-3">
            <v-select
              style="width: 70px"
              dense
              :items="ratings"
              color="#310059"
              v-model="aptitude"
              outlined
            />
          </td>
        </tr>

        <tr>
          <td class="pa-3">Leadership</td>
          <td class="pa-3">
            <p class="description">
              Is the applicant capable of leading and motivating people? Does
              the applicant exhibit a can-do attitude, bravery, and commitment
              to lead no matter what is thrown at them?
            </p>
          </td>

          <td class="pa-3">
            <v-select
              style="width: 70px"
              dense
              :items="ratings"
              color="#310059"
              v-model="leadership"
              outlined
            />
          </td>
        </tr>

        <tr>
          <td class="pa-3">Professionalism</td>
          <td class="pa-3">
            <p class="description">
              Does the applicant display a professional disposition? Did they
              exhibit a high level of preparedness for their video response?
            </p>
          </td>

          <td class="pa-3">
            <v-select
              style="width: 70px"
              dense
              :items="ratings"
              color="#310059"
              v-model="professionalism"
              outlined
            />
          </td>
        </tr>
      </tbody>
    </table>

    <div class="my-2">
    <label>Comment</label>
    <div style="height: 150px">
      <v-textarea v-model="comment" color="#310059" outlined height="140px" />
    </div>
  </div>

    <v-btn
      @click="submitReview"
      :loading="loading"
      block
      large
      color="#310059"
      class="white--text my-4"
      >Submit Review</v-btn
    >

    <v-dialog v-model="areyousure" max-width="500px">
      <v-card height="180px" class="pa-6">
        <h2>Confirm Review Submission</h2>

        <p>You will not be able to modify this review after submitting. Are you sure you want to submit review?</p>

        <div class="float-right">
          <v-btn class="mr-5" depressed @click="areyousure = !areyousure">No</v-btn>
          <v-btn width="90px" @click="confirmReview" color="#310059" class="white--text" :loading="loading">Yes</v-btn>
        </div>
      </v-card>
    </v-dialog>


  </v-card>
</template>
  
  
<script>
import axios from "axios";

export default {
  name: "ReviewApplication",
  data: () => ({
    name: "",
    email: "",
    password: "",
    show: false,
    loading: false,
    reviewerEmail: "",
    disabled: true,
    appropriateness: "",
    commitment: "",
    impact: "",
    communication: "",
    aptitude: "",
    leadership: "",
    professionalism: "",
    ratings: [1, 2, 3, 4, 5],
    comment:"",
    areyousure:false
  }),
  props: {
    reviewers: {
      type: Array,
    },
    applicationId: {
      type: String,
    },
  },
  methods: {
    showReviewer() {
      this.$emit("showReviewer");
    },
    enable() {
      this.disabled = false;
    },
    submitReview() {
      console.log(this.$route.params.id);
      if (
        this.appropriateness == "" ||
        this.commitment == "" ||
        this.impact == "" ||
        this.communication == "" ||
        this.aptitude == "" ||
        this.leadership == "" ||
        this.professionalism == ""
      ) {
        this.$swal({
          icon: "error",
          title: "Review Incomplete",
          text: "Please submit a review of the applicant based on all criteria",
          confirmButtonText: `<p class="brandcolor">Ok</p>`,
        });
        this.loading = false;
        return;
      }
      this.areyousure = true
    },

    confirmReview(){
      this.loading = true;
      let assessment =
        ((parseFloat(this.appropriateness) +
          parseFloat(this.commitment) +
          parseFloat(this.impact) +
          parseFloat(this.communication) +
          parseFloat(this.aptitude) +
          parseFloat(this.leadership) +
          parseFloat(this.professionalism)) /
          35) *
        100;

      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/reviewer/review-application",
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("reviewerToken"),
        },
        data: {
          id: this.$route.params.id,
          assessment: assessment,
          comment:this.comment
        },
      })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.areyousure = false
          this.$swal({
            icon: "success",
            title: "Assessment submitted",
            text: "You have successfully submitted a review for this application",
            confirmButtonText: "<p class='brandcolor'>Ok</p>",
          }).then(() => {
            window.location.href = "/reviewer/applications";
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Something went wrong",
            text: "Reviewer not assigned",
            confirmButtonText: "<p class='brandcolor'>Try again</p>",
          });
        });
    },

      
  },
};
</script>
  
  
  
  <style scoped>
table {
  width: 100%;
  padding: 17px 0px;
}
table,
th,
td {
  border: 1px solid;
  border-collapse: collapse;
}

.description {
  font-size: 12px;
  line-height: 12px !important;
}
</style>