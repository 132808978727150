import { render, staticRenderFns } from "./SubmittedApplications.vue?vue&type=template&id=5bccc20c&scoped=true"
import script from "./SubmittedApplications.vue?vue&type=script&lang=ts"
export * from "./SubmittedApplications.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_vue-template-compiler@2.7.16_webpack@5.90.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bccc20c",
  null
  
)

export default component.exports