<template>
    <div>
        <div class="d-flex">
        <v-card flat width="280px" class="pa-5">
            <h2>{{ applications.length }}</h2>
            <p>total applications</p>
        </v-card>

        <v-card color="#310059" flat width="280px" class="white--text pa-5 mx-6">
            <h2>{{ reviewed.length }}</h2>
            <p>reviewed applications</p>
        </v-card>

        <v-card flat width="280px" class="pa-5 mx-3">
            <h2>{{ applications.length - reviewed.length }}</h2>
            <p>unreviewed applications</p>
        </v-card>
       </div>
    </div>
</template>


<script>
export default {
   props:{
  
    applications:{
        type:Array
    },
    reviewed:{
        type:Array
    }
   }
}
</script>

<style scoped>

</style>