<template>
    <v-main style="margin:50px 0px 0px 200px">
       <div>       
        <h1 class="my-3" style="color:#310059">Applications</h1>
       <ApplicationTable :reviewers="reviewer.reviewers" :applications="reviewer.applications" :loading="reviewer.loading"/>
      </div>
    </v-main>
</template>



<script lang="ts">
import ApplicationTable from "./ApplicationTable.vue"
import { mapState } from "vuex"

export default {
    components:{ApplicationTable},
    computed:{
      ...mapState(['reviewer'])
    },
    data:()=>({
        search:""
    })
}
</script>

<style scoped>

</style>