<template>
    <v-main style="margin:50px 0px 0px 200px">
       <DashboardStatCard :applicants="admin.applicants" :applications="admin.applications" :reviewers="admin.reviewers" />

       <div class="mt-8">
       <h3 style="color:#310059">Applications</h3>
       <ApplicationTable :reviewers="admin.reviewers" :applications="admin.applications.slice(0,5)" :loading="admin.loading"/>
      <ApplicationTableLoading :loading="admin.loading"/>
      </div>
    </v-main>
</template>



<script>
import DashboardStatCard from "./DashboardStatCard"
import ApplicationTable from "./ApplicationTable.vue"
import ApplicationTableLoading from "./ApplicationTableLoading.vue"
import { mapState } from "vuex"

export default {
    components:{DashboardStatCard,ApplicationTable,ApplicationTableLoading},
    computed:{
      ...mapState(['admin'])
    },
}
</script>

<style scoped>

</style>