<template>
  <div class="applicationTable">
    <table cellspacing="0">
      <thead>
        <tr v-if="loading" class="tableHeading">
          <th></th>
          <th>Full name</th>
          <th>Email</th>
          <th>Phone</th>
          <!-- <th>Id</th> -->
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody v-for="number in rows" :key="number">
        <tr v-if="loading">
          <td><v-checkbox color="#310059" /></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td>
            <v-skeleton-loader type="text"></v-skeleton-loader>
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </td>
        </tr>
      </tbody>
    </table>

    <v-dialog persistent max-width="580px" v-model="assignreviewer">
      <AssignReviewer
        :reviewers="reviewers"
        @showReviewer="showReviewer"
        :applicationId="applicationId"
      />
    </v-dialog>
  </div>
</template>
    
  
  
 <script>
import AssignReviewer from "../Application/AssignReviewer.vue";

export default {
  components: {
    AssignReviewer,
  },
  props: {
    applications: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    reviewers: {
      type: Array,
    },
  },
  data: () => ({
    applicationId: "",
    assignreviewer: false,
    rows: 5,
  }),
  methods: {
    viewApplication(id) {
      this.$router.push("application/" + id);
    },
    assignReviewer(id) {
      this.applicationId = id;
      this.assignreviewer = !this.assignreviewer;
    },
    showReviewer() {
      this.assignreviewer = !this.assignreviewer;
    },
  },
};
</script>
    
    
    <style scoped>
.tableHeading {
  background: #310059 !important;
  color: white !important;
}

.tableHeading tr {
  background: #f9fafb;
}

td {
  border-bottom: 1px solid #f9fafb;
  padding: 10px !important;
  text-align: center;
  border: 1px solid #310059;
}
th {
  width: 150px;
  padding: 10px;
  border-right: 1px solid white;
}

.applicationTable {
  margin: 0px 60px 0px 0px;
}
</style>