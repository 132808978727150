<template>
    <v-main style="margin:50px 0px 0px 200px">
       <DashboardStatCard :reviewed="reviewer.applications.filter((application)=>application.status =='Reviewed')" :applications="reviewer.applications" />
       <div class="mt-8">
       <h3 style="color:#310059">Applications</h3>
       <ApplicationTable :applications="reviewer.applications" :loading="reviewer.loading"/>
      </div>
    </v-main>
</template>



<script>
import DashboardStatCard from "./DashboardStatCard"
import ApplicationTable from "./ApplicationTable.vue"
import { mapState } from "vuex"

export default {
    components:{DashboardStatCard,ApplicationTable},
    computed:{
      ...mapState(['reviewer'])
    },
}
</script>

<style scoped>

</style>