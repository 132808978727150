<template>
  <v-card class="pa-6">
    <div class="text-right d-flex justify-space-between">
        <h2 class="brandcolor">Add Reviewer</h2>
        <v-btn @click="showReviewer" small fab depressed><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <form>
      <label>Name</label>
      <v-text-field color="#4c248a" v-model="name" outlined dense />

      <label>Email address</label>
      <v-text-field color="#4c248a" v-model="email" outlined dense />

      <label>Password</label>
      <v-text-field
        v-model="password"
        dense
        color="#4c248a"
        single-line
        :type="show ? 'text' : 'password'"
        outlined
        @click:append="show = !show"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      />
    </form>

    <v-btn @click="addReviewer" :loading="loading" block large color="#310059" class="white--text">Create Reviewer</v-btn>
  </v-card>
</template>


<script>
import axios from 'axios'
export default {
  data: () => ({
    name: "",
    email:"",
    password:"",
    show: false,
    loading:false
  }),
  methods:{
    showReviewer(){
        this.$emit("showReviewer")
    },
    addReviewer(){
        this.loading = true
        axios({
            method:"POST",
            url:process.env.VUE_APP_API_URL + "/admin/create-reviewer",
            headers:{
                Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
                ContentType:"application/json",
                Accept:"*/*"
            },
            data:{
                name:this.name,
                email:this.email,
                password:this.password
            }
        }).then((response)=>{
            console.log(response)
            this.loading = false
            this.name = ""
            this.email = ""
            this.password = ""
            this.$store.dispatch("admin/fetchAdminUsers")
            this.$swal({
                icon:"success",
                title:"Reviewer account Created",
                text:"You have successfully created a reviewer account for "+this.name,
                confirmButtonText:`<p class="brandcolor">Ok</p>`
            }).then(()=>{
              this.$store.dispatch("admin/fetchAdminUsers")
            })
        }).catch((error)=>{
           this.name = ""
            this.email = ""
            this.password = ""
            console.log(error)
            this.$swal({
                icon:"error",
                title:"Reviewer account not Created",
                text:error.response.data.message,
                confirmButtonText:`<p class="brandcolor">Ok</p>`
            })
            this.loading = false
        })
    }
  }
};
</script>



<style scoped>
</style>