<template>
  <v-app class="backgroundColor">
    <AppBar />
    <AppSidebar :page="$route.path" :userData="userData" />
    <v-container class="my-5 container">
      <h1 class="brandcolor topTitle">Confidential Information</h1>
      <v-card flat class="pa-5 my-5">
        <div>
          Please enter your information in each of the sections. Fields with an
          asterisk (*) are required and must be completed before you can submit
          your application. Additional questions may become required based on
          your answers to a previous question. Please remember to save your
          application regularly to avoid losing your answers.
        </div></v-card
      >

      <v-card class="pa-4" flat>
        <v-form>
          <p>
            Do you have a disability you wish to declare? (This information WILL
            NOT be used to inform selection decisions. It is helpful for us to
            be aware at this stage of any likely requirements you may have
            during the program.)
          </p>

          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="no-disability"
            label="No disability"
          ></v-checkbox>
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="austic-disorder"
            label="Autism Spectrum Disorder"
          ></v-checkbox>
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="blind/partial-sight"
            label="Blind/Partial sight"
          ></v-checkbox>
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="deaf/partial-hearing"
            label="Deaf/Partial Hearing"
          ></v-checkbox>
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="mental-disorder"
            label="Mental Disorder"
          ></v-checkbox>
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="learning-difficulty"
            label="Learning Difficulty"
          ></v-checkbox>
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="wheelchair/mobility/other"
            label="Wheelchair/mobility"
          ></v-checkbox>
          <v-checkbox
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.confidentialInfo"
        value="do-not-wish-to-disclose"
        label="Do not wish to disclose"
      ></v-checkbox>
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="other"
            label="Other"
          ></v-checkbox>


          <p v-if="confidentialInfo.indexOf(application.confidentialInfo.toLowerCase()) == -1" class="instructions">
            Enter below.
          </p>
          <v-text-field
            v-model="application.confidentialInfo"
            v-if="confidentialInfo.indexOf(application.confidentialInfo.toLowerCase()) == -1"
            class="mt-5"
            color="#4c248a"
            outlined
            dense
            required
          />
        </v-form>

        <div class="mt-12">
          <v-btn
            @click="updateApplication"
            :loading="loading"
            block
            x-large
            class="primaryBtn"
            >Save & Continue</v-btn
          >
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>
      
      
      <script>
import { mapState } from "vuex";
import AppBar from "./AppBar.vue";
import AppSidebar from "./AppSidebar.vue";
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      confidentialInfo: [
      "no-disability",
      "austic-disorder",
      "blind/partial-sight",
      "deaf/partial-hearing",
      "mental-disorder",
      "learning-difficulty",
      "wheelchair/mobility/other",
      "do-not-wish-to-disclose"
      ],
    };
  },
  components: { AppBar, AppSidebar },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      userData: "userData",
      application: "application",
    }),
  },

  methods: {
    updateApplication() {
      this.loading = true;
      const payload = {
        applicationId: this.application._id,
        confidentialInfo: this.application.confidentialInfo,
      };
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/update-application",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: payload,
      }).then((response) => {
        console.log(response);
        this.loading = false;
        this.$router.push("/application/signature");
      });
    },
  },
};
</script>
      
      
      
      
      <style scoped>
@media only screen and (max-width: 600px) {
  .topTitle {
    padding: 0px 0px 0px 15px;
  }
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 776px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 894px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 996px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

.v-text-field {
  height: 54px !important;
}

.instructions {
  font-size: 13px;
  margin: 0px 0px 10px 0px;
}

.checkBoxes {
  margin-top: -27px;
}

.descriptionBox {
  margin: 0px 0px 30px 0px;
}

.v-textarea,
textarea {
  resize: none !important;
}
textarea {
  width: 100%;
  outline: 1px solid #4c248a;
  border-radius: 5px;
  height: 250px;
  padding: 10px;
}
</style>