<template>
    <v-app class="backgroundColor">
        <AppBar />
        <v-container class="my-5 container">
        <SideBar :page="$route.path" :userData="userData"/>
        <h1 class="brandcolor">My Application</h1>

        <v-card flat class="pa-5 my-5">
            <div class="text-right">
                <v-btn :loading="loading" @click="createApplication" class="primaryBtn"> <v-icon>mdi-plus</v-icon>Start Application</v-btn>
            </div>

            <div class="my-5 tableScroll">
            <table>
                    <tr>
                      <th class="type">Type</th>
                      <th class="status">Status</th>
                      <th class="started">Started</th>
                      <th class="submitted">Submitted</th>
                    </tr>
                    <tr v-if="applications.length == 0">
                      <td>You've not started an application for the BMGA Fellows Program yet</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr v-for="(application,i) in applications" v-else :key="i">
                      <td><router-link :to="application.status!=='in-progress'?'':'/application/personal-information'"><u>{{ application.title }}</u></router-link></td>
                      <td :class="application.status">{{ application.status }}</td>
                      <td>{{ application.created }}</td>
                      <td>{{ application.submitted }}</td>
                    </tr>
            </table>
          </div>
        </v-card>


    
    </v-container>
    </v-app>
</template>


<script>
import { mapState } from "vuex";
import AppBar from "../Applicant/AppBar.vue";
import SideBar from "../Applicant/SideBar.vue";
import axios from 'axios'

export default {
  data(){
    return{
      loading:false
    }
  },


  components: {
    AppBar,
    SideBar,
  },

  created(){
    window.scrollTo(0,0)
  },

  props:{
    applications:{
      type:Array
    }
  },

  computed:{
    ...mapState({
      userData:"userData",
      application:"application"
    })
  },

  methods:{
    createApplication(){
      this.loading = true
      axios({
        method:"POST",
        url:process.env.VUE_APP_API_URL + "/create-application",
        headers:{
          Authorization:"Bearer "+sessionStorage.getItem("bmgaToken"),
          ContentType:"application/json",
          Accept:"*/*"
        },
        data:{
            email:this.userData.email,
            firstName:this.userData.firstName,
            lastName:this.userData.lastName,
            phone:this.userData.phone
        }
      }).then(()=>{
        this.loading = false
        this.$router.push("/application/personal-information")
      }).catch((error)=>{
        console.log(error)
        this.loading = false
        this.$swal({
          icon:"error",
          title:"Error",
          text:error.response.data.message,
          confirmButtonText:`<p class="brandcolor">Ok</p>`
        })
      })

    }
  },
};
</script>




<style scoped>
.container {
  max-width: 950px;
  margin: auto;
  padding:0px 50px
}



.needHelpCard{
    border-top: 6px solid  #4c248a!important
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  min-width:140px
}

/* tr:nth-child(1) {
  background-color: #dddddd;
} */

@media only screen and (max-width:700px){
  .tableScroll{
    max-width:600px;
    overflow-x: scroll;
  }
}

.Submitted{
  color:#32c36c
}
.in-progress{
  color:#ffd700
}
</style>