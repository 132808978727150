import { render, staticRenderFns } from "./ApplicantComponent.vue?vue&type=template&id=99c0fc34&scoped=true"
import script from "./ApplicantComponent.vue?vue&type=script&lang=ts"
export * from "./ApplicantComponent.vue?vue&type=script&lang=ts"
import style0 from "./ApplicantComponent.vue?vue&type=style&index=0&id=99c0fc34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_vue-template-compiler@2.7.16_webpack@5.90.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99c0fc34",
  null
  
)

export default component.exports