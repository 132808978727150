<template>
    <v-main style="margin: 50px 0px 0px 200px">
      <div>
        <h1 class="my-3" style="color: #310059">Reviewer: {{ reviewer }}</h1>
        <TableFilter  @searchList="searchList"
        @assignReviewer="assignReviewer"
          @filterBy="filterBy" :isReviewer="isReviewer" />
        <ApplicationTable
        :selectedApp="selectedApp"
        :assignreviewer="assignreviewer"
        @showSelected="showSelected"
        @assignReviewer="assignReviewer"
        :reviewers="admin.reviewers"
        :applications="admin.filteredReviewerApplications"
        />
        <v-progress-circular color="#310059"  title="fetching data" v-if="admin.loading" indeterminate></v-progress-circular>
      </div>
    </v-main>
  </template>
  
  
  
<script>
  import Vue from "vue";
  import ApplicationTable from "../Application/ApplicationTable.vue";
  import { mapState } from "vuex";
import TableFilter from "../Application/TableFilter.vue";
  export default Vue.extend({
    data: () => ({
      filteredApplications:"",
      assignreviewer:false,
      selectedApp:[]
    }),
    components: { ApplicationTable, TableFilter },
    computed: {
      ...mapState(["admin"]),
      reviewer() {
        return this.$route.params.id;
      },
      isReviewer(){
        return this.$route.path.split("/")[1]
      }
    },
    methods:{
      searchList(e){
         this.$store.dispatch("admin/searchReviewerApplications",e)
      },
      filterBy(e){
        this.$store.dispatch("admin/filterBy",e)
      },

      showSelected(e){
      this.selectedApp = e
      console.log(this.selectedApp)
    },

      assignReviewer(){
      if(this.selectedApp.length == 0 ){
        this.$swal({
          icon:"info",
          title:"Application Not Selected",
          text:"You need to select atleast one application",
          confirmButtonText:`<p class='brandcolor'>Ok</p>`
        })
        return
      }
      this.assignreviewer = !this.assignreviewer
    },
    }
  });
  </script>