<template>
    <v-app>
        <v-main class="hidden-md-and-down">
      <v-row style="background:#F8F8F9;height:101%">
        <v-col cols="12" md="1" sm="1" lg="1">
          <AdminSideBar />
        </v-col>
        <v-col  cols="12" md="11" sm="11" lg="11">
            <AppBar />
            <DashboardComponent />
        </v-col>
      </v-row>
    </v-main>
    </v-app>
</template>


<script>
import AdminSideBar from "../../components/Reviewer/AdminSideBar.vue"
import DashboardComponent from "@/components/Reviewer/Dashboard/DashboardComponent.vue"
import AppBar from "@/components/Reviewer/AppBar.vue";

export default {
    components:{
        AdminSideBar,
        DashboardComponent,
        AppBar
    },
    
    created(){
        this.$store.dispatch("reviewer/fetchApplications")
        if(sessionStorage.getItem("reviewerToken") == null){
          this.$router.push("/reviewer/login")
        }
    }
}
</script>

<style scoped>

</style>