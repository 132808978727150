<template>
  <div class="my-3 d-flex">
    <div style="width: 400px">
      <v-text-field
        v-if="$route.path != '/admin/applications'"
        prepend-inner-icon="mdi-magnify"
        v-model="search"
        @keyup="searchList"
        color="#310059"
        dense
        outlined
        label="Search..."
      />
    </div>
    <v-menu class="ml-2" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on" v-show="hideBtn">
          Sort by: {{ sortBy }} <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="filterBy('Score')">
            <v-list-item-title>Score</v-list-item-title>
          </v-list-item>
          <v-list-item @click="filterBy('Stage')">
            <v-list-item-title>Stage</v-list-item-title>
          </v-list-item>

          <v-list-item @click="filterBy('Date')">
            <v-list-item-title>Date (Newest)</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>


    <v-menu v-if="showStatus" class="ml-2" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          Status: {{ status }} <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="filterCategory('In Progress')">
            <v-list-item-title>In Progress</v-list-item-title>
          </v-list-item>
          <v-list-item @click="filterCategory('Reviewed')">
            <v-list-item-title>Reviewed</v-list-item-title>
          </v-list-item>

          <v-list-item @click="filterCategory('Submitted')">
            <v-list-item-title>Submitted</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <div v-if="stage != '1' &&  stage !='3'  && stage != '4' && stage != '5' && !isReviewer && !hideBtn" >
      <v-btn

        rounded-lg
        color="#310059"
        class="white--text"
        @click="assignReviewer"
        :loading="btnloading"
      >
        Assign Reviewer</v-btn
      >
    </div>

    <div v-if="stage == '4'">
      <v-btn
        
        rounded-lg
        color="#310059"
        class="white--text"
        @click="emailAcceptance"
        :loading="btnloading"   
      >
        <v-icon small>mdi-email-outline</v-icon> Acceptance</v-btn
      >
    </div>

    <div v-if="hideBtn">
      <v-btn
       
        rounded-lg
        color="#310059"
        outlined
        @click="emailReminder"
        :loading="btnloading"
        class="ml-3"
      >
        <v-icon small>mdi-email-outline</v-icon> Reminder</v-btn
      >
    </div>


    <div v-if="stage == '5'">
      <v-btn
        
        rounded-lg
        color="#310059"
        class="white--text"
        @click="emailEnrolled"
        :loading="btnloading"   
      >
        <v-icon small>mdi-email-outline</v-icon> Enrolled</v-btn
      >
    </div>

    <div v-if="stage == '3'">
      <v-btn
       
        rounded-lg
        color="#310059"
        outlined
        @click="emailWaitlist"
        :loading="btnloading"
        class="ml-3"
      >
        <v-icon small>mdi-email-outline</v-icon> Waitlist</v-btn
      >
    </div>
    <div v-if="stage == '1' || stage=='3'">
      <v-btn
      
        rounded-lg
        color="#ff2000"
        class="mx-2"
        @click="emailRejection"
        outlined
        :loading="btnloading"
      >
        <v-icon small>mdi-email-outline</v-icon> Rejection</v-btn
      >
    </div>

    <div class="ml-2">
      <v-btn :loading="admin.allLoading" outlined rounded-lg color="#310059" @click="downloadCSV"
        >Export as CSV <v-icon class="ml-2" small>mdi-export</v-icon></v-btn
      >
    </div>
  </div>
</template>


<script lang="ts">
import { mapState } from 'vuex';
import { excelParser } from "./excel-parser";

export default {
  data: () => ({
    status: "",
    search: "",
    sortBy:""
  }),

  computed:{
    ...mapState([
      'admin'
    ])
  },


  components: {},
  methods: {
    searchList() {
      this.$emit("searchList", this.search);
    },
    filterBy(e) {
      this.sortBy = e;
      this.$emit("filterBy", e);
    },

    filterCategory(e) {
      this.status = e;
      this.$emit("filterBy", e);
    },
    downloadCSV() {
      excelParser().exportDataFromJSON(this.admin.allApplications.length != 0?this.admin.allApplications:this.admin.stageapplications, null, null);
    },

    emailAcceptance(){
      this.$emit('emailAcceptance')
    },
    emailEnrolled(){
      this.$emit('emailEnrolled')
    },
    emailWaitlist(){
      this.$emit('emailWaitlist')
    },
    emailReminder(){
      this.$emit('emailReminder')
    },
    emailRejection(){
      this.$emit('emailRejection')
    },
    assignReviewer(){
      this.$emit("assignReviewer")
    }
  },
  props: {
    name: {
      type: String,
    },
    applications: {
      type: Array,
    },
    allApplications:{
      type:Array
    },
    stage: {
      type: String,
    },
    btnloading:{
      type:Boolean
    },
    isReviewer:{
      type:String
    },
    showStatus:{
      type:Boolean
    },
    hideBtn: {
      type: Boolean
    }
  },
};
</script>