<template>
  <div>
    <div v-if="editor">
      <v-btn
        @click="editor.chain().focus().toggleBold().run()"
        icon
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        <v-icon>mdi-format-bold</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        <v-icon>mdi-format-italic</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
      >
        <v-icon>mdi-format-strikethrough-variant</v-icon>
      </v-btn>
      <!-- <v-btn @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
          code
        </v-btn> -->
      <!-- <v-btn @click="editor.chain().focus().unsetAllMarks().run()">
          clear marks
        </v-btn> -->
      <!--<v-btn @click="editor.chain().focus().clearNodes().run()">
          clear nodes
        </v-btn> -->
      <v-btn
        icon
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
      >
        <v-icon>mdi-format-paragraph</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        <v-icon>mdi-format-header-1</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        <v-icon>mdi-format-header-2</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      >
        <v-icon>mdi-format-header-3</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
      >
        <v-icon>mdi-format-header-4</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
      >
        <v-icon>mdi-format-header-5</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
      >
        <v-icon>mdi-format-header-6</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        <v-icon> mdi-format-list-bulleted</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
      >
        <v-icon> mdi-format-list-numbered</v-icon>
      </v-btn>
      <!-- <v-btn @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
          code block
        </v-btn> -->
      <!--  <v-btn @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
          blockquote
        </v-btn> -->
      <v-btn icon @click="editor.chain().focus().setHorizontalRule().run()">
        <v-icon> mdi-drag-horizontal-variant </v-icon>
      </v-btn>
      <!--<v-btn @click="editor.chain().focus().setHardBreak().run()">
          hard break
        </v-btn> -->
      <v-btn icon @click="editor.chain().focus().undo().run()">
        <v-icon> mdi-undo </v-icon>
      </v-btn>
      <v-btn icon @click="editor.chain().focus().redo().run()">
        <v-icon> mdi-redo </v-icon>
      </v-btn>

      <v-btn icon @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
        <v-icon> mdi-link </v-icon>
      </v-btn>

      <v-btn icon @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
        <v-icon> mdi-link-off </v-icon>
    </v-btn>
    </div>
    <editor-content :editor="editor" :email="email"/>
  </div>
</template>
  

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Link from '@tiptap/extension-link'

export default {
 name:"IndexTipTap",
  components: {
    EditorContent,
  },
  data() {
    return {
      editor: null,
      content: "",
    };
  },
  props:{
    email:{
      type:String
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [StarterKit,Link.configure({
          openOnClick: false,
        }),],
      content: this.email? this.email:`<i>Start typing</i>`,
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  updated() {
    this.emailContent();
  },
  methods: {
    emailContent() {
      this.content = this.editor.getHTML();
      this.$emit("emailContent", this.content);
    },

    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
  },
};
</script>
  
  <style lang="scss">
/* Basic editor styles */
.ProseMirror {
  height: 200px;
  > * + * {
    margin-top: 0.75em;
  }
  ul,
  ol {
    padding: 0 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: none !important;
    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
.ProseMirror:focus {
  border: none !important;
  outline: none;
}
</style>