<template>
  <div>
    <v-app-bar dark class="africanPrint px-3">
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="showMenu"
      ></v-app-bar-nav-icon>

      <v-card @click="$router.push('/dashboard')" color="white"
        ><img src="../../assets/bmgalogo.png"
      /></v-card>

      <v-spacer></v-spacer>

      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-account</v-icon>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>

          <span v-bind="attrs" v-on="on">{{
            userData && userData.firstName.length > 6
              ? userData.firstName.slice(0, 6) + "..."
              : userData.firstName
          }}</span>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i">
            <router-link :to="item.link">{{ item.title }}</router-link>
          </v-list-item>
        </v-list>
      </v-menu>

      <span @click="logOut()" class="pl-7 logOut">Logout</span>
    </v-app-bar>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    items: [
      { title: "Dashboard", link: "/dashboard" },
      { title: "Applications", link: "/applications" },
      { title: "Profile", link: "/profile" },
    ],
  }),
  computed: {
    ...mapState({
      userData: "userData",
    }),
  },
  created() {
    this.$store.dispatch("fetchUser");
  },

  methods: {
    logOut() {
      sessionStorage.removeItem("bmgaToken");
      this.$router.push("/login");
    },
    showMenu() {
      this.$store.dispatch("showMenu");
    },
  },
};
</script>


<style scoped>
.africanPrint {
  background-image: url("../../assets/African Print.jpeg");
  width: 100%;
  background-size: cover;
  object-fit: cover;
}

.logOut{
cursor: pointer
}
</style>