<template>
    <v-app>
        <ResetPasswordComponent/>
    </v-app>
</template>



<script>
import ResetPasswordComponent from "../../components/Applicant/Auth/ResetPasswordComponent.vue"

export default {
    components:{
        ResetPasswordComponent
    },

    metaInfo() {
    return {
      title: `Reset Password`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content:
            "Start your application on the BMGA application portal"
        },
        {
          property: "og:title",
          content: "Reset Password | BMGA Foundation"
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Reset Password | BMGA Foundation"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/reset-password" 
        },
      ]
    };
  },
}
</script>