<template>
  <v-main style="margin: 10px 70px 0px 200px">
    <div>
      <h1 class="my-3" style="color: #310059">Settings</h1>

      <v-list>
        <v-list-item @click="showModal()" class="d-flex justify-between">
            <v-list-item-title> Set Application Deadline </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
        </v-list-item>

        <v-list-item @click="showAccept()" class="d-flex justify-between">
            <v-list-item-title> Customize Acceptance Email </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
        </v-list-item>

        <v-list-item @click="showReject()" class="d-flex justify-between">
            <v-list-item-title> Customize Rejection Email </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
        </v-list-item>

        <v-list-item  @click="showWaitlist()" class="d-flex justify-between">
            <v-list-item-title> Customize Waitlist Email </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
        </v-list-item>

        <v-list-item @click="showReviewer()"  class="d-flex justify-between">
            <v-list-item-title> Customize Reviewer Reminder Email </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
        </v-list-item>

        <v-list-item @click="showEnrolled()"  class="d-flex justify-between">
            <v-list-item-title> Customized Enrollment Email </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
        </v-list-item>
      </v-list>
    </div>

    <v-dialog v-model="showmodal" fullscreen>
      <DeadlineComponent :settings="settings" @closeModal="showModal"/>
    </v-dialog>

    <v-dialog v-model="showaccept" fullscreen>
      <EmailComponent :settings="settings" @closeModal="showAccept"/>
    </v-dialog>

    <v-dialog v-model="showreject" fullscreen>
      <RejectionEmail :settings="settings" @closeModal="showReject"/>
    </v-dialog>

    <v-dialog v-model="showwaitlist" fullscreen>
      <WaitlistComponent :settings="settings" @closeModal="showWaitlist"/>
    </v-dialog>

    <v-dialog v-model="showreviewer" fullscreen>
      <ReviewerReminder :settings="settings" @closeModal="showReviewer"/>
    </v-dialog>

    <v-dialog v-model="showenrolled" fullscreen>
      <EnrolledEmail :settings="settings" @closeModal="showEnrolled"/>
    </v-dialog>

    
  </v-main>
</template>


<script>
import DeadlineComponent from "./DeadlineComponent.vue"
import EmailComponent from "./EmailComponent.vue";
import RejectionEmail from './RejectionEmail.vue';
import WaitlistComponent from './WaitlistComponent.vue';
import ReviewerReminder from "./ReviewerReminder.vue"
import EnrolledEmail from "./EnrolledEmail.vue"

export default {
  data:()=>({
    showmodal:false,
    showaccept:false,
    showreject:false,
    showwaitlist:false,
    showreviewer:false,
    showenrolled:false
  }),
  props:{
    settings:{
      type:[Object, String]
    }
  },
  components:{DeadlineComponent,EmailComponent,RejectionEmail,WaitlistComponent,ReviewerReminder,EnrolledEmail},
  methods:{
    showModal(){
      this.showmodal = !this.showmodal
    },

    showAccept(){
      this.showaccept = !this.showaccept
    },

    showReject(){
      this.showreject = !this.showreject
    },

    showWaitlist(){
      this.showwaitlist = !this.showwaitlist
    },

    showReviewer(){
      this.showreviewer = !this.showreviewer
    },
    showEnrolled(){
      this.showenrolled = !this.showenrolled
    }
  }
}
</script>


<style scoped>
.v-list-item {
  cursor: pointer !important;
}
</style>