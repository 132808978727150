<template>
  <v-app class="backgroundColor" style="height: 150vh">
    <AppBar />
    <AppSidebar :page="$route.path" :userData="userData" />
    <v-container class="my-5 container">
      <h1 class="brandcolor topTitle">Academic Experience</h1>
      <v-card flat class="pa-5 my-5">
        <p>
          List the educational institutions you have attended after secondary
          school (i.e. tertiary education). For undergraduates, please list the
          details of your current CGPA and proposed year of graduation.
        </p></v-card
      >

      <v-card class="pa-4" flat>
        <div class="text-right">
          <v-btn @click="addinstitution = !addinstitution" class="primaryBtn">
            <v-icon>mdi-plus</v-icon>Add Institution</v-btn
          >
        </div>

        <div class="my-5 tableScroll">
          <table>
            <tr>
              <th class="type">University</th>
              <th class="status">Course</th>
              <th class="started">Qualification</th>
              <th class="started">Degree</th>
              <th class="submitted">CGPA</th>
              <th class="submitted">Action</th>
            </tr>
            <tr v-if="application.academicExperience.length == 0">
              <td>
                You've not added an academic experience yet
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr
              v-for="(institution, i) in application.academicExperience"
              v-else
              :key="i"
            >
              <td>{{ institution.university }}</td>
              <td>{{ institution.courseOfStudy }}</td>
              <td>{{ institution.degree }}</td>
              <td>{{ institution.degreeClass }}</td>
              <td>{{ institution.cgpa }}/{{ institution.gpaScale }}</td>
              <td class="submitted">
                <v-btn @click="selectEdit(institution)" text color="#4c248a">Edit</v-btn> <br /><v-btn
                  text
                  color="#4c248a"
                  
                  @click="deleteInstitution(institution)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </td>
            </tr>
          </table>
        </div>
        <div v-if="application.academicExperience.length > 0">
        <v-btn class="primaryBtn" x-large block to="/application/additional-information">Save & Continue</v-btn>
      </div>
      </v-card>
    </v-container>

    <v-dialog persistent max-width="580px" v-model="addinstitution">
      <v-card max-width="580px" class="pa-6">
        <div class="d-flex justify-space-between">
          <h2 class="brandcolor">Add Institution</h2>
          <v-btn small fab depressed @click="addinstitution = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <v-form>
          <label>Country of Study*</label>
          <v-select
            v-model="countryOfStudy"
            :items="filteredCountries"
            attach
            outlined
            dense
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="searchTerm"
                    placeholder="Search"
                    @input="searchCountry"
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>

          <label>Qualification*</label>
          <v-select
            color="#4c248a"
            outlined
            dense
            :items="degrees"
            v-model="degree"
          />

          <label>Course Of Study*</label>
          <v-text-field
            color="#4c248a"
            outlined
            dense
            v-model="courseOfStudy"
          />

          <label>University*</label>
          <v-text-field color="#4c248a" outlined dense v-model="university" />

          <label>Duration of Course (in years only)*</label>
          <v-select
            color="#4c248a"
            outlined
            dense
            :items="durations"
            v-model="duration"
          />

          <label
            >What is the status of the degree result you have listed
            above*?</label
          >
          <v-select
            :items="academicStatuses"
            color="#4c248a"
            outlined
            dense
            v-model="academicStatus"
          />

          <label>Award Date*</label>
          <v-text-field
            dense
            outlined
            v-model="awardDate"
            color="#4c248a"
            type="date"
          />
          <div class="instructions">
            (If your degree has been awarded, please enter the award date. If
            you have not yet completed this degree, please enter the expected
            award date. Otherwise, please enter the date you stopped being
            affiliated with the institution)
          </div>

          <label>Graduating Degree*</label>
          <v-select
            dense
            :items="classes"
            v-model="degreeClass"
            color="#4c248a"
            outlined
          />

          <label>CGPA</label>
          <div class="d-flex">
            <v-text-field
              v-model="cgpa"
              style="max-width: 80px"
              dense
              outlined
              color="#4c248a"
              type="number"
            />
            <div class="mt-2 mx-5">on a</div>
            <v-select
              color="#4c248a"
              style="max-width: 80px"
              outlined
              v-model="gpaScale"
              dense
              :items="cgpaScale"
            />
            <v-text-field
              v-if="gpaScale ==='Other'"
              color="#4c248a"
              style="max-width: 80px;margin-left:10px"
              outlined
              v-model="otherScale"
              dense
            />
            <div class="mt-2 mx-5">scale</div>
          </div>

          <div class="my-5">
            <v-btn
              :loading="loading"
              @click="addInstitution"
              class="primaryBtn"
              x-large
              block
              >Add Institution</v-btn
            >
          </div>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- edit instiution -->
    <v-dialog persistent max-width="580px" v-model="editinstitution">
      <v-card max-width="580px" class="pa-6">
        <div class="d-flex justify-space-between">
          <h2 class="brandcolor">Edit Institution</h2>
          <v-btn small fab depressed @click="editinstitution = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <v-form>
          <label>Country of Study*</label>
          <v-select
            v-model="selected.country"
            :items="filteredCountries"
            attach
            outlined
            dense
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="searchTerm"
                    placeholder="Search"
                    @input="searchCountry"
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>

          <label>Qualification*</label>
          <v-select
            color="#4c248a"
            outlined
            dense
            :items="degrees"
            v-model="selected.degree"
          />

          <label>Course Of Study*</label>
          <v-text-field
            color="#4c248a"
            outlined
            dense
            v-model="selected.courseOfStudy"
          />

          <label>University*</label>
          <v-text-field color="#4c248a" outlined dense v-model="selected.university" />

          <label>Duration of Course (in years only)*</label>
          <v-select
            color="#4c248a"
            outlined
            dense
            :items="durations"
            v-model="selected.duration"
          />

          <label
            >What is the status of the degree result you have listed
            above*?</label
          >
          <v-select
            :items="academicStatuses"
            color="#4c248a"
            outlined
            dense
            v-model="selected.academicStatus"
          />

          <label>Award Date*</label>
          <v-text-field
            dense
            outlined
            v-model="selected.awardDate"
            color="#4c248a"
            type="date"
          />
          <div class="instructions">
            (If your degree has been awarded, please enter the award date. If
            you have not yet completed this degree, please enter the expected
            award date. Otherwise, please enter the date you stopped being
            affiliated with the institution)
          </div>

          <label>Graduating Degree*</label>
          <v-select
            dense
            :items="classes"
            v-model="selected.degreeClass"
            color="#4c248a"
            outlined
          />

          <label>Current CGPA (for undergraduates):</label>
          <div class="d-flex">
            <v-text-field
              v-model="selected.cgpa"
              style="max-width: 80px"
              dense
              outlined
              color="#4c248a"
              type="number"
            />
            <div class="mt-2 mx-5">on a</div>
            <v-select
              color="#4c248a"
              style="max-width: 99px"
              outlined
              v-model="selected.gpaScale"
              dense
              :items="cgpaScale"
            />
        
            <v-text-field
              v-if="selected.gpaScale ==='Other'"
              color="#4c248a"
              style="max-width: 80px;margin-left:10px"
              outlined
              v-model="selected.otherScale"
              dense
            />
          
            <div class="mt-2 mx-5">scale</div>
          </div>


          <div class="my-5">
            <v-btn
              :loading="loading"
              @click="editInstitution"
              class="primaryBtn"
              x-large
              block
              >Edit Institution</v-btn
            >
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <v-overlay v-model="deleteLoading">
      <v-progress-circular indeterminate>
      </v-progress-circular>
      <p class="text-center">Deleting...</p>
    </v-overlay>
  </v-app>
</template>
  
  
  <script>
import { mapState } from "vuex";
import AppBar from "./AppBar.vue";
import AppSidebar from "./AppSidebar.vue";
import axios from "axios";

export default {
  data() {
    return {
      addinstitution: false,
      countryOfStudy: "",
      degrees: ["Bachelor's", "Masters", "PhD"],
      degree: "",
      courseOfStudy: "",
      duration: "",
      durations: ["1", "2", "3", "4", "5", "6", "7", "8"],
      academicStatus: "",
      otherAcademicStatus: "",
      academicStatuses: ["Awarded", "Not yet completed"],
      degreeClass: "",
      classes: [
        "First class",
        "Distinction",
        "Second class Upper",
        "Second class Lower",
        "Third class",
        "Pass",
      ],
      cgpaScale: ["4.0", "5.0", "7.0","Other"],
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antigua & Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia & Herzegovina",
        "Botswana",
        "Brazil",
        "British Virgin Islands",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Cote D Ivoire",
        "Croatia",
        "Cruise Ship",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Polynesia",
        "French West Indies",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kuwait",
        "Kyrgyz Republic",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Pierre & Miquelon",
        "Samoa",
        "San Marino",
        "Satellite",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Somalia",
        "South Africa",
        "South Sudan",
        "South Korea",
        "Spain",
        "Sri Lanka",
        "St Kitts & Nevis",
        "St Lucia",
        "St Vincent",
        "St. Lucia",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor L'Este",
        "Togo",
        "Tonga",
        "Trinidad & Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks & Caicos",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (US)",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      filteredCountries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antigua & Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia & Herzegovina",
        "Botswana",
        "Brazil",
        "British Virgin Islands",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Cote D Ivoire",
        "Croatia",
        "Cruise Ship",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Polynesia",
        "French West Indies",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kuwait",
        "Kyrgyz Republic",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Pierre & Miquelon",
        "Samoa",
        "San Marino",
        "Satellite",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Somalia",
        "South Africa",
        "South Sudan",
        "South Korea",
        "Spain",
        "Sri Lanka",
        "St Kitts & Nevis",
        "St Lucia",
        "St Vincent",
        "St. Lucia",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor L'Este",
        "Togo",
        "Tonga",
        "Trinidad & Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks & Caicos",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (US)",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      cgpa: "",
      gpaScale: "",
      awardDate: "",
      loading: false,
      university: "",
      editinstitution:false,
      selected:"",
      deleteLoading:false,
      otherScale:"",
      searchTerm:""
    };
  },
  components: { AppBar, AppSidebar },
  computed: {
    ...mapState({
      userData: "userData",
      application: "application",
    }),
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {

    checkInstitution(obj){
      const valueArray = Object.values(obj);
      for (var key in valueArray) {
        console.log(key)
        if (valueArray.some(element => element === '')) {
          this.$swal({
            icon: "error",
            title: "Institution Not Complete!",
            text: "You need to fill out all required fields before you can add this institution",
            confirmButtonText: `<p class="brandcolor">Ok</p>`,
          });
          return false
        }
        return true
    }
    },


    addInstitution() {
      this.loading = true;
      const payload = {
        country: this.countryOfStudy,
        // qualification: this.qualification,
        courseOfStudy: this.courseOfStudy,
        duration: this.duration,
        degree: this.degree,
        degreeClass:this.degreeClass,
        awardDate: this.awardDate,
        // graduatingDegree: this.graduatingDegree,
        cgpa: this.cgpa,
        gpaScale: this.gpaScale == 'Other'?this.otherScale:this.gpaScale,
        university: this.university,
        academicStatus:this.academicStatus,
        id:this.application.academicExperience.length+1
      };


      if(!this.checkInstitution(payload)){
        this.loading = false
        return
      }


      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/add-institution",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: {
          applicationId: this.application._id,
          payload: payload,
        },
      }).then((response) => {
        this.loading = false;
        console.log(response);
        location.reload();
      });
    },

    selectEdit(e){
      this.editinstitution = !this.editinstitution
      this.selected = e
    },

    editInstitution() {
      this.loading = true;
      const payload = {
        country: this.selected.countryOfStudy,
        qualification: this.selected.qualification,
        courseOfStudy: this.selected.courseOfStudy,
        duration: this.selected.duration,
        degree: this.selected.degree,
        degreeClass:this.selected.degreeClass,
        awardDate: this.selected.awardDate,
        graduatingDegree: this.selected.graduatingDegree,
        cgpa: this.selected.cgpa,
        gpaScale: this.selected.gpaScale == 'Other'?this.selected.otherScale:this.selected.gpaScale,
        university: this.selected.university,
        academicStatus:this.selected.academicStatus,
        id:this.selected.id
      };
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/edit-institution",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: {
          applicationId: this.application._id,
          payload: payload,
        },
      }).then((response) => {
        this.loading = false;
        console.log(response);
        this.$swal({
          icon:"success",
          title:"Institution Updated Successfully",
          text:"You have successfully updated your institution",
          confirmButtonText:`<p class="brandcolor">Ok</p>`
        }).then(()=>{
          location.reload()
        })
      });
    },


    deleteInstitution(e) {
      this.deleteLoading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/delete-institution",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: {
          applicationId: this.application._id,
          payload: e,
        },
      }).then((response) => {
        this.deleteLoading = false;
        console.log(response);
        this.$swal({
          icon:"success",
          title:"Institution deleted Successfully",
          text:"You have successfully deleted your institution",
          confirmButtonText:`<p class="brandcolor">Ok</p>`
        }).then(()=>{
          location.reload()
        })
      });
    },

    searchCountry() {
      if (!this.searchTerm) {
        this.filteredCountries = this.countries;
      }

      this.filteredCountries = this.countries.filter((country) => {
        return (
          country.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        );
      });
    },
    
  
    


  },
};
</script>
  
  
  
  
  <style scoped>

@media only screen and (max-width: 600px) {
  .topTitle {
    padding: 0px 0px 0px 15px;
  }
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 776px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 894px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 996px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.v-text-field {
  height: 54px !important;
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.v-text-field {
  height: 54px !important;
}

.instructions {
  margin-top: -10px;
  margin-bottom: 8px;
  font-size: 10px;
  line-height: 10px;
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* tr:nth-child(1) {
  background-color: #dddddd;
} */

@media only screen and (max-width: 700px) {
  .tableScroll {
    overflow-x: scroll;
  }
}
</style>