<template>
  <v-app>
    <v-main class="signupForm">
    <v-card elevation="6">
      <v-row no-gutters>
      <v-col>
      <div class="signupCard" tile>
        <v-alert v-model="alert" type="error">{{ message }}</v-alert>
        <h2 class="brandcolor">Create an account</h2>
        <div class="pt-3">Start your application for the BMGA Fellows Program</div>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-2">
            <v-col>
              <label>First Name</label>
              <v-text-field
                v-model="firstName"
                :rules="firstNameRules"
                single-line
                dense
                color="#4c248a"
                outlined
              />
            </v-col>
            <v-col>
              <label>Last Name</label>
              <v-text-field
                v-model="lastName"
                :rules="lastNameRules"
                single-line
                dense
                color="#4c248a"
                outlined
              />
            </v-col>
          </v-row>
          <label>Email address</label>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            type="email"
            dense
            color="#4c248a"
            single-line
            outlined
          />

          <label>Phone Number</label>
          <vue-tel-input
            autocomplete="null"
            @country-changed="countryChanged"
            class="phoneField"
            v-model="phone"
            type="number"
            style="height: 46px; width: 100%; margin: 0px 0px 10px 0px"
            :rules="phoneRules"
          ></vue-tel-input>

          <label>Password</label>
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            dense
            color="#4c248a"
            single-line
            :type="show ? 'text' : 'password'"
            outlined
            @click:append="show = !show"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          />

          <label>Confirm Password</label>
          <v-text-field
            v-model="confirmPassword"
            required
            dense
            color="#4c248a"
            single-line
            :type="showConfirm ? 'text' : 'password'"
            outlined
            @click:append="showConfirm = !showConfirm"
            :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          />
        </v-form>

       

        <div class="my-3">
          <v-btn :loading="loading" @click="signup" block large color="#4c248a" class="white--text"
            >Create Account</v-btn
          >
          <p class="text-center my-3">
            Already have an account?
            <router-link to="/login" class="brandcolor">Login</router-link>
          </p>
        </div>
    </div>
    </v-col>
    <v-col class="hidden-md-and-down">
        <div style="position:relative;height:100%">
        <v-img class="image" height="100%" width="600px" src="../../../assets/registration-image.jpg"/>
       </div>
    </v-col>
    </v-row>
</v-card>
    </v-main>
  </v-app>
</template>



<script>
import axios from 'axios'


export default {
  data() {
    return {
      valid: true,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      password: "",
      confirmPassword: "",
      firstNameRules: [(v) => !!v || "First name is required"],
      lastNameRules: [(v) => !!v || "Last name is required"],
      emailRules: [
        (v) => !!v || "Email address is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone number is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be greater than 6 characters",
      ],
      show: false,
      showConfirm:false,
      alert:false,
      message:"",
      loading:false
    };
  },

  
  methods: {
    countryChanged(e) {
      this.country = e.dialCode;
    },

    async signup() {
      await this.$refs.form.validate();
      if(!this.valid){
        return
      }
      if(this.password =='' || this.confirmPassword==''|| this.phone ==''){
        this.alert = true
        this.message="Please complete all fields to create an account"
        setTimeout(()=>{
            this.alert = false
        },1000)
        window.scrollTo(0,0)
        return
      }

      

      if(this.password != this.confirmPassword){
        this.alert = true
        this.message="Your password and confirm password are not the same"
        window.scrollTo(0,0)
        setTimeout(()=>{
            this.alert = false
        },1000)
        return
      }


      this.loading = true

      axios({
        method:"POST",
        url: process.env.VUE_APP_API_URL + "/signup",
        headers:{
            Accept:"*/*",
            ContentType:"application/json"
        },
        data:{
            firstName:this.firstName,
            lastName:this.lastName,
            email:this.email,
            phone:this.phone.replaceAll(' ',''),
            password:this.password
        }
      }).then(()=>{
        this.loading = false
        this.$swal({
            icon:"success",
            title:"Confirmation Email Sent",
            text:"A confirmation email has been sent to your email address. Kindly tap the button to verify your account and complete your registration",
            confirmButtonText:`<p class="brandcolor">Open Email</p>` 
        }).then((result)=>{
            if(result.isConfirmed){
                window.open("https://gmail.com","_blank")
            }
        })
      }).catch((error)=>{
        this.loading = false
        console.log(error)
        this.$swal({
            icon:"error",
            title:"Account Not Created",
            text:error.response.data.email,
            confirmButtonText:`<p class="brandcolor">Ok</p>` 
        })
      })


    },
  },
};
</script>


<style scoped>
.signupForm {
  background: #feeaf3;
  padding: 60px !important;
}

.signupCard {
  padding: 60px 40px 0px 40px;
  background:white
}

label {
  font-size: 13px;
}

.v-text-field {
  height: 54px !important;
}

span {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  line-height: 12px;
}

h2 {
  margin-bottom: -10px;
}

.image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, #8b3694, #4c248a);
    opacity: .7;
}



@media screen and (max-width: 600px) {
  .signupForm {
    background: #feeaf3;
    padding: 60px 10px !important;
  }
  .signupCard {
    padding: 20px 20px;
  }
}
</style>