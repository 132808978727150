import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import store from './store'

Vue.config.productionTip = false


Vue.use(VueTelInput,{
  defaultCountry:234,
  dropdownOptions:{
      showSearchBox:true
  },
  inputOptions:{
    placeholder:"",
    type:"tel"
  }
},
)

const options = {
  confirmButtonColor: "white",
  confirmButtonTextColor:"#4c248a",
  cancelButtonColor: '#000000',
  background:"#4c248a",
  color:"#ffffff"
};

Vue.use(VueSweetalert2,options);

console.log("env", process.env)

new Vue({
  vuetify,
  router,
  store,
  VueSweetalert2,
  render: h => h(App)
}).$mount('#app')
