<template>
  <v-main style="margin: 50px 0px 0px 200px">
    <div>
      <h1 class="my-3" style="color: #310059">Applications</h1>
      <TableFilter
        :showStatus="true"
        @assignReviewer="assignReviewer"
        @searchList="searchList"
        @filterBy="filterBy"
        :applications="admin.filteredApplications"
        :allApplications="admin.applications"
      />
      <ApplicationTable
        :selectedApp="selectedApp"
        @showSelected="showSelected"
        :assignreviewer="assignreviewer"
        @assignReviewer="assignReviewer"
        :reviewers="admin.reviewers"
        :applications="admin.filteredApplications"
        :loading="admin.loading"
      />
      <ApplicationTableLoading :loading="admin.loading" />
    </div>

    <div v-if="admin.applications" class="py-5 d-flex horizontalScroll">
      <v-icon @click="prevPagination(admin.applicationPagination)"
        >mdi-chevron-left</v-icon
      >
      <div class="mx-2" v-for="number in Math.ceil(total / 20)" :key="number">
        <div
          @click="setPagination(number)"
          :class="admin.applicationPagination == number ? 'pagination' : ''"
          small
          width="2px"
          outlined
        >
          {{ number }}
        </div>
      </div>
      <v-icon @click="nextPagination(admin.applicationPagination)"
        >mdi-chevron-right</v-icon
      >
    </div>
  </v-main>
</template>



<script lang="ts">
import ApplicationTable from "./ApplicationTable.vue";
import TableFilter from "./TableFilter.vue";
import { mapState } from "vuex";
import ApplicationTableLoading from "./ApplicationTableLoading.vue";

export default {
  components: { ApplicationTable, TableFilter, ApplicationTableLoading },
  computed: {
    ...mapState(["admin"]),
  },
  data: () => ({
    search: "",
    total: JSON.parse(sessionStorage.getItem("stats")).total,
    assignreviewer: false,
    selectedApp: [],
  }),
  methods: {
    searchList(e) {
      console.log(e);
      this.$store.dispatch("admin/searchApplications", e);
    },
    filterBy(e) {
      this.$store.dispatch("admin/filterBy", e);
    },
    setPagination(e) {
      window.scrollTo(0, 0);
      this.$store.dispatch("admin/setApplicationPagination", e);
    },

    prevPagination(e) {
      window.scrollTo(0, 0);
      if (e > 1) {
        let number = e - 1;
        this.$store.dispatch("admin/setApplicationPagination", number);
      }
    },

    nextPagination(e) {
      window.scrollTo(0, 0);
      if (e < Math.floor(this.total / 20)) {
        let number = e + 1;
        this.$store.dispatch("admin/setApplicationPagination", number);
      }
    },
    assignReviewer() {
      if (this.selectedApp.length == 0) {
        this.$swal({
          icon: "info",
          title: "Application Not Selected",
          text: "You need to select atleast one application",
          confirmButtonText: `<p class='brandcolor'>Ok</p>`,
        });
        return;
      }
      this.assignreviewer = !this.assignreviewer;
    },
    showSelected(e) {
      this.selectedApp = e;
      console.log(this.selectedApp);
    },
  },
};
</script>

<style scoped>
.horizontalScroll {
  overflow-x: scroll;
  cursor: pointer;
}

.pagination {
  border-radius: 50%;
  background: #310059;
  padding: 0 6px;
  color: white;
  cursor: pointer;
}
</style>