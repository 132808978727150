<template>
  <div class="applicationTable">
    <table cellspacing="0">
      <thead>
        <tr class="tableHeading">
          <th>Applicant</th>
          <th>Email</th>
          <th>Date Modified</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody v-for="(application, i) in applications" :key="i">
        <tr>
          <td>{{ application.firstName }} {{ application.lastName }}</td>
          <td>{{ application.email }}</td>
         
          <td>{{ application.created }}</td>
          <td>{{ application.status }}</td>
          <td>
            <v-btn
              @click="viewApplication(application._id)"
              color="#310059"
              class="white--text"
              :disabled="application.status === 'Reviewed'"
              >Review</v-btn
            >
          </td>
        </tr>

        <tr v-if="loading">
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td>
            <v-skeleton-loader type="text"></v-skeleton-loader>
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </td>
        </tr>

        <tr v-if="loading">
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td>
            <v-skeleton-loader type="text"></v-skeleton-loader>
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </td>
        </tr>

        <tr v-if="loading">
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
          <td>
            <v-skeleton-loader type="text"></v-skeleton-loader>
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  props: {
    applications: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  methods: {
    viewApplication(id) {
      this.$router.push("application/" + id);
    },
  },
};
</script>


<style scoped>
.tableHeading {
  background: #310059 !important;
  border-radius: 20px;
  color: white !important;
}

table th:first-child {
  border-radius: 10px 0 0 0px;
}

table th:last-child {
  border-radius: 0 10px 0px 0;
}

.tableHeading tr {
  background: white !important;
  border-radius: 20px;
}

td {
  border-bottom: 1px solid #f9fafb !important;
  padding: 10px !important;
  text-align: center;
  border: 1px solid #310059;
}
th {
  width: 150px;
  padding: 10px;
  border-right: 1px solid white !important;
}

.applicationTable {
  margin: 0px 60px 0px 0px;
}
</style>