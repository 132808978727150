<template>
  <v-app>
    <ProfileComponent />
  </v-app>
</template>



<script>
import ProfileComponent from "../../components/Applicant/ProfileComponent.vue";

export default {
  components: {
    ProfileComponent,
  },
  created() {
    this.$store.dispatch("fetchUser");
  },
  metaInfo() {
    return {
      title: `Profile`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content:
            "Apply to the BMGA fellows program"
        },
        {
          property: "og:title",
          content: "Profile | BMGA Foundation"
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Profile | BMGA Foundation"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/signup" 
        },
      ]
    };
  },
};
</script>