<template>
  <div>
    <v-navigation-drawer
      fixed
      absolute
      permanent
      left
      style="padding: 30px 30px 0px 30px"
      class="hidden-md-and-down"
    >
      <v-list>
        <v-list-item-group
          v-for="item in items"
          :key="item.title"
          color="#0582D2"
        >
          <v-list-item
            v-if="item.title != 'Applications'"
            :key="item.title"
            :to="item.link"
            link
          >
            <v-list-item-icon style="margin-right: 20px">
              <v-icon color="#310059">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content :to="item.link">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group color="#310059" v-if="item.title === 'Applications'">
            <template v-slot:activator>
              <v-list-item-icon style="margin-right: 20px">
                <v-icon color="#310059">mdi-file-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content color="#0582D2" :to="item.link">
                <v-list-item-title style="color:#310059">Applications</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item href="/admin/applications">
              <v-list-item-title>All Applications</v-list-item-title>
            </v-list-item>

            <v-list-item href="/admin/applications/pending">
              <v-list-item-title>Pending Applications</v-list-item-title>
            </v-list-item>

            <v-list-item href="/admin/applications/submitted">
              <v-list-item-title>Submitted Applications</v-list-item-title>
            </v-list-item>

            <v-list-item href="/admin/applications/stage/1">
              <v-list-item-title>Review - Level 1</v-list-item-title>
            </v-list-item>

            <v-list-item href="/admin/applications/stage/2">
              <v-list-item-title>Semi-Final</v-list-item-title>
            </v-list-item>

            <v-list-item href="/admin/applications/stage/3">
              <v-list-item-title>Review - Level 2</v-list-item-title>
            </v-list-item>

            <v-list-item href="/admin/applications/stage/4">
              <v-list-item-title>Final</v-list-item-title>
            </v-list-item>


            <v-list-item href="/admin/applications/stage/5">
              <v-list-item-title>Enrolled</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>

        <v-list-item @click="logOut()">
          <v-list-item-icon style="margin-right: 20px">
            <v-icon color="#310059">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "NavMenu",
  data: () => ({
    items: [
      {
        title: "Dashboard",
        icon: "mdi-view-grid-outline",
        link: "/admin/dashboard",
      },
      {
        title: "Applications",
        icon: "mdi-file-multiple",
        link: "/admin/applications",
      },
      {
        title: "Applicants",
        icon: "mdi-account-multiple",
        link: "/admin/applicants",
      },
      { title: "Reviewers", icon: "mdi-magnify", link: "/admin/reviewers" },
      { title: "Settings", icon: "mdi-cog-outline", link: "/admin/settings" },
    ],
    drawer: false,
    group: null,
    userData: "",
    name: "",
    image: "",
    notifications: [
      {
        title:
          "Jumoke Kolade-Johnson has a pending action for you. Check it out.",
        time: "13h",
        image: "",
      },
      {
        title:
          "Service downtime. Our third party service is currently experiencing a downtime. Full service to be restored soon",
        icon: "mdi-cog-outline",
        link: "/settings",
      },
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },

  computed: {},

  props: {},

  created() {},

  methods: {
    goToDispute(title) {
      if (title == "Dispute") {
        window.open("mailto:disputes@kuleanpay.com", "_blank");
      }
    },
    logOut() {
      sessionStorage.removeItem("adminToken");
      sessionStorage.removeItem("stats");
      
      this.$router.push("/admin/login");
    },
  },
};
</script>


<style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>