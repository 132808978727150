<template>
  <v-app>
    <LoginComponent />
  </v-app>
</template>



<script>
import LoginComponent from "../../components/Admin/LoginComponent.vue";

export default {
  components: {
    LoginComponent,
  },
  created(){
    if(sessionStorage.getItem("adminToken") != null){
          window.location.href = "/admin/dashboard"
        }
  }
};
</script>