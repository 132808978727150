<template>
    <v-app>
        <CompleteEnrollmentModal :showCheckboxModal="showCheckboxModal" @close="showCheckboxModal = false" />
        <DashboardComponent/>
    </v-app>
</template>



<script>
import DashboardComponent from "../../components/Applicant/DashboardComponent.vue"
import CompleteEnrollmentModal from "../../components/CompleteEnrollmentModal.vue"

export default {
    components:{
        DashboardComponent,
        CompleteEnrollmentModal
    },
    data() {
      return {
        showCheckboxModal: false
      }
    },  
    methods: {
      openCheckboxModal() {
        console.log("here.")
        this.showCheckboxModal = true
      }
    },
    created(){
        this.$store.dispatch("fetchUser")
        this.$store.dispatch("fetchSettings")
        this.$store.dispatch("fetchApplications")
        if(sessionStorage.getItem("bmgaToken") == null){
          this.$router.push("/login")
        }

        console.log(this.$route.query.completeEnrollment)
        console.log(this.$route.query)

        if (this.$route.query?.completeEnrollment) {
          this.openCheckboxModal()
        }
    },

    metaInfo() {
    return {
      title: `Dashboard`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content:
            "Apply to the BMGA fellows program"
        },
        {
          property: "og:title",
          content: "Dashboard | BMGA Foundation"
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Dashboard | BMGA Foundation"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/signup" 
        },
      ]
    };
  },
}
</script>