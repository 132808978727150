import { render, staticRenderFns } from "./SingleApplicantPage.vue?vue&type=template&id=f1dce5d8&scoped=true"
import script from "./SingleApplicantPage.vue?vue&type=script&lang=ts"
export * from "./SingleApplicantPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_vue-template-compiler@2.7.16_webpack@5.90.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1dce5d8",
  null
  
)

export default component.exports