<template>
  <div class="applicationTable">
    <table cellspacing="0">
      <thead>
        <tr v-if="!loading" class="tableHeading">
          <th>Applicant</th>
          <th>Email</th>
          <th>Date Modified</th>
          <th>Stage</th>
          <th>Score</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody v-for="(application, i) in applications" :key="i">
        <tr>
          <td>{{ application.firstName }} {{ application.lastName }}</td>
          <td>{{ application.email }}</td>
          <td>{{ application.modified?application.modified:application.created }}</td>
          <td>{{ application.stage ? application.stage:0 }} </td>
          <td>{{ application.assessment ?  application.assessment.toFixed(0):0 }}%</td>
          <td>{{ application.status }}</td>
          <td>
            <v-menu
      transition="slide-x-transition"
      bottom
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          dark
          v-bind="attrs"
          v-on="on"
        >
        <v-icon color="#310059">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
        >
          <v-list-item-title @click="viewApplication(application._id)">View</v-list-item-title>
        </v-list-item>

        <v-list-item
        >
          <v-list-item-title @click="assignReviewer(application._id)">Assign Reviewer</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
          </td>
        </tr>
      </tbody>
    </table>


    <v-dialog persistent max-width="580px" v-model="assignreviewer">
      <AssignReviewer :reviewers="reviewers" @showReviewer="showReviewer" :applicationId="applicationId"/>
    </v-dialog>
  </div>
</template>


<script>
import AssignReviewer from '../Application/AssignReviewer.vue';

export default {
  components:{
    AssignReviewer
  },
  props: {
    applications: {
      type: Array,
    },
    loading:{
      type:Boolean
    },
    reviewers:{
      type:Array
    }
  },
  data:()=>({
    applicationId:"",
    assignreviewer:false
  }),
  methods:{
    viewApplication(id){
      this.$router.push("application/"+id)
    },
    assignReviewer(id){
      this.applicationId = id
      this.assignreviewer = !this.assignreviewer
    },
    showReviewer(){
      this.assignreviewer = !this.assignreviewer
    }
  }
};
</script>


<style scoped>
.tableHeading {
  background: #310059 !important;
  color: white !important;
}



.tableHeading tr {
  background: #f9fafb;
  border-radius: 20px;
}

td {
  border-bottom: 1px solid #f9fafb;
  padding: 10px !important;
  text-align: center;
  border: 1px solid #310059;
}
th {
  width: 150px;
  padding: 10px;
  border-right: 1px solid white;
}

.applicationTable {
  margin: 0px 60px 0px 0px;
}
</style>