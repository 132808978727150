<template>
  <v-main class="fullheight">
    <v-sheet class="deadlineContainer">
      <div class="d-flex justify-space-between">
        <div><h2>Set Deadline</h2></div>
        <div>
          <v-btn small @click="closeModal" fab depressed
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
      </div>

      <div>
        <label>Application Deadline. Current: <span class="red--text font-weight-bold">{{ settings.applicationDeadline }}</span></label>
        <v-text-field
          v-model="applicationDeadline"
          dense
          type="date"
          outlined
          color="#310059"
        />
      </div>

      <div class="my-3">
        <label>Review Deadline. Current: <span class="red--text font-weight-bold">{{ settings.reviewDeadline }}</span></label>
        <v-text-field  color="#310059" v-model="reviewDeadline" dense type="date" outlined />
      </div>

      <v-btn
        block
        @click="submitSettings"
        color="#310059"
        class="white--text"
        large
        :loading="btnloading"
        >Submit</v-btn
      >
    </v-sheet>
  </v-main>
</template>


<script lang="ts">
import axios from 'axios';
export default {
  data:()=>({
      applicationDeadline: "",
      reviewDeadline: "",
      btnloading:false
  }),
  props:{
    settings:{
      type:Object
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    submitSettings() {
        this.btnloading = true
        console.log(this.applicationDeadline)
        axios({
            method:"POST",
            url:process.env.VUE_APP_API_URL + "/admin/settings",
            headers:{
                Authorization:"Bearer "+sessionStorage.getItem("adminToken"),
                Accept:"*/*",
                ContentType:"application/json"
            },
            data:{
                applicationDeadline:this.applicationDeadline ? this.applicationDeadline : this.settings.applicationDeadline,
                reviewDeadline:this.reviewDeadline ? this.reviewDeadline : this.settings.reviewDeadline
            }
        }).then(()=>{
            this.btnloading = false
            this.$store.dispatch("admin/fetchSettings")
            this.$swal({
                icon:"success",
                title:"Settings Updated",
                text:"You have successfully updated your deadlines",
                confirmButtonText:`<p class='brandcolor'>Ok</p>`
              })
        }).catch((error)=>{
            console.log(error)
            this.btnloading = false
        })
    },
  },
};
</script>


<style scoped>
.fullheight {
  height: 100%;
  background: white;
}

.deadlineContainer {
  padding: 30px;
}

.bgColor {
  background: pink !important;
  justify-content: space-between !important;
}
</style>