import { render, staticRenderFns } from "./ApplicationPage.vue?vue&type=template&id=969400d8&scoped=true"
import script from "./ApplicationPage.vue?vue&type=script&lang=ts"
export * from "./ApplicationPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_vue-template-compiler@2.7.16_webpack@5.90.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "969400d8",
  null
  
)

export default component.exports