<template>
    <v-app>
        <v-main class= "text-center contain">
           <v-overlay :value="overlay" :opacity="0.6">
            <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
      <p>Verifying account, please wait...</p>
           </v-overlay>
        </v-main>
    </v-app>
</template>



<script>
import axios from "axios"
export default {
    data(){
        return{
            overlay:true
        }
    },

    created(){
        axios({
            method:"POST",
            url:process.env.VUE_APP_API_URL + "/verify-user",
            headers:{
                ContentType:"application/json",
                Accept:"*/*"
            },
            data:{
                id:this.$route.query.id
            }

        }).then((response)=>{
            this.overlay = false
            console.log(response)
            this.$swal({
                icon:"success",
                title:"Account Verified!",
                text:"You have successfully verified your account. You can now proceed to login",
                confirmButtonText:`<p class="brandcolor">Login</p>`,
                allowOutsideClick: false
            }).then((result)=>{
                if(result.isConfirmed){
                    this.$router.push("/login")
                }
            })
        })
    }

}
</script>


<style scoped>
.contain{
    margin:90px 0px
}
</style>