<template>
  <v-app>
    <v-main class="hidden-md-and-down">
      <v-row style="background: #f8f8f9; height: 101%">
        <v-col cols="12" md="1" sm="1" lg="1">
          <AdminSideBar />
        </v-col>
        <v-col cols="12" md="11" sm="11" lg="11">
          <AppBar />
          <ApplicationComponent />
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
  
  
<script lang="ts">
import AdminSideBar from "../../components/Reviewer/AdminSideBar.vue";
import ApplicationComponent from "@/components/Reviewer/Application/ApplicationComponent.vue";
import AppBar from "@/components/Reviewer/AppBar.vue";

export default {
  components: {
    AdminSideBar,
    ApplicationComponent,
    AppBar,
  },

  created() {
    this.$store.dispatch("reviewer/fetchApplications");
    if (sessionStorage.getItem("reviewerToken") == null) {
      this.$router.push("/reviewer/login");
    }
  },
};
</script>
  
  <style scoped>
</style>