<template>
  <v-main style="margin: 50px 0px 0px 200px">
    <div>
      <h1 class="my-3" style="color: #310059">Applicants</h1>
      <TableFilter
        @searchList="searchList"
        @filterBy="filterBy"
        :applicants="admin.filteredApplicants"
      />
      <ApplicantTable
        :applications="admin.filteredApplicants"
        :loading="admin.loading"
      />
      <ApplicantTableLoading :loading="admin.loading" />
    </div>

    <div v-if="admin.applicants" class="py-5 d-flex horizontalScroll">
      <v-icon @click="prevPagination(admin.applicantPagination)">mdi-chevron-left</v-icon>
      <div
        class="mx-2"
        v-for="number in Math.floor(total / 20)"
        :key="number"
      >
        <div
          @click="setPagination(number)"
          :class="admin.applicantPagination == number ? 'pagination' : ''"
          small
          width="2px"
          outlined
        >
          {{ number }}
        </div>
      </div>
      <v-icon @click="nextPagination(admin.applicantPagination)">mdi-chevron-right</v-icon>
    </div>
  </v-main>
</template>



<script lang="ts">
import ApplicantTable from "./ApplicantTable.vue";
import TableFilter from "./TableFilter.vue";
import { mapState } from "vuex";
import ApplicantTableLoading from "./ApplicantTableLoading.vue";


export default {
  components: { ApplicantTable, TableFilter,ApplicantTableLoading },
  computed: {
    ...mapState(["admin"]),
  },
  data: () => ({
    search: "",
    total:JSON.parse(sessionStorage.getItem("stats")).applicantsTotal
  }),

  props:{
    applicants:{
      type:Array
    }
  },

  methods: {
    searchList(e) {
      this.$store.dispatch("admin/searchApplicants", e);
    },
    filterBy(e) {
      this.$store.dispatch("admin/filterApplicants", e);
    },

    setPagination(e) {
      window.scrollTo(0,0)
      this.$store.dispatch("admin/setApplicantPagination", e);
    },

    prevPagination(e) {
      window.scrollTo(0,0)
      if (e > 1) {
        let number = e - 1;
        this.number == e - 1;
        this.$store.dispatch("admin/setApplicantPagination", number);
      }
    },

    nextPagination(e) {
      window.scrollTo(0,0)
      if (e < Math.floor(this.total/ 20)) {
        let number = e + 1;
        this.$store.dispatch("admin/setApplicantPagination", number);
      }
    },
  },
};
</script>

<style scoped>
.horizontalScroll {
  overflow-x: scroll;
  cursor:pointer
}

.pagination {
  border-radius: 50%;
  background: #310059;
  padding: 0 7px;
  color: white;
  cursor: pointer;
}
</style>