<template>
  <v-app class="backgroundColor">
    <AppBar />
    <AppSidebar :page="$route.path" :userData="userData" />
    <v-container class="my-5 container">
      <h1 class="brandcolor topTitle">Marketing Information</h1>
      <v-card flat class="pa-5 my-5">
        <div>
          Please enter your information in each of the sections. Fields with an
          asterisk (*) are required and must be completed before you can submit
          your application. Additional questions may become required based on
          your answers to a previous question. Please remember to save your
          application regularly to avoid losing your answers.
        </div></v-card
      >

      <v-card class="pa-4" flat>
        <v-form>
          <p>How did you find out about the BMGA Fellows Program?</p>

          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="bmga-website"
            label="BMGA website"
            >BMGA website</v-checkbox
          >
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="bmga-fellow"
            label="BMGA Fellow"
            >BMGA Fellow</v-checkbox
          >

          <p v-if="application.marketingInfo == 'bmga-fellow'" class="instructions">
            Insert the Name of the BMGA Fellow who Referred You:
          </p>
          <v-text-field
            v-model="application.referredByBMGAFellow"
            v-if="application.marketingInfo == 'bmga-fellow'"
            class="mt-5"
            color="#4c248a"
            outlined
            dense
            required
          />

          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="search-engine"
            label="Search Engine"
            >Search Engine</v-checkbox
          >
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="facebook"
            label="Facebook"
          >
            Facebook</v-checkbox
          >
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="linkedin"
            label="LinkedIn"
            >LinkedIn</v-checkbox
          >
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="instagram"
            label="Instagram"
            >Instagram</v-checkbox
          >
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="Twitter"
            label="Twitter"
            >Twitter</v-checkbox
          >
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="university"
            label="University Career Center"
            >University Career Centre</v-checkbox
          >
          <v-checkbox
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="other"
            label="Other"
            >Other</v-checkbox
          >

          <p v-if="application.marketingInfo == 'other'" class="instructions">
            How did you hear about the BMGA Fellows Program?
          </p>
          <v-text-field
            v-model="otherMarketingInfo"
            v-if="application.marketingInfo == 'other'"
            class="mt-5"
            color="#4c248a"
            outlined
            dense
            required
          />
        </v-form>

        <div class="mt-12">
          <v-btn
            @click="updateApplication"
            :loading="loading"
            block
            x-large
            class="primaryBtn"
            >Save & Continue</v-btn
          >
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>
      
      
      <script>
import { mapState } from "vuex";
import AppBar from "./AppBar.vue";
import AppSidebar from "./AppSidebar.vue";
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      otherMarketingInfo: ""
    };
  },
  components: { AppBar, AppSidebar },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      userData: "userData",
      application: "application",
    }),
  },

  methods: {
    updateApplication() {
      this.loading = true;
      const payload = {
        applicationId: this.application._id,
        marketingInfo: this.application.marketingInfo == 'other' ? this.otherMarketingInfo : this.application.marketingInfo,
        referredByBMGAFellow: this.application.referredByBMGAFellow
      };
      console.log(payload);
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/update-application",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: payload,
      }).then((response) => {
        console.log(response);
        this.loading = false;
        this.$router.push("/application/confidential-information");
      });
    },
  },
};
</script>
      
      
      
      
      <style scoped>
@media only screen and (max-width: 600px) {
  .topTitle {
    padding: 0px 0px 0px 15px;
  }
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 776px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 894px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 996px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

.v-text-field {
  height: 54px !important;
}

.instructions {
  font-size: 13px;
  margin: 0px 0px 10px 0px;
}

.checkBoxes {
  margin-top: -27px;
}

.descriptionBox {
  margin: 0px 0px 30px 0px;
}

.v-textarea,
textarea {
  resize: none !important;
}
textarea {
  width: 100%;
  outline: 1px solid #4c248a;
  border-radius: 5px;
  height: 250px;
  padding: 10px;
}
</style>