<template>
    <v-app>
        <ForgotPasswordComponent/>
    </v-app>
</template>



<script>
import ForgotPasswordComponent from "../../components/Applicant/Auth/ForgotPasswordComponent.vue"

export default {
    components:{
        ForgotPasswordComponent
    },
    metaInfo() {
    return {
      title: `Forgot Password`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content:
            "Start your application on the BMGA application portal"
        },
        {
          property: "og:title",
          content: "Forgot Password | BMGA Foundation"
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Forgot Password | BMGA Foundation"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/signup" 
        },
      ]
    };
  },
}
</script>