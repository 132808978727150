<template>
  <div class="d-flex">
    <div style="width:400px">
    <v-text-field prepend-inner-icon="mdi-magnify" v-model="search" @keyup="searchList" color="#310059" dense outlined label="Search..."/>
  </div>
    <v-menu class="ml-2" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                Sort by: {{ status }} <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item @click="filterBy('verified')">
                  <v-list-item-title>Verified</v-list-item-title>
                </v-list-item>
                <v-list-item @click="filterBy('unverified')">
                  <v-list-item-title>Unverified</v-list-item-title>
                </v-list-item>  
              
                <v-list-item @click="filterBy('Date')">
                  <v-list-item-title>Date (Newest)</v-list-item-title>
                </v-list-item>  
              </v-list-item-group>
            </v-list>
          </v-menu>

          <div v-if="status !=''" class="mt-2">No: {{ applicants && applicants.length }} applicants</div>
        </div>
</template>


<script lang="ts">
export default {
    data:()=>({
        status:"",
        search:""
    }),
   
   
    methods:{
      searchList(){
        console.log(this.search)
        this.$emit("searchList",this.search)
      },
      filterBy(e){
        this.status = e
        this.$emit("filterBy",e)
      }
    },
    props:{
        name:{
            type:String
        },
        applicants:{
        type:Array
      }
    }
}
</script>