<template>
    <v-main style="margin:50px 0px 0px 200px">
       <div>       
        <h1 class="my-3" style="color:#310059">Application</h1>
        <SingleApplicationContent :application="admin.application"/>
      </div>
    </v-main>
</template>



<script>
import Vue from 'vue'
import SingleApplicationContent from './SingleApplicationContent.vue';
import { mapState } from 'vuex';
export default Vue.extend({
    data: () => ({}),
    components: { SingleApplicationContent },
    computed: {
      ...mapState(['admin']),
    },
})

</script>