<template>
    <v-dialog v-model="showDialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h6"></span>
          <!-- <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-text>
          <v-form>
            <p class="label">
              Have you read the BMGA Fellows Program Terms of Reference document and returned your signed copy to the BMGA Team?
            </p>
              <v-radio-group
                class="noMargin"
                v-model="readDocs"
              >
                <v-radio color="#4c248a" label="Yes, I have" value="yes"></v-radio>
                <v-radio color="#4c248a" label="No, I have not" value="no"></v-radio>
              </v-radio-group>


              <v-btn v-if="readDocs == 'yes'" to="/complete-enrollment" color="#4c248a" class="white--text mb-4" large
                >Proceed</v-btn
              >

              <p v-if="readDocs == 'no'">
                You have not completed the steps required to be able to enrol in the BMGA Fellows Program. Kindly refer to your acceptance email for the steps to follow to complete your enrollment.
              </p>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        readDocs: ""
      };
    },
    computed: {
        showDialog() {
            return this.$props.showCheckboxModal;
        },
    },
    props: ["showCheckboxModal"],
    methods: {
        submit() {
        // Handle form submission here
        // Access selected options through `this.checkboxes`
            this.close()
        },
        close() {
            this.$emit('close'); // Emit an event to close the modal
        },
    },
  };
  </script>
  