<template>
  <v-app>
    <v-main class="signupForm">
      <v-card elevation="8" width="500px" class="rounded-lg mx-auto">
        <div class="signupCard" tile>
          <v-alert v-model="alert" type="error">{{ message }}</v-alert>
          <h2 class="brandcolor text-center">Admin Login</h2>
          <div class="text-center">Login to BMGA Admin Portal</div>
          <v-form ref="form">
            <label>Email address</label>
            <v-text-field
              v-model="email"
             
              dense
              color="#4c248a"
              single-line
              outlined
            />

            <label>Password</label>
            <v-text-field
              v-model="password"
              dense
              color="#4c248a"
              single-line
              :type="show ? 'text' : 'password'"
              outlined
              @click:append="show = !show"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            />
        
          
          </v-form>

          <div class="my-3">
            <v-btn
              :loading="loading"
              @click="signup"
              block
              x-large
              color="#4c248a"
              class="white--text"
              >Login</v-btn
            >
          
          </div>
        </div>
      </v-card>
    </v-main>
  </v-app>
</template>
  
  
  
  <script>
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      alert:false,
      message:"",
      show:false
    };
  },
  methods: {
    async signup() {
      if (this.password == "") {
        this.alert = true;
        this.message = "Please enter your password";
        setTimeout(() => {
          this.alert = false;
        }, 1000);
        window.scrollTo(0, 0);
        return;
      }

      this.loading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/login",
        headers: {
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          email: this.email,
          password: this.password,
        },
      })
        .then((response) => {
          if(response.status == 290){
            this.alert = true
            this.message = "Invalid login details"
            this.loading = false
            return
          }
          sessionStorage.setItem("adminToken",response.data.token)
          window.location.href = "/admin/dashboard"
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Login Unsuccessful",
            text: error.response.data.message,
            confirmButtonText: `<p class="brandcolor">Ok</p>`,
          });
        });
    },
  },
};
</script>
  
  
  <style scoped>
.signupForm {
  background:#310059 ;
  background-size: cover;
  padding: 60px !important;
}

.signupCard {
  padding: 20px 40px;
  background: white;
}

label {
  font-size: 13px;
}

.v-text-field {
  height: 54px !important;
}

span {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  line-height: 12px;
}

h2 {
  margin-bottom: -3px;
}

.image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, #8b3694, #4c248a);
  opacity: 0.7;
}

@media screen and (max-width: 600px) {
  .signupForm {
    background: #feeaf3;
    padding: 60px 15px !important;
  }
  .signupCard {
    padding: 20px 20px;
  }
}
</style>