<template>
    <div>
        <div class="d-flex">
        <v-card flat width="280px" class="pa-5">
            <v-progress-circular v-if="admin.applicantstatloading" indeterminate></v-progress-circular>
            <h2 v-if="!admin.applicantstatloading">{{ admin.totalApplicants }}</h2>
            <p>applicants</p>
        </v-card>

        <v-card color="#310059" flat width="280px" class="text-center white--text pa-5 mx-6">
            <v-progress-circular v-if="admin.statloading" indeterminate></v-progress-circular>
            <h2 v-if="!admin.statloading">{{ admin.total }}</h2>
            <p>applications</p>
           <div class="d-flex justify-space-around">
           
        </div>
        </v-card>

        <v-card flat width="280px" class="pa-5 mx-3">
            <v-progress-circular v-if="admin.reviewers.length == 0" indeterminate></v-progress-circular>
            <h2 v-if="admin.reviewers.length != 0">{{ admin.reviewers.length }}</h2>
            <p>reviewers</p>
        </v-card>
       </div>


       <h2 class="text-center mt-6">Applications Statistics</h2>

       <div class="my-4 d-flex">
        <v-card flat width="280px" class="text-center applicationStat pa-5">
            <v-progress-circular v-if="admin.statloading" indeterminate></v-progress-circular>
            <h2 v-if="!admin.statloading">{{ admin.submitted }}</h2>
            <p>Submitted</p>
        </v-card>

        <v-card flat width="280px" class="applicationStat text-center pa-5 mx-6">
            <v-progress-circular v-if="admin.statloading" indeterminate></v-progress-circular>
            <h2 v-if="!admin.statloading">{{ admin.inprogress }}</h2>
            <p>In progress</p>
           <div class="d-flex justify-space-around">
           
        </div>
        </v-card>

        <v-card flat width="280px" class="applicationStat text-center pa-5 mx-6">
            <v-progress-circular v-if="admin.statloading" indeterminate></v-progress-circular>
            <h2 v-if="!admin.statloading">{{ admin.reviewed }}</h2>
            <p>Reviewed</p>
           <div class="d-flex justify-space-around">
           
        </div>
        </v-card>

        <v-card  flat width="280px" class="applicationStat text-center pa-5 mx-6">
            <v-progress-circular v-if="admin.statloading" indeterminate></v-progress-circular>
            <h2 v-if="!admin.statloading">{{ admin.accepted }}</h2>
            <p>Accepted</p>
           <div class="d-flex justify-space-around">
           
        </div>
        </v-card>


        <v-card  flat width="280px" class="applicationStat text-center pa-5 mx-6">
            <v-progress-circular v-if="admin.statloading" indeterminate></v-progress-circular>
            <h2 v-if="!admin.statloading">{{ admin.rejected }}</h2>
            <p>Rejected</p>
           <div class="d-flex justify-space-around">
           
        </div>
        </v-card>

      
       </div>
    </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
    computed:{
      ...mapState(['admin'])
    },
}
</script>

<style scoped>
.applicationStat{
    border:solid 1px #310059;
    background:#f8f8f9
}
</style>