<template>
    <v-app>
        <PersonalInformationComponent/>
    </v-app>
</template>

<script>
import PersonalInformationComponent from "../../components/Applicant/PersonalInformation.vue"


export default {
    components:{
        PersonalInformationComponent
    },

    created(){
        console.log(this.userData)
        this.$store.dispatch("fetchApplications")
    },

    metaInfo() {
    return {
      title: `Personal Information`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content:
            "Apply to the BMGA fellows program"
        },
        {
          property: "og:title",
          content: "Personal Information | BMGA Foundation"
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Personal Information | BMGA Foundation"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/signup" 
        },
      ]
    };
  },
}
</script>


<style scoped>

</style>