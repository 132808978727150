<template>
  <div class="applicationTable">
    <table cellspacing="0">
      <thead>
        <tr v-if="!loading" class="tableHeading">
          <th></th>
          <th>Full name</th>
          <th>Email</th>
          <th>Phone</th>
          <!-- <th>Id</th> -->
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody v-for="(application, i) in applications" :key="i">
        <tr>
          <td><v-checkbox color="#310059" /></td>
          <td>{{ application.firstName }} {{ application.lastName }}</td>
          <td>{{ application.email }}</td>
          <td>{{ application.phone }}</td>
          <!-- <td>{{ application._id }}</td> -->
          <td>{{ application.accountStatus }}</td>
          <td>
            <v-btn @click="viewApplicant(application._id)" small depressed
              ><v-icon>mdi-eye</v-icon></v-btn
            >
            <v-btn small depressed @click="deleteModal(application)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </td>
        </tr>
      </tbody>
    </table>

    <v-dialog persistent max-width="580px" v-model="deletemodal">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between">
          <h2 class="brandcolor">Delete {{ applicantData.firstName }}</h2>
          <v-btn fab small depressed @click="deleteModal"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <p class="my-5">
          Are you sure you want to delete {{ applicantData.firstName }}'s
          account? Their reviews and feedback would be lost
        </p>

        <div class="text-right">
          <v-btn
            :loading="btnloading"
            @click="deleteApplicant"
            color="#310059"
            class="white--text"
            >Yes, Delete</v-btn
          >

          <v-btn @click="deleteModal" class="ml-3" outlined color="#310059"
            >No, don't delete</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    
<script>
import axios from "axios";

export default {
  props: {
    applications: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },

  components: {},

  data: () => ({
    search: "",
    applicantData: "",
    deletemodal:false,
    btnloading:false
  }),

  methods: {
    viewApplicant(id) {
      this.$router.push("applicant/" + id);
    },
    deleteModal(applicant) {
      this.applicantData = applicant;
      this.deletemodal = !this.deletemodal;
    },

    deleteApplicant() {
      this.btnloading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/delete-applicant",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adminToken"),
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          id: this.applicantData._id,
        },
      })
        .then(() => {
          this.btnloading = false;
          this.$swal({
            icon: "success",
            title: "Applicant Deleted",
            text:
              "You have successfully deleted " +
              this.applicantData.firstName +
              " from the list of applicants",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          }).then(() => {
            this.$store.dispatch("admin/fetchAdminApplicants");
            this.deletemodal = !this.deletemodal;
          });
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Applicant Not Deleted",
            text:
              "You could not delete " +
              this.applicantData.name +
              " from the list of applicants",
            confirmButtonText: `<p class='brandcolor'>Try again</p>`,
          });
        });
    },
  },
};
</script>
    
    
    <style scoped>
.tableHeading {
  background: #310059 !important;
  border-radius: 20px;
  color: white !important;
}

table th:first-child {
  border-radius: 10px 0 0 0px;
}

table th:last-child {
  border-radius: 0 10px 0px 0;
}

.tableHeading tr {
  border-radius: 20px;
}

td {
  border-bottom: 1px solid #f9fafb;
  padding: 10px !important;
  text-align: center;
  border: 1px solid #310059;
}
th {
  width: 150px;
  padding: 10px;
  border-right: 1px solid white;
}

.applicationTable {
  margin: 0px 60px 0px 0px;
}

.searchField {
  width: 300px;
}
</style>
  