<template>
    <v-main style="margin:50px 0px 0px 200px">
       <div>    
        <div class="mb-4 d-flex justify-space-between mr-12">
        <h1  style="color:#310059">Application</h1>
        <div>
        <v-btn @click="showReview()" large color="#310059" class="white--text">Submit Review</v-btn>
        <v-btn @click="downloadPDF()" outlined fab color="#310059" class="ml-3 "><v-icon>mdi-cloud-download</v-icon></v-btn>
      </div>
      </div>   
      <SingleApplicationContent :application="admin.application"/>
      <VueHtml2pdf
            :manual-pagination="true"
            :enable-download="true"
            ref="DownloadComp"
          >
        <section slot="pdf-content">
        <div class="pa-5">
        <SingleApplicationContent :application="admin.application"/>
      </div>
      </section>
          </VueHtml2pdf>
        <div class="submitReview">
          <v-btn @click="showReview()" large block color="#310059" class="white--text">Submit Review</v-btn>
        </div>
      </div>

      <v-dialog persistent max-width="580px" v-model="review">
        <ReviewApplication @showReviewer="showReview"/>
      </v-dialog>
    </v-main>
</template>



<script>
import Vue from 'vue'
import SingleApplicationContent from './SingleApplicationContent.vue';
import ReviewApplication from './ReviewApplication.vue';
import { mapState } from 'vuex';
import VueHtml2pdf from 'vue-html2pdf'
export default Vue.extend({
    data: () => ({
      review:false
    }),
    components: { SingleApplicationContent,ReviewApplication,VueHtml2pdf },
    computed: {
      ...mapState(['admin']),
    },
    methods:{
      showReview(){
        this.review = !this.review
      },
      downloadPDF () {
        this.$refs.DownloadComp.generatePdf()
    }
    }
})

</script>


<style scoped>
.submitReview{
  background:white;
  padding:10px;
  position:fixed;
  bottom:0;
  width:500px;
  left:40%
}
</style>