<template>
  <v-app class="backgroundColor">
    <AppBar />
    <v-container>
      <v-card color="white" flat class="pa-6 my-7">
        <h2 class="brandcolor">Check your Eligibility</h2>
        <p>
          Hi {{ userData.firstName }}, <strong>sincerely</strong> fill the form
          below to check if you are eligible to apply for the BMGA fellows
          program
        </p>
        <v-form>
          <ol>
            <li id="female">
              <p class="label">Are you a female?</p>
              <v-radio-group
                @change="goToCountry"
                class="noMargin"
                v-model="isFemale"
              >
                <v-radio color="#4c248a" label="Yes" value="yes"></v-radio>
                <v-radio color="#4c248a" label="No" value="no"></v-radio>
              </v-radio-group>
            </li>

            <div v-show="isFemale != 'no'">

              <li id="country">
                <p class="label">Are you from a country in Africa or Asia?</p>
                <v-radio-group
                  @change="goToEducation()"
                  class="noMargin"
                  v-model="isCountry"
                >
                  <v-radio color="#4c248a" label="Yes" value="yes"></v-radio>
                  <v-radio color="#4c248a" label="No" value="no"></v-radio>
                </v-radio-group>
              </li>
  
              <li id="education">
                <p class="label">
                  Are you currently an undergraduate student of a tertiary
                  institution?
                </p>
                <v-radio-group
                  @change="goToEdu"
                  class="noMargin"
                  v-model="isEducation"
                >
                  <v-radio color="#4c248a" label="Yes" value="yes"></v-radio>
                  <v-radio color="#4c248a" label="No" value="no"></v-radio>
                </v-radio-group>
              </li>
  
              <li v-if="isEducation == 'yes'" id="educationDetails">
                <p class="label">
                  Are you in your final year as an undergraduate of a tertiary
                  education [This refers to individuals who would be in their
                  final year by 17 March 2024] ?
                </p>
                <v-radio-group
                  @change="goToLanguage"
                  class="noMargin"
                  v-model="isUndergrad"
                >
                  <v-radio color="#4c248a" label="Yes" value="yes"></v-radio>
                  <v-radio color="#4c248a" label="No" value="no"></v-radio>
                </v-radio-group>
              </li>
  
              <li v-if="isEducation == 'no'" id="educationDetails">
                <p class="label">
                  Do you have less than three years of work experience, derived after graduating from your undergraduate study?
                </p>
                <v-radio-group
                  @change="goToLanguage"
                  class="noMargin"
                  v-model="isProfessional"
                >
                  <v-radio color="#4c248a" label="Yes" value="yes"></v-radio>
                  <v-radio color="#4c248a" label="No" value="no"></v-radio>
                </v-radio-group>
              </li>
  
              <li id="language">
                <p class="label">
                  Can you speak, read and write English with proficiency?
                </p>
                <v-radio-group
                  @change="goToInternet"
                  class="noMargin"
                  v-model="isLanguage"
                >
                  <v-radio color="#4c248a" label="Yes" value="yes"></v-radio>
                  <v-radio color="#4c248a" label="No" value="no"></v-radio>
                </v-radio-group>
              </li>
  
              <li id="internet">
                <p class="label">Do you have regular access to the internet?</p>
                <v-radio-group class="noMargin" v-model="isInternet">
                  <v-radio color="#4c248a" label="Yes" value="yes"></v-radio>
                  <v-radio color="#4c248a" label="No" value="no"></v-radio>
                </v-radio-group>
              </li>
            </div>
          </ol>

          <v-btn
            :loading="loading"
            @click="checkEligibility()"
            color="#4c248a"
            class="white--text"
            x-large
            >Submit</v-btn
          >
        </v-form>
      </v-card>
    </v-container>

    <v-dialog v-model="eligible" max-width="580px">
      <v-card max-width="580px" class="pa-5 text-center">
        <v-img
          width="200px"
          src="../../assets/success.gif"
          class="mx-auto"
        ></v-img>
        <h2>Congratulations {{ userData.firstName }}!</h2>
        <p>You are eligible for the BMGA Fellows Program.</p>
        <p>Let’s get started!</p>
        <v-btn to="/applications" color="#4c248a" class="white--text mb-4" large
          >Okay</v-btn
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="noteligible" max-width="580px">
      <v-card max-width="580px" class="pa-5 text-center">
        <v-img
          width="200px"
          src="../../assets/decline.gif"
          class="mx-auto"
        ></v-img>
        <h2 class="my-3">Not Eligible</h2>
        <p>
          You are not eligible to apply for the BMGA Fellows Program.<br />
          Please review the eligibility criteria via our website
          <a href="https://bmgafoundation.org">here.</a>
        </p>

        <v-btn to="/dashboard" color="#4c248a" class="white--text mb-4" large
          >Okay</v-btn
        >
      </v-card>
    </v-dialog>
  </v-app>
</template>


<script>
import AppBar from "@/components/Applicant/AppBar.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      isFemale: "",
      isCountry: "",
      isEducation: "",
      isUndergrad: "",
      isProfessional: "",
      isLanguage: "",
      isInternet: "",
      loading: false,
      eligible: false,
      noteligible: false,
    };
  },
  metaInfo() {
    return {
      title: `Eligibility Check`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content: "Apply to the BMGA fellows program",
        },
        {
          property: "og:title",
          content: "Eligibility Check | BMGA Foundation",
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Eligibility Check | BMGA Foundation",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/signup",
        },
      ],
    };
  },

  components: {
    AppBar,
  },

  created() {
    window.scrollTo(0, 0);
    if(sessionStorage.getItem("bmgaToken") == null){
          this.$router.push("/login")
        }
  },
  computed: {
    ...mapState({
      userData: "userData",
    }),
  },
  methods: {
    goToCountry() {
      document.getElementById("country").scrollIntoView();
    },
    goToEducation() {
      document.getElementById("education").scrollIntoView();
    },
    goToEdu() {
      document.getElementById("educationDetails").scrollIntoView();
    },
    goToLanguage() {
      document.getElementById("language").scrollIntoView();
    },
    goToInternet() {
      document.getElementById("internet").scrollIntoView();
    },

    checkEligibility() {
      this.loading = true;
      const criteria = [
        this.isFemale,
        this.isCountry,
        this.isUndergrad,
        this.isProfessional,
        this.isLanguage,
        this.isInternet,
      ];
      if (criteria.includes("no")) {
        //set user as not eligible
        axios({
          method: "POST",
          url: process.env.VUE_APP_API_URL + "/set-eligibility",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
            ContentType: "application/json",
            Accept: "*/*",
          },
          data: {
            eligible: false,
          },
        }).then((response) => {
          console.log(response);
          this.loading = false;
          this.noteligible = true;
        });
      } else {
        //set user as eligible
        axios({
          method: "POST",
          url: process.env.VUE_APP_API_URL + "/set-eligibility",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
            ContentType: "application/json",
            Accept: "*/*",
          },
          data: {
            eligible: true,
          },
        }).then((response) => {
          console.log(response);
          this.loading = false;
          this.eligible = true;
        });
      }
    },
  },
};
</script>


<style scoped>
.noMargin {
  margin-top: -10px;
  padding: 0px;
}

.label {
  font-size: 18px;
}
</style>