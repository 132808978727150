<template>
    <v-app>
        <ApplicationsComponent :applications="applications"/>
    </v-app>
</template>

<script>
import { mapState } from 'vuex'
import ApplicationsComponent from "../../components/Applicant/ApplicationsComponent.vue"
export default {
    components:{
        ApplicationsComponent
    },
    computed:{
        ...mapState({
            applications:"applications"
        })
    },
    created(){
        console.log(this.userData)
        this.$store.dispatch("fetchApplications")
    },
    metaInfo() {
    return {
      title: `Applications`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content:
            "Apply to the BMGA fellows program"
        },
        {
          property: "og:title",
          content: "Applications | BMGA Foundation"
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Applications | BMGA Foundation"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/signup" 
        },
      ]
    };
  },
}
</script>



<style scoped>

</style>