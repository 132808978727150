<template>
  <v-card class="pa-4" flat>
    <h3 class="brandcolor my-3">Personal Information</h3>
    <v-form>
      <v-row>
        <v-col>
          <label>First Name</label>
          <v-text-field
            color="#4c248a"
            dense
            outlined
            v-model="application.firstName"
            readonly
          />
        </v-col>

        <v-col>
          <label>Last Name</label>
          <v-text-field
            color="#4c248a"
            dense
            outlined
            v-model="application.lastName"
            readonly
          />
        </v-col>
      </v-row>
      <label>Email address</label>
      <v-text-field
        color="#4c248a"
        dense
        outlined
        v-model="application.email"
        readonly
      />

      <label>Phone Number</label>
      <v-text-field
        color="#4c248a"
        dense
        outlined
        v-model="application.phone"
        readonly
      />

      <label>Date of birth</label>
      <v-text-field
        color="#4c248a"
        dense
        outlined
        v-model="application.dob"
        readonly
      />

      <label>Gender</label>
      <v-text-field
        color="#4c248a"
        dense
        outlined
        v-model="application.gender"
        readonly
      />

      <label>Address</label>
      <v-text-field
        color="#4c248a"
        dense
        outlined
        v-model="application.address"
        readonly
      />

      <label>Nationality</label>
      <v-text-field
        color="#4c248a"
        dense
        outlined
        v-model="application.nationality"
        readonly
      />

      <label>Country of Residence</label>
      <v-text-field
        color="#4c248a"
        dense
        outlined
        v-model="application.country"
        readonly
      />

      <label>City</label>
      <v-text-field
        color="#4c248a"
        dense
        outlined
        v-model="application.city"
        readonly
      />
    </v-form>

    <h3 class="brandcolor mt-3">Academic Experience</h3>

    <div class="tableScroll">
      <table>
        <tr>
          <th class="type">University</th>
          <th class="status">Course</th>
          <th class="started">Qualification</th>
          <th class="started">Degree</th>
          <th class="submitted">CGPA</th>
        </tr>
        <tr v-if="application.academicExperience.length == 0">
          <td>You've not added an academic experience yet</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr
          v-for="(institution, i) in application.academicExperience"
          v-else
          :key="i"
        >
          <td>{{ institution.university }}</td>
          <td>{{ institution.courseOfStudy }}</td>
          <td>{{ institution.degree }}</td>
          <td>{{ institution.degreeClass }}</td>
          <td>{{ institution.cgpa }}/{{ institution.gpaScale }}</td>
        </tr>
      </table>
    </div>

    <h3 class="brandcolor mt-3">Additional Information</h3>
    <additionalinformation-component
      :loading="loading"
      @updateApplication="submitApplication"
      :proficiency="proficiency"
      :experiences="experiences"
      :employmentType="employmentType"
      :industryType="industryType"
      :internetAccess="internetAccess"
    />

    <h3 class="brandcolor mt-3">Supporting Documents</h3>

    <v-card class="pa-4" flat>
      <div class="descriptionBox">
        <p class="instructions">
          According to the
          <a
            target="_blank"
            href="https://www.weforum.org/agenda/2023/05/future-of-jobs-2023-skills/"
            >World Economic Forum</a
          >, 10 (ten) skills are required to thrive in the future of work.
          Please provide candid feedback on the three skills you actively seek
          to possess and why? (max. 200 words).
        </p>
        <textarea
          readonly
          color="#4c248a"
          outlined
          v-model="application.wefEssay"
        ></textarea>
      </div>

      <div class="descriptionBox">
        <p class="instructions">
          Do you believe inequality undermines the career trajectory of women in
          Africa or Asia? If yes, how is this so and what do you believe can be
          done to address this issue? If not, what is the justification for your
          answer? (max. 150 words).
        </p>
        <textarea
          style="max-height: 500px"
          cols="120"
          color="#4c248a"
          outlined
          v-model="application.inequalityEssay"
          readonly
        ></textarea>
      </div>

      <div class="descriptionBox">
        <p class="instructions">
          Why do you want to be a BMGA Fellow, what do you hope to gain from
          your time in the program, and what will you bring to the BMGA Fellows
          Program? (max. 150 words).
        </p>
        <textarea
          style="max-height: 500px"
          cols="120"
          color="#4c248a"
          outlined
          v-model="application.whyEssay"
          readonly
        ></textarea>
      </div>

      <div class="descriptionBox">
        <p class="instructions">
          Is there anything about your family background or your community that
          has significantly impacted who you are? (max 100 words).
        </p>
        <textarea
          style="max-height: 500px"
          cols="120"
          color="#4c248a"
          outlined
          v-model="application.familyEssay"
          readonly
        ></textarea>
      </div>

      <div class="descriptionBox">
        <p class="instructions">
          Who do you hope to be in five years? (max. 100 words).
        </p>
        <textarea
          style="max-height: 500px"
          cols="120"
          color="#4c248a"
          outlined
          v-model="application.fiveYearsEssay"
          readonly
        ></textarea>
      </div>

      <div>
        <h4 class="brandcolor">Video Question</h4>
        <p>
          Instruction: Create a 3-minute (maximum) video of yourself that
          responds to the following prompts and upload it on YouTube as an
          unlisted video. After uploading it on YouTube, insert the link in the
          space provided below. (Ensure that there are no distractions or noise
          in your environment. Also ensure that your YouTube video link provided
          is correct).
        </p>
      </div>

      <div class="my-2">
        <p>Video Link</p>
        <a target="_blank" :href="application.videoLink">{{
          application.videoLink
        }}</a>
      </div>

      <div class="mt-4">
        <h4 class="brandcolor">Resume*</h4>
        <div>A recent copy of your resume in PDF.</div>
        <input
          v-if="application.resume == ''"
          class="my-1"
          type="file"
          accept=".pdf"
        />
        <a
          class="my-2"
          v-if="application.resume != ''"
          :href="application.resume"
          target="_blank"
          ><u>{{ application.firstName }}'s resume. View uploaded file</u></a
        >
      </div>

      <div class="mt-4">
        <h4 class="brandcolor mb-2">Reference Letter*</h4>
        <div class="instructions">
          Recommendation letter in PDF (Please check the guideline for your
          recommendation letter below).
        </div>
        <input
          v-if="application.referenceLetter == ''"
          class="my-1"
          type="file"
          accept=".pdf"
        />
        <a
          class="my-2"
          v-if="application.referenceLetter != ''"
          :href="application.referenceLetter"
          target="_blank"
          ><u>{{ application.firstName }}'s reference. View uploaded file</u></a
        >
      </div>
    </v-card>

    <h3 class="brandcolor mt-3">Marketing Information</h3>

    <v-form>
      <p>How did you find out about the BMGA Fellows Program?</p>

      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.marketingInfo"
        value="bmga-website"
        label="BMGA website"
        >BMGA website</v-checkbox
      >
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.marketingInfo"
        value="bmga-fellow"
        label="BMGA fellow"
        >BMGA Fellow</v-checkbox
      >
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.marketingInfo"
        value="search-engine"
        label="Search Engine"
        >Search Engine</v-checkbox
      >
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.marketingInfo"
        value="facebook"
        label="Facebook"
      >
        Facebook</v-checkbox
      >
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.marketingInfo"
        value="linkedin"
        label="LinkedIn"
        >LinkedIn</v-checkbox
      >
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.marketingInfo"
        value="instagram"
        label="Instagram"
        >Instagram</v-checkbox
      >
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.marketingInfo"
        value="Twitter"
        label="Twitter"
        >Twitter</v-checkbox
      >
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.marketingInfo"
        value="university"
        label="University Career Center"
        >University Career Centre</v-checkbox
      >
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.marketingInfo"
        value="other"
        label="Other"
        >Other</v-checkbox
      >
    </v-form>

    <h3 class="brandcolor mt-3">Confidential Information</h3>

    <v-form>
      <p>
        Do you have a disability you wish to declare? (This information WILL NOT
        be used to inform selection decisions. It is helpful for us to be aware
        at this stage of any likely requirements you may have during the
        program.)
      </p>

      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.confidentialInfo"
        value="no-disability"
        label="No disability"
      ></v-checkbox>
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.confidentialInfo"
        value="austic-disorder"
        label="Austic Disorder"
      ></v-checkbox>
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.confidentialInfo"
        value="blind/partial-sight"
        label="Blind/Partial sight"
      ></v-checkbox>
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.confidentialInfo"
        value="deaf/partial-hearing"
        label="Deaf/Partial Hearing"
      ></v-checkbox>
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.confidentialInfo"
        value="mental-disorder"
        label="Mental Disorder"
      ></v-checkbox>
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.confidentialInfo"
        value="learning-difficulty"
        label="Learning Difficulty"
      ></v-checkbox>
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.confidentialInfo"
        value="wheelchair/mobility/other"
        label="Wheelchair/mobility"
      ></v-checkbox>
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.confidentialInfo"
        value="do-not-wish-to-disclose"
        label="Do not wish to disclose"
      ></v-checkbox>
      <v-checkbox
        readonly
        class="my-0 py-0"
        color="#4c248a"
        v-model="application.confidentialInfo"
        value="other"
        label="Other (Do not wish to disclose)"
      ></v-checkbox>
    </v-form>

    <h3 class="brandcolor mt-3">Signature</h3>

    <v-form>
      <label>Signature (Enter full name)*</label>
      <v-text-field
        readonly
        v-model="application.signature"
        color="#4c248a"
        outlined
        dense
      />
    </v-form>
  </v-card>
</template>
          
          
 <script>
import { mapState } from "vuex";
import axios from "axios";
import AdditionalinformationComponent from "../../AdditionalInformation/AdditionalinformationComponent.vue";
import moment from "moment";

export default {
  data() {
    return {
      loading: false,
      proficiency: ["Basic", "Fluent", "Proficient"],
      isProfessional: "",
      experiences: [
        "Less than 1 year",
        "1-3 years",
        "3-5 years",
        "5-10 years",
        "More than 10 years",
      ],
      yearsOfExperience: "",
      employment: "",
      employmentType: ["Not Employed", "Internship", "Part-Time", "Full-Time"],
      industry: "",
      industryType: [
        "Asset/Investment Management",
        "Banking",
        "Consulting",
        "Consumer Durables",
        "Education",
        "Engineering/Construction",
        "Government/Public Sector",
        "Health Services/Hospitals",
        "Insurance",
        "International Development",
        "Legal Services",
        "Logistics",
        "Manufacturing",
        "Marketing/PR/Advertising",
        "Media/Entertainment",
        "Non-Profit",
        "Pharmaceuticals",
        "Research/Academia",
        "Retail",
        "Technology",
        "Telecommunications",
        "Other",
      ],
      company: "",
      internet: "",
      isTuition: "",
      internetAccess: [
        "On a regular basis",
        "Most of the time",
        "I will not have a reliable connection to the internet",
      ],
      fundingDescription: "",
    };
  },
  components: { AdditionalinformationComponent },
  computed: {
    ...mapState({
      application: "application",
    }),
  },

  created() {
    window.scrollTo(0, 0);
  },

  methods: {
    submitApplication() {
      //check if there is any required field that has not been filled

      // if(!this.checkApplication(this.application)){
      //   return
      // }

      const payload = {
        applicationId: this.application._id,
        status: "Submitted",
        submitted: moment(Date.now()).format("LLL"),
      };

      this.loading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/update-application",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: payload,
      }).then((response) => {
        console.log(response);
        this.loading = false;
        this.$swal({
          icon: "success",
          title: "Application submitted successfully",
          text: "You have successfully submitted your application to The BMGA Fellows Program.",
          confirmButtonText: `<p class="brandcolor">Ok</p>`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push("/applications");
          }
        });
      });
    },

    // checkApplication(obj) {
    //   obj.submitted = moment(Date.now()).format('LLL')
    //   const valueArray = Object.values(obj)
    //   console.log(valueArray)
    //   for (var key in valueArray) {
    //     if (valueArray[key] == ""){
    //     this.$swal({
    //       icon:"error",
    //       title:"Application Not Complete!",
    //       text:"You need to fill out all required fields before you can submit your application",
    //       confirmButtonText:`<p class="brandcolor">Ok</p>`
    //     })
    //     return false
    //   }
    //   }
    // },
  },
};
</script>
          
          
          
          
<style scoped>
.container {
  max-width: 700px;
  margin: auto;
  padding: 0px 50px;
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.v-text-field {
  height: 54px !important;
}

.instructions {
  font-size: 15px;
  margin: 0px 0px 10px 0px;
}

.checkBoxes {
  margin-top: -27px;
}

.descriptionBox {
  margin: 0px 0px 30px 0px;
}

.v-textarea,
textarea {
  resize: none !important;
}
textarea {
  width: 100%;
  outline: 1px solid #4c248a;
  border-radius: 5px;
  height: 250px;
  padding: 10px;
}

.information {
  color: red;
  font-style: italic;
}

@media only screen and (max-width: 600px) {
  .topTitle {
    padding: 0px 0px 0px 15px;
  }
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 776px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 894px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 996px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.v-text-field {
  height: 54px !important;
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.v-text-field {
  height: 54px !important;
}

.instructions {
  margin-top: -10px;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 15px;
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* tr:nth-child(1) {
 
} */

@media only screen and (max-width: 700px) {
  .tableScroll {
    overflow-x: scroll;
  }
}
</style>