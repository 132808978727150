<template>
  <div>
    <v-card class="px-4" flat>
      <v-form>
        <h4 class="brandcolor pt-6">Language Proficiency*</h4>
        <div class="my-4">
          The BMGA Fellows Program is fully conducted in the English Language.
          Please indicate your proficiency in English – Proficient, Fluent, or
          Basic, for the four categories below:
        </div>

        <v-row>
          <v-col>
            <p style="padding:5px 0px">Reading</p>
            <v-select
              color="#4c248a"
              dense
              outlined
              :items="proficiency"
              v-model="application.reading"
              :readonly="disabled"
            />
          </v-col>
          <v-col>
            <p style="padding:5px 0px">Writing</p>
            <v-select
              color="#4c248a"
              dense
              outlined
              :items="proficiency"
              v-model="application.writing"
              :readonly="disabled"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p style="padding:5px 0px">Speaking</p>
            <v-select
              dense
              outlined
              :items="proficiency"
              v-model="application.speaking"
              color="#4c248a"
              :readonly="disabled"
            />
          </v-col>
          <v-col>
            <p style="padding:5px 0px">Listening</p>
            <v-select
              dense
              outlined
              :items="proficiency"
              v-model="application.listening"
              color="#4c248a"
              :readonly="disabled"
            />
          </v-col>
        </v-row>
      </v-form>

      <h4 class="brandcolor mt-5">Professional Experience*</h4>
      <p>
        Do you have any work experience derived after your undergraduate education?
        [Please consider all relevant work or professional experiences including
        internships, part-time work, and full-time work]
      </p>
      <div style="padding:5px 0px" class="checkBoxes d-flex">
        <v-checkbox
          color="#4c248a"
          v-model="application.isProfessional"
          value="yes"
          label="Yes"
          :readonly="disabled"
        ></v-checkbox>
        <v-checkbox
          color="#4c248a"
          v-model="application.isProfessional"
          value="no"
          label="No"
          class="ml-4"
          :readonly="disabled"
        ></v-checkbox>
      </div>

      <div v-if="application.isProfessional == 'yes'">
        <p style="padding:5px 0px">How many years of experience have you had?*</p>
        <v-select
          color="#4c248a"
          dense
          outlined
          :items="experiences"
          v-model="application.yearsOfExperience"
          :readonly="disabled"
        />

        <p style="padding:5px 0px">Are you currently employed or working?*</p>
        <v-select
          color="#4c248a"
          dense
          outlined
          :items="employmentType"
          v-model="application.employed"
          :readonly="disabled"
        />

        <div v-if="application.employed !=='Not Employed'">
        <p style="padding:5px 0px">In which sector?*</p>
        <v-select
          color="#4c248a"
          dense
          outlined
          :items="industryType"
          v-model="application.sector"
          :readonly="disabled"
        />
        <!-- <div v-if="application.sector == 'Other'">
        <label>Enter "Other" sector</label>
        <v-text-field
          color="#4c248a"
          v-if="application.sector == 'Other'"
          v-model="application.other"
          dense
          outlined
        />
      </div> -->

        <p style="padding:5px 0px">What is the name of your company and your role?*</p>
        <v-text-field
          placeholder="e.g Software engineer, Microsoft"
          color="#4c248a"
          v-model="application.company"
          dense
          outlined
          :readonly="disabled"
        />
      </div>
    </div>

      <div>
        <h4 style="padding:5px 0px" class="brandcolor mt-5">Commitment*</h4>
        <p style="padding:5px 0px">
          Constant access to a working internet is important to successfully
          participate in the BMGA Fellows Program. Please indicate your internet
          accessibility along with your ability and commitment to checking your
          email over the duration of the Program. Indicate the category that
          applies:
        </p>

        <p style="padding:5px 0px">Access to Internet:</p>
        <v-select
          :items="internetAccess"
          v-model="application.accessToInternet"
          outlined
          dense
          color="#4c248a"
          :readonly="disabled"
        />
      </div>

      <div>
        <!-- <h4 style="padding:5px 0px" class="brandcolor mt-5">Fee Requirement*</h4>
        <p>
          This is a paid program that is heavily subsidized by BMGA Foundation
          and her partners. The program fee has been subsidized to ensure that
          participants get a high-quality educational experience. After
          admission into the program, participants are required to pay a fee of
          $250.
          <br /><br />
          Please note that the program consists of peer-to-peer learning
          where participants will be paired with graduate students from foreign
          universities for mentorship or structured coaching from executives
          with international experience.
        </p> -->

        <!-- <label
          >Will you be able to fund your participation in this program?</label
        >
        <div class="pt-3 checkBoxes d-flex">
          <v-checkbox
            color="#4c248a"
            v-model="application.isTuition"
            value="yes"
            label="Yes"
          ></v-checkbox>
          <v-checkbox
            color="#4c248a"
            v-model="application.isTuition"
            value="no"
            label="No"
            class="ml-4"
          ></v-checkbox>
        </div> -->
      </div>

      <!-- <div style="padding:5px 0px" class="descriptionBox">
        <p style="padding:5px 0px"
          >Please describe how you expect your tuition fee for the BMGA Fellows Program to be funded. </p
        >
        <v-textarea
          color="#4c248a"
          outlined
          v-model="application.describeTuition"
        />
      </div> -->
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    proficiency: {
      type: Array,
    },
    experiences: {
      type: Array,
    },
    employmentType: {
      type: Array,
    },
    industryType: {
      type: Array,
    },
    internetAccess: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      userData: "userData",
      application: "application",
    }),
  },
  methods: {
    updateApplication() {
      this.$emit("updateApplication");
    },
  },
};
</script>


<style scoped>
.descriptionBox {
  height: 300px;
}
</style>