<template>
    <v-app>
        <ConfidentialInformationComponent />
    </v-app>
</template>

<script>
import ConfidentialInformationComponent from "../../components/Applicant/ConfidentialInformation.vue"

export default {
    components:{
        ConfidentialInformationComponent
    },
    metaInfo() {
    return {
      title: `Confidential Information`,
      titleTemplate: "%s | BMGA Foundation",
      meta: [
        {
          name: "description",
          content:
            "Apply to the BMGA fellows program"
        },
        {
          property: "og:title",
          content: "Confidential Information | BMGA Foundation"
        },
        { property: "og:site_name", content: "BMGA Foundation" },
        {
          property: "og:description",
          content: "Confidential Information | BMGA Foundation"
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://apply.bmgafoundation.org/signup" 
        },
      ]
    };
  },
}
</script>


<style scoped>

</style>