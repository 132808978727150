<template>
  <div style="padding:40px">
    <div>
      <div style="display:flex;justify-content: space-between;">
        <p>
          BMGA Foundation <br />
          Lekki Phase 1 <br />
          Lagos, Nigeria <br />
          <a href="https://bmgafoundation.org">https://bmgafoundation.org</a
          >
        </p>

        <img
          width="150px"
          style="object-fit: cover"
          src="../../assets/bmga.jpg"
        />
      </div>
      <hr />
      <div style="padding:8px 0px" class="my-5">
        <p>{{ today }}</p>

        <p><strong>{{ userData && userData.firstName }} {{ userData && userData.lastName }}</strong></p>

        <p>{{ application.address }}, {{ application.city }}, {{ application.country }}</p>

        <p style="padding:12px 0px">Dear Ms. {{application.lastName}},</p>

        <p style="padding:8px 0px">
          It is with great pleasure that we extend an invitation to you to be a
          participant within the 2023 BMGA Fellows Program. On behalf of BMGA
          Foundation, I congratulate you on this accomplishment and welcome you
          to the fourth cohort of the BMGA Fellows Program.
        </p>

        <p style="padding:8px 0px">
          Your thoughtful application, outstanding academic accomplishments, and
          personality convinced us that you have the intellectual aptitude,
          imagination, and potential to succeed at the BMGA Fellows Program. You
          are joining an incredibly talented and diverse group of young women
          from African and Asian countries seeking to build and develop their
          careers to make an impact on the social, economic, and political
          development of their countries. We are thrilled to have you onboard
          and look forward to the unique and extraordinary contributions we know
          you will make to the program. Therefore, we believe you will seize
          this opportunity and make the best of it.
        </p>

        <p style="padding:8px 0px">
          The BMGA Fellows Program offers you a rare and unique opportunity to
          have a transformational learning experience, which will play an
          important role in furthering your personal and professional
          development. You will get the opportunity to learn from business
          leaders and subject experts from different industries. Upon the
          conclusion of the program, you would have interacted with faculty
          members, global leaders, business executives, and students from some
          of the top institutions in the world and will be equipped with
          transformational tools that will enable you to be competitive on the
          global stage.
        </p>

        <p style="padding:8px 0px">
          We invite you to participate in a virtual welcome meet-up to mark the
          commencement of your journey with us.
          <strong
            >This virtual meet up, scheduled for Thursday, 04 May 2023</strong
          >
          will introduce you to the dynamic and diverse nature of the program.
          Please note that details regarding this meet up will be provided after
          you have confirmed your spot in the 2023 BMGA Fellows Program via our
          enrollment process
        </p>

        <p style="padding:8px 0px">
          Once again, I extend my congratulations on your admission to the 2023
          BMGA Fellows Program!
        </p>

        <p style="padding:8px 0px">Sincerely</p>
        <img src="../../assets/signature.png" />

        <p style="padding:8px 0px">
          Gbemisola Abudu <br />
          <strong>Founder</strong>
        </p>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import moment from "moment";
import { mapState } from "vuex"

export default {

  name: "AcceptanceLetter",
  computed: {
    today() {
      return moment(Date.now()).format("LL");
    },
    ...mapState({
      userData: "userData",
      settings: "settings",
      application: "application",
    }),
  },
};
</script>