<template>
  <div class="applicationTable">
    <table cellspacing="0">
      <thead>
        <tr class="tableHeading">
          <th>Reviewer</th>
          <th>Name</th>
          <th>Email</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody v-for="(reviewer, i) in reviewers" :key="i">
        <tr>
          <td><v-checkbox color="#310059" v-model="selected" :value="reviewer._id"  @change="showSelected"/></td>
          <td>{{ reviewer.name }}</td>
          <td>{{ reviewer.email }}</td>
          <td>
            <div class="d-flex">
              <v-btn
                color="#310059"
                @click="viewReviewer(reviewer)"
                class="white--text cursor-pointer"
                >View</v-btn
              >
              <v-btn
                color="#310059"
                @click="deleteModal(reviewer)"
                outlined
                class="ml-2 cursor-pointer"
                >Delete</v-btn
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <v-dialog persistent max-width="580px" :value="reviewer">
      <AddReviewer @showReviewer="showReviewer" />
    </v-dialog>

    <v-dialog persistent max-width="580px" v-model="deletemodal">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between">
          <h2 class="brandcolor">Delete {{ reviewerData.name }}</h2>
          <v-btn fab small depressed @click="deleteModal"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <p class="my-5">
          Are you sure you want to delete {{ reviewerData.name }}'s reviewer's
          account? Their reviews and feedback would be lost
        </p>

        <div class="text-right">
          <v-btn
            :loading="btnloading"
            @click="deleteReviewer"
            color="#310059"
            class="white--text"
            >Yes, Delete</v-btn
          >

          <v-btn @click="deleteModal" class="ml-3" outlined color="#310059"
            >No, don't delete</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    
<script>
import AddReviewer from "./AddReviewer.vue";
import axios from "axios";
export default {
  data: () => ({
    search: "",
    deletemodal: false,
    reviewerData: "",
    btnloading: false,
    selected:[]
  }),
  props: {
    reviewers: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    reviewer: {
      type: Boolean,
    },
  },
  components: { AddReviewer },
  methods: {
    showReviewer() {
      this.$emit("showReviewer");
    },
    showSelected(){
      this.$emit("showSelected",this.selected)
    },
    viewReviewer(reviewer) {
      this.$router.push("/reviewer/" + reviewer.email);
    },
    deleteModal(reviewer) {
      this.reviewerData = reviewer;
      this.deletemodal = !this.deletemodal;
    },
    deleteReviewer() {
      this.btnloading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/delete-reviewer",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adminToken"),
          Accept: "*/*",
          ContentType: "application/json",
        },
        data: {
          id: this.reviewerData._id,
        },
      })
        .then((response) => {
          this.btnloading = false;
          console.log(response);
          this.$store.dispatch("admin/fetchAdminUsers")
          this.$swal({
            icon: "success",
            title: "Reviewer Deleted",
            text:
              "You have successfully deleted " +
              this.reviewerData.name +
              " from the list of reviewers",
            confirmButtonText: `<p class='brandcolor'>Ok</p>`,
          }).then(() => {
            this.$store.dispatch("admin/fetchAdminUsers")
            this.deletemodal = !this.deletemodal;
          });
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Reviewer Not Deleted",
            text:
              "You could not delete " +
              this.reviewerData.name +
              " from the list of reviewers",
            confirmButtonText: `<p class='brandcolor'>Try again</p>`,
          });
        });
    },
  },
};
</script>
    
    
    <style scoped>
.tableHeading {
  background: #310059 !important;
  border-radius: 20px;
  color: white !important;
}

table th:first-child {
  border-radius: 10px 0 0 0px;
}

table th:last-child {
  border-radius: 0 10px 0px 0;
}

.tableHeading tr {
 
  border-radius: 20px;
}

td {
  border-bottom: 1px solid #f9fafb;
  padding: 10px !important;
  text-align: center;
  border: 1px solid #310059;
}
th {
  width: 150px;
  padding: 10px;
  border-right: 1px solid white;
}

.applicationTable {
  margin: 0px 60px 0px 0px;
}

.searchField {
  width: 300px;
}
</style>
  