<template>
  <div class="applicationTable">
    <table cellspacing="0">
      <thead>
        <tr v-if="!loading" class="tableHeading">
          <th><v-checkbox color="white"  v-model="selectAll" @change="select_All($event)"/></th>
          <th>Applicant</th>
          <th>Email</th>
          <th>Stage</th>
          <th>Score</th>
          <th>Date Modified</th>
          <th>Status</th>
          <th>Assigned</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody v-for="(application, i) in applications" :key="i">
        <tr>
          <td><v-checkbox id="checkbox" ref="n" color="#310059" v-model="selected[i]" :value="application._id"  @change="showSelected"/></td>
          <td>{{ application.firstName }} {{ application.lastName }}</td>
          <td>{{ application.email }}</td>
          <td>{{ application.stage ? application.stage : 0 }}</td>
          <td>
            {{
              application.assessment ? application.assessment.toFixed(0) : 0
            }}%
          </td>
          <td>
            {{
              application.modified ? application.modified : application.created
            }}
          </td>
          <td>{{ application.status }}</td>
          <td>{{ application.reviewerEmail == undefined? "No":"Yes"}}</td>
          <td>
            <v-menu transition="slide-x-transition" bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text dark v-bind="attrs" v-on="on">
                  <v-icon color="#310059">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item class="cursor-pointer">
                  <v-list-item-title
                    class="cursor-pointer"
                    @click="viewApplication(application._id)"
                    >View</v-list-item-title
                  >
                </v-list-item>


                <v-list-item class="cursor-pointer" v-if="application.status != 'in-progress'">
                  <v-list-item-title
                    class="cursor-pointer"
                    @click="showComment(application)"
                    >View Comment</v-list-item-title
                  >
                </v-list-item>

                <!-- <v-list-item class="cursor-pointer" v-if="application.status != 'in-progress' && application.status != 'Enrolled'">
                  <v-list-item-title
                    class="cursor-pointer"
                    @click="assignReviewer(application._id)"
                    >Assign Reviewer</v-list-item-title
                  >
                </v-list-item> -->

                <v-list-item v-if="application.status != 'in-progress' && application.status != 'Enrolled'">
                  <v-list-item-title
                    class="cursor-pointer"
                    @click="moveToNextStage(application)"
                    >Move to Next Stage</v-list-item-title
                  >
                </v-list-item>

                <v-list-item v-if="application.stage != undefined">
                  <v-list-item-title
                    class="cursor-pointer"
                    @click="revertDecision(application)"
                    >Revert Decision</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>


      </tbody>
    </table>

    <v-dialog persistent max-width="580px" :value="assignreviewer">
      <AssignReviewer
        :reviewers="reviewers"
        @showReviewer="showReviewer"
        :selectedApp="selectedApp"
      />
    </v-dialog>


    <v-dialog v-model="comments" max-width="500px">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between">
          <h2 class="brandcolor">Comments</h2>
          <v-btn fab small depressed @click="comments = !comments"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>

        

        <div v-for="(comment,i) in application.comment" :key="i">
          <table>
            <thead>
              <tr>
              <th> 
                Review
              </th>
              <th>
                Score
              </th>
              <th>
                Comment
              </th>
            </tr>
            </thead>
          <tr>
            <td style="width:10%">
              {{ i+1 }}
            </td>
            <td style="width:15%">
             {{ comment.score.toFixed(2) }}%
            </td>
            <td>
               {{ comment.comment }}
            </td>
          </tr>
        </table>
         
        </div>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="580px" v-model="nextstagemodal">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between">
          <h2 class="brandcolor">Update Application</h2>
          <v-btn fab small depressed @click="moveApplicationModal"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <p class="my-5">
          Are you sure you want to move this application to the next stage?
        </p>

        <div class="text-right">
          <v-btn
            :loading="btnloading"
            @click="moveApplication"
            color="#310059"
            class="white--text"
            >Yes</v-btn
          >

          <v-btn @click="moveApplicationModal" class="ml-3" outlined color="#310059"
            >No</v-btn
          >
        </div>
      </v-card>
    </v-dialog>


  </div>
</template>


<script>
import AssignReviewer from "./AssignReviewer.vue";
import axios from 'axios'

export default {
  components: {
    AssignReviewer,
  },
  props: {
    applications: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    reviewers: {
      type: Array,
    },
    assignreviewer:{
      type:Boolean
    },
    selectedApp:{
      type:Array
    }
  },
  data: () => ({
    applicationId: "",
    // assignreviewer: false,
    application:"",
    nextstagemodal:false,
    btnloading:false,
    selected:[],
    comments:false,
    selectAll:false
  }),
  methods: {
    showSelected(){
      this.$emit("showSelected",this.selected)
    },
    viewApplication(id) {
      window.location.href="/admin/application/"+id
    },
    assignReviewer(id) {
      this.applicationId = id;
      this.$emit("assignReviewer",this.applicationId)
    },
    showReviewer() {
      this.$emit("assignReviewer")
    },
    moveToNextStage(e){
      this.application = e
      this.nextstagemodal = !this.nextstagemodal
    },
    moveApplicationModal(){
      this.nextstagemodal = !this.nextstagemodal
    },
    moveApplication(){
    this.btnloading = true
      axios({
        method:"POST",
        url:process.env.VUE_APP_API_URL + "/admin/movetonextstage",
        headers:{
          Authorization:"Bearer "+sessionStorage.getItem("adminToken")
        },
        data:{
          id:this.application._id,
          stage:(this.application.stage?this.application.stage+1:1)
        }
      }).then(()=>{
        this.btnloading = false
        this.$swal({
          icon:"success",
          title:"Application moved to the next stage",
          text:"This application has been moved to the next stage",
          confirmButtonText:`<p class='brandcolor'>Ok</p>`
        })
        location.reload()
      }).catch(()=>{
        this.btnloading = false
      })
    },
    revertDecision(application){
    this.btnloading = true
      axios({
        method:"POST",
        url:process.env.VUE_APP_API_URL + "/admin/movetonextstage",
        headers:{
          Authorization:"Bearer "+sessionStorage.getItem("adminToken")
        },
        data:{
          id:application._id,
          stage:(application.stage?application.stage-1:0)
        }
      }).then((response)=>{
        console.log(response)
        this.btnloading = false
        this.$swal({
          icon:"success",
          title:"Application moved to the previous stage",
          text:"This application has been moved to the previous stage",
          confirmButtonText:`<p class='brandcolor'>Ok</p>`
        })
        location.reload()
        // this.$store.dispatch("admin/fetchAdminUsers")
      }).catch(()=>{
        this.btnloading = false
      })
    },

    showComment(e){
      this.comments = !this.comments
      this.application = e
    },

    select_All(e){
        if(e == true)
        {
          this.$refs.n.forEach((val, index) => this.selected[index] = this.applications[index]._id,)
          this.$emit("showSelected",this.selected)
        } else {
            this.$refs.n.forEach((val, index) => this.selected[index] = false)
        }
    }
  },
};
</script>


<style scoped>
.tableHeading {
  background: #310059 !important;
  color: white !important;
}



.tableHeading tr {
  background: #f9fafb;
}

td {
  border-bottom: 1px solid #f9fafb;
  padding: 10px !important;
  text-align: center;
  border: 1px solid #310059;
}
th {
  width: 150px;
  padding: 10px;
  border-right: 1px solid white;
}

.applicationTable {
  margin: 0px 60px 0px 0px;
}

.v-list-item{
  cursor: pointer !important;
}
</style>