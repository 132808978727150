<template>
  <v-app class="backgroundColor">
    <AppBar />
    <AppSidebar :page="$route.path" :userData="userData" />
    <v-container class="my-5 container">
      <h1 class="brandcolor my-3 topTitle">Review & Submit</h1>
      <v-card class="pa-4" flat>
        <h3 class="brandcolor my-3">Personal Information</h3>
        <v-form>
          <v-row>
            <v-col>
              <label>First Name</label>
              <v-text-field
                color="#4c248a"
                dense
                outlined
                v-model="application.firstName"
                readonly
              />
            </v-col>

            <v-col>
              <label>Last Name</label>
              <v-text-field
                color="#4c248a"
                dense
                outlined
                v-model="application.lastName"
                readonly
              />
            </v-col>
          </v-row>
          <label>Email address</label>
          <v-text-field
            color="#4c248a"
            dense
            outlined
            v-model="application.email"
            readonly
          />

          <label>Phone Number</label>
          <v-text-field
            color="#4c248a"
            dense
            outlined
            v-model="application.phone"
            readonly
          />

          <label>Date of birth</label>
          <v-text-field
            color="#4c248a"
            dense
            outlined
            v-model="application.dob"
            readonly
          />

          <label>Gender</label>
          <v-text-field
            color="#4c248a"
            dense
            outlined
            v-model="application.gender"
            readonly
          />

          <label>Address</label>
          <v-text-field
            color="#4c248a"
            dense
            outlined
            v-model="application.address"
            readonly
          />

          <label>Nationality</label>
          <v-text-field
            color="#4c248a"
            dense
            outlined
            v-model="application.nationality"
            readonly
          />

          <label>Country of Residence</label>
          <v-text-field
            color="#4c248a"
            dense
            outlined
            v-model="application.country"
            readonly
          />

          <label>City</label>
          <v-text-field
            color="#4c248a"
            dense
            outlined
            v-model="application.city"
            readonly
          />
        </v-form>



        <h3 class="brandcolor mt-3">Academic Experience</h3>

        <div class="tableScroll">
          <table>
            <tr>
              <th class="type">University</th>
              <th class="status">Course</th>
              <th class="started">Qualification</th>
              <th class="started">Degree</th>
              <th class="submitted">CGPA</th>
              
            </tr>
            <tr v-if="application.academicExperience.length == 0">
              <td>
                You've not added an academic experience yet
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr
              v-else
              v-for="(institution, i) in application.academicExperience"
              :key="i"
            >
              <td>{{ institution.university }}</td>
              <td>{{ institution.courseOfStudy }}</td>
              <td>{{ institution.degree }}</td>
              <td>{{ institution.degreeClass }}</td>
              <td>{{ institution.cgpa }}/{{ institution.gpaScale }}</td>
              
            </tr>
          </table>
        </div>

        



        <h3 class="brandcolor mt-3">Additional Information</h3>
        <additionalinformation-component
          :loading="loading"
          @updateApplication="submitApplication"
          :proficiency="proficiency"
          :experiences="experiences"
          :employmentType="employmentType"
          :industryType="industryType"
          :internetAccess="internetAccess"
        />

        <h3 class="brandcolor mt-3">Supporting Documents</h3>

        <v-card class="pa-4" flat>
          <div class="descriptionBox">
            <p class="instructions">
              Please tell us about yourself, your interests, motivations and objectives for participating in this program. (max. 250 words)
            </p>
            <textarea
              style="max-height: 500px"
              cols="120"
              color="#4c248a"
              outlined
              v-model="application.aboutYourself"
              readonly
            ></textarea>
          </div>

          <div class="descriptionBox">
            <p class="instructions">
              According to the
              <a
                target="_blank"
                href="https://www.weforum.org/agenda/2023/05/future-of-jobs-2023-skills/"
                >World Economic Forum</a
              >, Ten (10) skills are required to thrive in the future of work. Please provide candid feedback on the three skills you actively seek to possess and why. (max. 200 words)
            </p>
            <textarea
              readonly
              color="#4c248a"
              outlined
              v-model="application.wefEssay"
            ></textarea>
          </div>


          <div class="descriptionBox">
            <p class="instructions">
              Why do you want to be a BMGA Fellow, what do you hope to gain from your time in the program, and what will you bring to the BMGA Fellows Program? Discuss the relevance of your future goals to your participation in this program. (max. 200 words)
            </p>
            <textarea
              style="max-height: 500px"
              cols="120"
              color="#4c248a"
              outlined
              v-model="application.whyEssay"
              readonly
            ></textarea>
          </div>

          <div class="descriptionBox">
            <p class="instructions">
              Describe the challenge(s) you expect concerning your participation in the program. [State any obstacles that may prevent your enrolment or completion of the BMGA Fellows Program.] (max. 100 words)
            </p>
            <textarea
              style="max-height: 500px"
              cols="120"
              color="#4c248a"
              outlined
              v-model="application.challenges"
              readonly
            ></textarea>
          </div>

          

          <div>
            <h4 class="brandcolor">Video Question</h4>
            <div>
              Instruction: Create a 3-minute (maximum) video of yourself that
              responds to the following prompts and upload it on YouTube as an
              unlisted video. After uploading it on YouTube, insert the link in
              the space provided below. (Ensure that there are no distractions
              or noise in your environment. Also ensure that your YouTube video
              link provided is correct).
            </div>

            <div>
              **For large video sizes, click
              <a
                href="https://www.freeconvert.com/video-compressor"
                target="_blank"
                >Video Compressor | Reduce Video File Size Online</a
              >
              to compress the video before uploading it.
            </div>
          </div>

          <ul>
            <li>
              What is your vision for social, economic, and political change in
              your country/continent? What would be some ideal outcomes, both in
              the short-term and the long-term? How will the BMGA Fellows Program help you actualize this vision?
            </li>
          </ul>

          <div class="mt-3">
            <label><strong>Video Link</strong></label> <br />
            <a style="font-size: 17px" :href="application.videoLink">{{
              application.videoLink
            }}</a>
          </div>

          <div class="mt-4">
            <h4 class="brandcolor">Resume*</h4>
            <div>A recent copy of your resume in PDF.</div>
            <input
              v-if="application.resume == ''"
              class="my-1"
              type="file"
              accept=".pdf"
            />
            <a
              class="my-2"
              v-if="application.resume != ''"
              :href="application.resume"
              target="_blank"
              ><u>{{ userData.firstName }}'s resume. View uploaded file</u></a
            >
          </div>

          <div class="mt-4">
            <h4 class="brandcolor">Reference Letter*</h4>
            
            <input
              v-if="application.referenceLetter == ''"
              class="my-1"
              type="file"
              accept=".pdf"
            />
            <a
              class="my-2"
              v-if="application.referenceLetter != ''"
              :href="application.referenceLetter"
              target="_blank"
              ><u
                >{{ userData.firstName }}'s reference. View uploaded file</u
              ></a
            >
          </div>
        </v-card>

        <h3 class="brandcolor mt-3">Marketing Information</h3>

        <v-form>
          <p>How did you find out about the BMGA Fellows Program?</p>

          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="bmga-website"
            label="BMGA website"
            >BMGA website</v-checkbox
          >
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="bmga-fellow"
            label="BMGA fellow"
            >BMGA Fellow</v-checkbox
          >
          <v-text-field
            v-model="application.referredByBMGAFellow"
            v-if="application.marketingInfo == 'bmga-fellow'"
            class="mt-5"
            color="#4c248a"
            outlined
            dense
            readonly
          />
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="search-engine"
            label="Search Engine"
            >Search Engine</v-checkbox
          >
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="facebook"
            label="Facebook"
          >
            Facebook</v-checkbox
          >
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="linkedin"
            label="LinkedIn"
            >LinkedIn</v-checkbox
          >
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="instagram"
            label="Instagram"
            >Instagram</v-checkbox
          >
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="Twitter"
            label="Twitter"
            >Twitter</v-checkbox
          >
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="university"
            label="University Career Center"
            >University Career Centre</v-checkbox
          >
          <!-- <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.marketingInfo"
            value="other"
            label="Other"
            :checked="marketingInfo.indexOf(application.marketingInfo.toLowerCase()) == -1"
            >Other</v-checkbox -->
          <label style="font-weight: bold" v-if="marketingInfo.indexOf(application.marketingInfo.toLowerCase()) == -1" class="instructions">
            Other
          </label>
          <v-text-field
            v-model="application.marketingInfo"
            v-if="marketingInfo.indexOf(application.marketingInfo.toLowerCase()) == -1"
            color="#4c248a"
            outlined
            dense
            readonly
          />


        </v-form>

        <h3 class="brandcolor mt-3">Confidential Information</h3>

        <v-form>
          <p>
            Do you have a disability you wish to declare? (This information WILL
            NOT be used to inform selection decisions. It is helpful for us to
            be aware at this stage of any likely requirements you may have
            during the program.)
          </p>

          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="no-disability"
            label="No disability"
          ></v-checkbox>
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="austic-disorder"
            label="Austic Disorder"
          ></v-checkbox>
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="blind/partial-sight"
            label="Blind/Partial sight"
          ></v-checkbox>
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="deaf/partial-hearing"
            label="Deaf/Partial Hearing"
          ></v-checkbox>
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="mental-disorder"
            label="Mental Disorder"
          ></v-checkbox>
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="learning-difficulty"
            label="Learning Difficulty"
          ></v-checkbox>
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="wheelchair/mobility/other"
            label="Wheelchair/mobility"
          ></v-checkbox>
          <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="do-not-wish-to-disclose"
            label="Do not wish to disclose"
          ></v-checkbox>
          <!-- <v-checkbox
            readonly
            class="my-0 py-0"
            color="#4c248a"
            v-model="application.confidentialInfo"
            value="other"
            label="Other"
          ></v-checkbox> -->
          <label style="font-weight: bold" v-if="confidentialInfo.indexOf(application.confidentialInfo.toLowerCase()) == -1" class="instructions">
            Other
          </label>
          <v-text-field
            v-model="application.confidentialInfo"
            v-if="confidentialInfo.indexOf(application.confidentialInfo.toLowerCase()) == -1"
            color="#4c248a"
            outlined
            dense
            readonly
          />
        </v-form>

        <h3 class="brandcolor mt-3">Signature</h3>

        <v-form>
          <v-checkbox
            readonly
            color="#4c248a"
            :value="true"
            v-model="application.attest"
            label="I certify that all answers provided throughout the application have been correctly answered to the best of my knowledge and that I have not intentionally misled or falsified any answer provided in my application. I grant BMGA Foundation permission to conduct reasonable and necessary background checks to validate all the information provided in my application. "
          ></v-checkbox>

          <label>Signature (Enter full name)*</label>
          <v-text-field
            readonly
            v-model="application.signature"
            color="#4c248a"
            outlined
            dense
          />

          <p>
            You will not be able to proceed with your application if you have
            not checked the declaration or included your signature. Ensure you
            correctly do so.
          </p>
          <p class="information">
            Note that selected applicants may be required to provide further
            information prior to the final selection, including verifications.
          </p>
        </v-form>

        <div class="mt-12">
          <v-btn
            @click="submitApplication"
            :loading="loading"
            block
            x-large
            class="primaryBtn"
            >Submit</v-btn
          >
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>
        
        
<script>
import { mapState } from "vuex";
import AppBar from "./AppBar.vue";
import AppSidebar from "./AppSidebar.vue";
import axios from "axios";
import AdditionalinformationComponent from "./../AdditionalInformation/AdditionalinformationComponent.vue";
import moment from "moment";

export default {
  data() {
    return {
      loading: false,
      proficiency: ["Basic", "Fluent", "Proficient"],
      marketingInfo: [
      "bmga-website",
      "bmga-fellow",
      "search-engine",
      "facebook",
      "linkedin",
      "instagram",
      "Twitter",
      "university"
      ],
      confidentialInfo: [
      "no-disability",
      "austic-disorder",
      "blind/partial-sight",
      "deaf/partial-hearing",
      "mental-disorder",
      "learning-difficulty",
      "wheelchair/mobility/other",
      "do-not-wish-to-disclose"
      ],
      isProfessional: "",
      experiences: [
        "Less than 1 year",
        "1-3 years",
        "3-5 years",
        "5-10 years",
        "More than 10 years",
      ],
      yearsOfExperience: "",
      employment: "",
      employmentType: ["Not Employed", "Internship", "Part-Time", "Full-Time"],
      industry: "",
      industryType: [
        "Asset/Investment Management",
        "Banking",
        "Consulting",
        "Consumer Durables",
        "Education",
        "Engineering/Construction",
        "Government/Public Sector",
        "Health Services/Hospitals",
        "Insurance",
        "International Development",
        "Legal Services",
        "Logistics",
        "Manufacturing",
        "Marketing/PR/Advertising",
        "Media/Entertainment",
        "Non-Profit",
        "Pharmaceuticals",
        "Research/Academia",
        "Retail",
        "Technology",
        "Telecommunications",
        "Other",
      ],
      company: "",
      internet: "",
      isTuition: "",
      internetAccess: [
        "On a regular basis",
        "Most of the time",
        "I will not have a reliable connection to the internet",
      ],
      fundingDescription: "",
    };
  },
  components: { AppBar, AppSidebar, AdditionalinformationComponent },
  computed: {
    ...mapState({
      userData: "userData",
      application: "application",
    }),
  },

  created() {
    window.scrollTo(0, 0);
  },

  methods: {
    submitApplication() {
      //check if there is any required field that has not been filled
      if (!this.checkApplication(this.application)) {
        return;
      }
      const payload = {
        applicationId: this.application._id,
        status: "Submitted",
        submitted: moment(Date.now()).format("LLL"),
        email:this.application.email,
        firstName:this.application.firstName
      };

      this.loading = true;
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/update-application",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: payload,
      }).then(() => {
        this.loading = false;
        this.$swal({
          icon: "success",
          width: '850px',
          title: "Thank you for applying to the BMGA Fellows Program!",
          html: `Your application has been received and is now with our Review Team. 
                We will be in touch again to let you know the status of your application or if we need 
                further information from you.<br><br>

                Please note that this is a paid program that is heavily subsidized by the BMGA Foundation and its partners. The program fee has been subsidized to ensure that participants get a high-quality educational experience. After admission into the program, participants are required to pay a fee of $350. 
                Please note that selected applicants get access to high-level professional development courses taught by experts and professors in renowned institutions and diverse industries; mental health and wellness sessions with experienced counsellors; and access to a globally celebrated Strengths Test for Career Development.
                The program also consists of peer-to-peer learning where participants will be paired with graduate students from top foreign universities for mentorship or structured coaching from executives with international experience. 

          `,
          confirmButtonText: `<p class="brandcolor">Sounds Great!</p>`,
        }).then((result) => {
          if (result) {
            this.$router.push("/applications");
          }
        });
      });
    },

    checkApplication(obj) {
      delete obj.postalCode
      delete obj.company
      delete obj.sector
      delete obj.yearsOfExperience
      delete obj.employed
      delete obj.isTuition
      delete obj.inequalityEssay
      delete obj.familyEssay
      delete obj.fiveYearsEssay
      delete obj.describeTuition
      delete obj.referredByBMGAFellow
      delete obj.videoLink // Make YouTube URL not required for submission.

      obj.submitted = moment(Date.now()).format("LLL");
      // const valueArray = Object.values(obj);
      const keyArray = Object.keys(obj)

      console.log(keyArray)

      for (var key of keyArray) {
        

        if (obj[key] === "") {
          console.log(obj[key])
          this.$swal({
            icon: "error",
            title: "Application Not Complete!",
            html: `
                <p>You need to fill out all required fields before you can submit your application</p> <br>
                <p>Kindly fill/upload the missing field: ${key}</p>
                `,
            confirmButtonText: `<p class="brandcolor">Ok</p>`,
          });
          return false;
        }
      }
      return true
    },
  },
};
</script>
        
        
        
        
        <style scoped>
@media only screen and (max-width: 600px) {
  .topTitle {
    padding: 0px 0px 0px 15px;
  }
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 776px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 894px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 996px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}
.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.v-text-field {
  height: 54px !important;
}

.instructions {
  font-size: 16px;
  margin: 0px 0px 10px 0px;
}

.checkBoxes {
  margin-top: -27px;
}

.descriptionBox {
  margin: 0px 0px 30px 0px;
}

.v-textarea,
textarea {
  resize: none !important;
}
textarea {
  width: 100%;
  outline: 1px solid #4c248a;
  border-radius: 5px;
  height: 250px;
  padding: 10px;
}

.information {
  color: red;
  font-style: italic;
}


@media only screen and (max-width: 600px) {
  .topTitle {
    padding: 0px 0px 0px 15px;
  }
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 776px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 894px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 996px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.v-text-field {
  height: 54px !important;
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.v-text-field {
  height: 54px !important;
}

.instructions {
  margin-top: -10px;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 15px;
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* tr:nth-child(1) {
  background-color: #dddddd;
} */

@media only screen and (max-width: 700px) {
  .tableScroll {
    overflow-x: scroll;
  }
}

table{
  width:100% !important
}
</style>

