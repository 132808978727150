<template>
  <div>
    <div class="sideBar hidden-md-and-down">
      <div class="text-center">
        <v-card
          tile
          :class="
            page.substring(1) == 'application/personal-information'
              ? 'active'
              : ''
          "
          @click="$router.push('/application/personal-information')"
          class="pa-5"
        >
          <v-icon
            class="mr-2"
            :color="
              page.substring(1) == 'application/personal-information'
                ? 'white'
                : ''
            "
            >mdi-account</v-icon
          >
          Personal Information
        </v-card>

        <v-card
          tile
          :class="
            page.substring(1) == 'application/academic-experience'
              ? 'active'
              : ''
          "
          @click="$router.push('/application/academic-experience')"
          class="pa-5 my-6"
        >
          <v-icon
            class="mr-3"
            :color="
              page.substring(1) == 'application/academic-experience'
                ? 'white'
                : ''
            "
            >mdi-school</v-icon
          >
          Academic Experience
        </v-card>

        <v-card
          :color="
            page.substring(1) == 'application/additional-information'
              ? 'active'
              : ''
          "
          tile
          @click="$router.push('/application/additional-information')"
          class="pa-5"
        >
          <v-icon
            class="mr-2"
            :color="
              page.substring(1) == 'application/additional-information'
                ? 'white'
                : ''
            "
            >mdi-account-plus</v-icon
          >
          Additional Information
        </v-card>

        <v-card
          :color="
            page.substring(1) == 'application/supporting-documents'
              ? 'active'
              : ''
          "
          tile
          @click="$router.push('/application/supporting-documents')"
          class="pa-5 my-6"
        >
          <v-icon
            :color="
              page.substring(1) == 'application/supporting-documents'
                ? 'white'
                : ''
            "
            >mdi-file-document</v-icon
          >
          Supporting Documents
        </v-card>

        <v-card
          :color="
            page.substring(1) == 'application/marketing-information'
              ? 'active'
              : ''
          "
          tile
          @click="$router.push('/application/marketing-information')"
          class="pa-5 my-6"
        >
          <v-icon
            class="mr-2"
            :color="
              page.substring(1) == 'application/marketing-information'
                ? 'white'
                : ''
            "
            >mdi-information</v-icon
          >
          Marketing Information
        </v-card>

        <v-card
          :color="
            page.substring(1) == 'application/confidential-information'
              ? 'active'
              : ''
          "
          tile
          @click="$router.push('/application/confidential-information')"
          class="pa-5 my-6"
        >
          <v-icon
            class="mr-2"
            :color="
              page.substring(1) == 'application/confidential-information'
                ? 'white'
                : ''
            "
            >mdi-contacts</v-icon
          >
          Confidential Information
        </v-card>

        <v-card
          :color="page.substring(1) == 'application/signature' ? 'active' : ''"
          tile
          @click="$router.push('/application/signature')"
          class="pa-5 my-6"
        >
          <v-icon
            class="mr-4"
            :color="page.substring(1) == 'application/signature' ? 'white' : ''"
            >mdi-account-edit</v-icon
          >
          Signature
        </v-card>

        <v-card
          :color="page.substring(1) == 'application/review' ? 'active' : ''"
          tile
          @click="$router.push('/application/review')"
          class="pa-5 my-6"
        >
          <v-icon
            class="mr-4"
            :color="page.substring(1) == 'application/review' ? 'white' : ''"
            >mdi-account-check</v-icon
          >
          Review & Submit
        </v-card>
      </div>
    </div>

    <v-navigation-drawer :value="drawer" absolute left temporary>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="goToPage('/application/personal-information')">
            Personal Information
          </v-list-item>

          <v-list-item @click="goToPage('/application/academic-experience')">
            Academic Experience
          </v-list-item>

          <v-list-item @click="goToPage('/application/additional-information')">
            Additional Information
          </v-list-item>

          <v-list-item @click="goToPage('/application/supporting-documents')">
            Supporting Documents
          </v-list-item>

          <v-list-item @click="goToPage('/application/marketing-information')">
            Marketing Information
          </v-list-item>

          <v-list-item @click="goToPage('/application/confidential-information')">
            Confidential Information
          </v-list-item>

          <v-list-item @click="goToPage('/application/signature')">
           Signature
          </v-list-item>

          <v-list-item @click="goToPage('/application/review')">
            Review & Submit
          </v-list-item>

      


        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>



<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    group: null,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  props: {
    page: {
      type: String,
    },
    userData: {
      type: Object,
    },
  },

  computed: {
    ...mapState({
      drawer: "drawer",
    }),
  },

  methods: {
    goToPage(e) {
      this.$router.push(e);
      this.$store.dispatch("showMenu");
    },

    goToApplications() {
      if (this.userData.status === "") {
        this.$router.push("/eligibility-check");
        return;
      }

      if (this.userData.status === false) {
        this.$swal({
          icon: "error",
          title: "Not Eligible",
          text: "You're not eligible to apply for the BMGA Fellowship. Kindly try again later",
          confirmButtonText: `<p class="brandcolor">Ok</p>`,
        });
        return;
      }

      if (this.userData.status == true) {
        this.$router.push("/applications");
      }
    },
  },
};
</script>

<style scoped>
.sideBar {
  position: absolute;
  left: 12px;
  top: 120px;
}

.active {
  background: #4c248a !important;
  color: white !important;
}
</style>