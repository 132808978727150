import { render, staticRenderFns } from "./ApplicationComponent.vue?vue&type=template&id=f27c9cb8&scoped=true"
import script from "./ApplicationComponent.vue?vue&type=script&lang=ts"
export * from "./ApplicationComponent.vue?vue&type=script&lang=ts"
import style0 from "./ApplicationComponent.vue?vue&type=style&index=0&id=f27c9cb8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_vue-template-compiler@2.7.16_webpack@5.90.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f27c9cb8",
  null
  
)

export default component.exports