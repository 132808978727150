<template>
  <v-app>
    <v-main class="hidden-md-and-down">
      <v-row style="background: #f8f8f9; height: 101%">
        <v-col cols="12" md="1" sm="1" lg="1">
          <AdminSideBar />
        </v-col>
        <v-col cols="12" md="11" sm="11" lg="11">
          <AppBar />
          
          <SingleApplicationComponent />
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
  
  
  <script lang="ts">
import AdminSideBar from "../../components/Reviewer/AdminSideBar.vue";
import SingleApplicationComponent from "@/components/Reviewer/SingleApplication/SingleApplicationComponent.vue";
import AppBar from "@/components/Reviewer/AppBar.vue";

export default {
  components: {
    AdminSideBar,
    SingleApplicationComponent,
    AppBar,
  },

  created() {
    console.log(this.$route.params.id);
    this.$store.dispatch("fetchReviewerApplication", this.$route.params.id);
    if (sessionStorage.getItem("reviewerToken") == null) {
      this.$router.push("/reviewer/login");
    }
  },
};
</script>
  
  <style scoped>
</style>