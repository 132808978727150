<template>
    <v-app>
      <v-main class="signupForm">
      <v-card elevation="8" width="500px" class="rounded-lg mx-auto">
        <div class="signupCard" tile>
          <v-alert v-model="alert" type="error">{{ message }}</v-alert>
          <h2 class="brandcolor text-center">Login</h2>
          <div class="text-center">Continue your application</div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <label>Email address</label>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              dense
              color="#4c248a"
              single-line
              outlined
            />
  
            <label>Password</label>
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              dense
              color="#4c248a"
              single-line
              :type="show ? 'text' : 'password'"
              outlined
              @click:append="show = !show"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            />
            <div class="brandcolor float-right mb-4"><router-link to="/forgot-password">Forgot Password?</router-link></div>

          </v-form>
  
          <div class="my-3">
            <v-btn :loading="loading" @click="signup" block x-large color="#4c248a" class="white--text"
              >Login</v-btn
            >
            <p class="text-center my-3">
              Don't have an account?
              <router-link to="/signup" class="brandcolor">Signup</router-link>
            </p>
          </div>
      </div>
  </v-card>
      </v-main>
    </v-app>
  </template>
  
  
  
  <script>
  import axios from 'axios'
  
  export default {
    data() {
      return {
        valid: true,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        firstNameRules: [(v) => !!v || "First name is required"],
        lastNameRules: [(v) => !!v || "Last name is required"],
        emailRules: [
          (v) => !!v || "Email address is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phoneRules: [
          (v) => !!v || "Phone number is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        passwordRules: [
          (v) => !!v || "Password is required",
          (v) =>
            (v && v.length >= 6) || "Password must be greater than 6 characters",
        ],
        checkbox: false,
        show: false,
        showConfirm:false,
        alert:false,
        message:"",
        loading:false
      };
    },
    methods: {
    async signup() {
        await this.$refs.form.validate();
        if(!this.valid){
          return
        }
        if(this.password ==''){
          this.alert = true
          this.message="Please enter your password"
          setTimeout(()=>{
              this.alert = false
          },1000)
          window.scrollTo(0,0)
          return
        }

        this.loading = true
        axios({
          method:"POST",
          url:process.env.VUE_APP_API_URL + "/login",
          headers:{
              Accept:"*/*",
              ContentType:"application/json"
          },
          data:{
              email:this.email.replaceAll(" ",""),
              password:this.password
          }
        }).then((response)=>{
          this.loading = false
          if(response.status== 290){
            this.$swal({
              icon:"error",
              title:"Login Unsuccessful",
              text:response.data.errors.password ? response.data.errors.password:"Email is incorrect. Kindly check and try again",
              confirmButtonText:`<p class="brandcolor">Ok</p>` 
          })
          return
          }

          if(response.data.accountStatus == 'unverified'){
            this.$swal({
              icon:"warning",
              title:"Registration Incomplete",
              text:"You cannot login because your account is not verified. Kindly tap the link sent to your email to verify your account and continue.",
              confirmButtonText:`<p class="brandcolor">Open Email</p>`,
            }).then((result)=>{
              if(result.isConfirmed){
                window.open("https://gmail.com","_blank")
              }
            })
            return
          }
          sessionStorage.setItem("bmgaToken",response.data.token)
          window.location.href="/dashboard"
        }).catch((error)=>{
          this.loading = false
          console.log(error)
          this.$swal({
              icon:"error",
              title:"Login Unsuccessful",
              text:error.response.data.message,
              confirmButtonText:`<p class="brandcolor">Ok</p>` 
          })
        })
  
  
      },
    },
  };
  </script>
  
  
  <style scoped>
  .signupForm {
    background-image:linear-gradient(to bottom, #8b369473, #4d248a6e),
    url('../../../assets/background-image.jpg');
    background-size: cover;
    padding: 60px !important;
  }
  
  .signupCard {
    padding: 20px 40px;
    background:white
  }
  
  label {
    font-size: 13px;
  }
  
  .v-text-field {
    height: 54px !important;
  }
  
  span {
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    line-height: 12px;
  }
  
  h2 {
    margin-bottom: -3px;
  }
  
  .image::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(120deg, #8b3694, #4c248a);
      opacity: .7;
  }
  
  
  
  @media screen and (max-width: 600px) {
    .signupForm {
      background: #feeaf3;
      padding: 60px 15px !important;
    }
    .signupCard {
      padding: 20px 20px;
    }
  }
  </style>