<template>
    <v-app class="backgroundColor">
        <AppBar/>
        <v-container class="my-5 container">
        <SideBar :page="$route.path" :userData="userData" :application="application"/>
        <h1 class="brandcolor">Welcome, {{ userData.firstName }}</h1>

        <v-card flat class="pa-5 my-5">
        <p>We are pleased that you are applying to join the next cohort of the BMGA Fellows Program. From this portal you will be able to apply to the BMGA Fellows Program, upload supporting documentation, and track the progress of your application until the selection process.</p></v-card>

        <v-row>
        <v-col md="8" sm="8" xs="8">
        <v-card v-if="(parseInt(application.stage) < 4 || application.stage == undefined) && application.status!='Accepted'" class="pa-5" flat>
        <h3 class="brandcolor my-3">My Application</h3>
        <p>Candidates are required to fill out and submit the complete application on or before <span class="brandcolor"><b> 23:59 hours (GMT+1) on {{ applicationDeadline }}</b></span>. This is a strict deadline and incomplete applications will not be considered. All information submitted is considered confidential and will only be passed to those involved in the selection process. </p>

<p>Our application portal offers you the flexibility to complete your application in stages at your convenience. You can log back into your account to continue with your application.</p>

<div class="my-3">
<b class="brandcolor">Application Requirements:</b>
<p><ul>
<li>
Personal Information 
</li>
<li>
Essay Questions 
</li>
<li>
Video Response
</li>
<li>
Resume
</li>
<li>
Letter of Recommendation (not more than 1 page) from someone who knows you well academically or professionally (eg; Academic Supervisor, Employer, Volunteer Organization) to support your application for the program. Please note that the recommendation must be typed on official letterhead and signed by the referee. 
</li>
</ul>
</p>
</div>
</v-card>


<v-card class="pa-5 my-7" flat v-if="parseInt(application.stage) < 4 || application.stage == undefined">
    <h4 class="brandcolor my-5">Eligibility Status</h4>
<v-btn v-if="userData.eligibilityStatus == ''" to="/eligibility-check" large class="white--text" color="#4c248a">Check Eligibility</v-btn>
<div v-if="userData.eligibilityStatus =='true'">You are <b class="brandcolor">eligible</b> for the BMGA Fellows Program. <br/> Click <v-btn color="#4c248a" class="white--text" @click="goToApplication">here to continue your application</v-btn></div>  
<div v-if="userData.eligibilityStatus =='false' && userData.status !==''">You are <b class="brandcolor"> NOT Eligible</b> for the BMGA Fellows Program.<br/>Please try again next year.</div>  
</v-card>

<v-card class="pa-5 my-7 pb-8" flat v-if="parseInt(application.stage) >= 4 && application.status=='Accepted'">
    <h3 class="brandcolor my-5"> Ready to begin your journey?</h3>
    <p>Congratulations on being selected to participate in the BMGA Fellows Program!</p>
    <p>We are excited about this and look forward to working with you on this transformational experience. You are about to meet a special group of high-potential women who we hope will become a valued part of your network.</p>

    <p>To begin your journey, download your acceptance letter from our Founder, review our terms of reference, make payment using the payment guide provided below, and proceed to enroll in the program. In order to reserve your spot in this cohort, please complete the steps outlined on or before the stated deadline in your email.</p>

    <p>After successful enrollment in the BMGA Fellows Program, be on the lookout for an email from support@bmgafoundation.org with the subject "Welcome to the BMGA Learning Platform." You should receive this email within a week of enrollment, giving you access to the Learning Management System for our program.</p>

    <router-link class="document" to="/acceptance-letter">Acceptance Letter</router-link>
    <a class="document" target="_blank" href="https://drive.google.com/file/d/14kTTfsOc3HGirebvgwgaBlJ1s6rhACpQ/view">Terms of Reference</a>
    <a class="document" target="_blank" href="https://drive.google.com/file/d/1gqoIFUbUZfiShmT9__ulZ4tIV3SyLeVf/view?usp=share_link">Payment Guide</a>
  
    <div class="mt-6">
      <v-btn to="/complete-enrollment" color="#4c248a" class="white--text" large block>Complete Enrollment</v-btn>
    </div>
  
  </v-card>


  <v-card class="pa-5 my-7 pb-8" flat v-if="parseInt(application.stage) >= 4 && (application.status=='Pending Enrollment' || application.status=='Pending Review')">
    <h3 class="brandcolor my-5"> Pending Enrollment </h3>
    <p>We have received your enrollment form and our team would confirm your payment status before proceeding to enroll you into the program.</p>
    <p>We look forward to welcoming you fully!</p>

    <router-link class="document" to="/acceptance-letter">Acceptance Letter</router-link>
    <a class="document" target="_blank" href="https://drive.google.com/file/d/14kTTfsOc3HGirebvgwgaBlJ1s6rhACpQ/view">Terms of Reference</a>
  </v-card>


  <v-card class="pa-5 my-7 pb-8" flat v-if="parseInt(application.stage) >= 4 && application.status=='Enrolled'">
    <h3 class="brandcolor my-5"> You're Now Enrolled</h3>
    <p>Congratulations, you have successfully completed your enrollment into the BMGA Fellows Program.</p>
    <p>We are excited about this and look forward to working with you on this transformational experience. You are about to meet a special group of high-potential women who we hope will become a valued part of your network.</p>
 
    <router-link class="document" to="/acceptance-letter">Acceptance Letter</router-link>
    <a class="document" target="_blank" href="https://drive.google.com/file/d/14kTTfsOc3HGirebvgwgaBlJ1s6rhACpQ/view">Terms of Reference</a>
 
  </v-card>
    </v-col>

    <v-col md="4" sm="4" xs="4">
        <v-card flat class="needHelpCard pa-5">
            <h4 class="brandcolor">Need help?</h4>

            <p>Please click here <a href="https://bmgafoundation.org/faqs">https://bmgafoundation.org/faqs</a> to find out more about the program from our FAQs.</p>
           <p>If you need further assistance, contact our team who will be happy to help with any queries you have about completing this application via <a href="mailto:info@bmgafoundation.org">info@bmgafoundation.org.</a></p>
        </v-card>
    </v-col>
       </v-row>
    </v-container>
    </v-app>
</template>


<script>
import { mapState } from "vuex";
import AppBar from "../Applicant/AppBar.vue";
import SideBar from "../Applicant/SideBar.vue";
export default {
  components: {
    AppBar,
    SideBar,
  },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      userData: "userData",
      settings: "settings",
      application: "application",
    }),
    applicationDeadline: function() {
      let date = new Date(this.settings.applicationDeadline)
      
      let dDate = new Date(date.setDate(date.getDate() - 1))

      return dDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
    }
  },

  methods: {
    goToApplication() {
      const time = Date.parse(this.settings.applicationDeadline);
      console.log(time);
      if (Date.now() > time) {
        this.$swal({
          icon: "info",
          title: "Deadline Passed",
          text: "You cannot proceed with this application because the application deadline has passed. Kindly try again next year.",
          confirmButtonText: `<p class='brandcolor'>Ok</p>`,
        });
        return;
      }

      this.$router.push("/applications");
    },
  },
};
</script>




<style scoped>
.container {
  max-width: 950px;
  margin: auto;
  padding: 0px 50px;
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.document {
  border: solid 1px #4c248a;
  padding: 6px;
  margin: 0px 6px;
  width: 30%;
}
</style>