import axios from "axios"


export default {
    namespaced: true,
    state: {
        reviewerToken: sessionStorage.getItem("reviewerToken"),
        reviewerEmail: sessionStorage.getItem("reviewerEmail"),
        applications:[],
        loading:false,
        
    },
    getters: {},
    mutations: {
        fetchApplications(state){
            axios({
                method:"GET",
                url:process.env.VUE_APP_API_URL + "/reviewer/application?email="+state.reviewerEmail,
                headers:{
                    Authorization:`Bearer ${state.reviewerToken}`,
                    Accept:"*/*",
                    ContentType:"application/json"
                },
            }).then((response)=>{
                state.applications = response.data.data
            })
        }
    },
    actions:{
        fetchApplications:(context,value) =>{
            context.commit("fetchApplications",value)
          }
    }
}