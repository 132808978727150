<template>
  <div class="emailcontainer">
    <v-container style="padding: 40px 0px 40px 16px">
      <div class="d-flex justify-space-between">
        <div><h2>Rejection Email</h2></div>
        <div>
          <v-btn small @click="closeModal" fab depressed
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
      </div>

      <div>
        <h2>Dear Ms. { { candidateSurName } }</h2>
      </div>
      <tiptap
        @emailContent="emailContent"
        id="emailBody"
        :email="settings.rejectionEmail"
      />
      <div
        style="margin: 0px 60px 50px 0px; right: 0; bottom: 0; position: fixed"
      >
        <v-btn
          @click="submitEmail()"
          x-large
          color="red"
          class="white--text rounded-lg"
          :loading="loading"
          >Update Template</v-btn
        >
      </div>
    </v-container>

    <v-dialog v-model="success" max-width="500">
      <v-card elevation="24" class="createAccount pa-7 text-center">
        <v-icon size="100px" color="white">mdi-check-circle</v-icon>
        <h1
          style="font-size: 23px; padding: 10px; color: white"
          class="font-weight-black"
        >
          {{ title }}
        </h1>
        <p style="font-size: 15px; color: white">{{ message }}</p>
        <v-btn @click="done()" elevation="24"> Ok</v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="error" max-width="500">
      <v-card elevation="24" class="createAccount pa-7 text-center">
        <v-icon size="100px" color="white">mdi-close-circle</v-icon>
        <h1
          style="font-size: 23px; padding: 10px; color: white"
          class="font-weight-black"
        >
          {{ title }}
        </h1>
        <p style="font-size: 15px; color: white">
          {{ message }}
        </p>
        <v-btn @click="error = false" elevation="24"> Close</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script>
import axios from "axios";
import Tiptap from "../Admin/Index.vue";
// import moment from 'moment'
export default {
  name: "EmailComponent",
  components: {
    Tiptap,
  },
  data() {
    return {
      row: 5,
      rowwithdraw: 5,
      success: false,
      error: false,
      message: "",
      title: "",
      emailBody: "",
      loading: false,
    };
  },
  props: {
    settings: {
      type: Object || String,
    },
  },
  computed: {},

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    emailContent(e) {
      this.emailBody = e;
    },

    submitEmail() {
      console.log(this.emailBody);
      this.loading = true;
      // set acceptance email here
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/admin/rejection-email",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("adminToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: {
          emailContent: this.emailBody,
        },
      }).then((response) => {
        console.log(response);
        this.loading = false;
        this.$swal({
          icon:"success",
          title:"Template updated successfully",
          text:"You have successfully updated the rejection email template. This would be sent to applicants when you send rejection emails",
          confirmButtonText:`<p class='brandcolor'>Ok</p>`
        })
      });
    },
  },
};
</script>
    
    <style scoped>
.v-btn:active {
  border-bottom: solid 1px #1ca08a;
  color: #f5ce2c;
  box-shadow: 0px;
}
.v-btn:visited {
  border-bottom: solid 1px #1ca08a;
  color: #f5ce2c;
  box-shadow: 0px;
}
.v-btn:hover {
  color: #f5ce2c;
  border-bottom: solid 1px#1ca08a;
  box-shadow: 0px;
}

.emailcontainer {
  background: white !important;
  height: 150% !important;
  padding: 0px 40px 0px 0px;
}
</style>
  
  