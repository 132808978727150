<template>
  <v-app>
    <div class="acceptanceLetter">
      <v-btn class="my-4" depressed small fab @click="goBack"
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >

      <AcceptanceLetter />

      <VueHtml2pdf
        :manual-pagination="true"
        :enable-download="true"
        ref="DownloadComp"
      >
        <section slot="pdf-content">
          <div class="pa-5">
            <AcceptanceLetter />
          </div>
        </section>
      </VueHtml2pdf>
      <div>
        <v-btn @click="downloadPDF()" color="#310059" class="white--text"
          >Download as Pdf
          <v-icon class="mx-3">mdi-cloud-download</v-icon></v-btn
        >
      </div>
    </div>
  </v-app>
</template>



<script>
import AcceptanceLetter from "../../components/Applicant/AcceptanceLetter.vue";
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: {
    AcceptanceLetter,
    VueHtml2pdf
  },

  created() {
    window.scrollTo(0, 0);
    this.$store.dispatch("fetchUser");
    this.$store.dispatch("fetchApplications");
    if (sessionStorage.getItem("bmgaToken") == null) {
      this.$router.push("/login");
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },

    downloadPDF () {
        this.$refs.DownloadComp.generatePdf()
    }
  },
};
</script>


<style scoped>
@media only screen and (max-width: 600px) {
  .acceptanceLetter {
    padding: 40px 10px;
  }
}

@media only screen and (min-width: 600px) {
  .acceptanceLetter {
    padding: 40px 50px;
  }
}

@media only screen and (min-width: 776px) {
  .acceptanceLetter {
    padding: 40px 50px;
  }
}

@media only screen and (min-width: 894px) {
  .acceptanceLetter {
    padding: 40px 50px;
  }
}

@media only screen and (min-width: 996px) {
  .acceptanceLetter {
    padding: 40px 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .acceptanceLetter {
    padding: 40px 160px;
  }
}
</style>