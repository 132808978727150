<template>
  <v-app class="backgroundColor" style="height:150vh">
    <AppBar />
    <AppSidebar :page="$route.path" :userData="userData" />
    <v-container class="my-5 container">
      <h1 class="brandcolor my-3 topTitle">Signature</h1>
      <v-card class="pa-4" flat>
        <v-form>
          <v-checkbox
            color="#4c248a"
            :value=true
            v-model="application.attest"
            label="I certify that all answers provided throughout the application have been correctly answered to the best of my knowledge and that I have not intentionally misled or falsified any answer provided in my application. I grant BMGA Foundation permission to conduct reasonable and necessary background checks to validate all the information provided in my application. "
          ></v-checkbox>

          <label>Signature (Enter full name)*</label>
          <v-text-field v-model="application.signature" color="#4c248a" outlined dense />

          <p>
            You will not be able to proceed with your application if you have
            not checked the declaration or included your signature. Ensure you
            correctly do so.
          </p>
          <p class="information">
            Note that selected applicants may be required to provide further
            information prior to the final selection, including verifications.
          </p>
        </v-form>

        <div class="mt-12">
          <v-btn
            @click="updateApplication"
            :loading="loading"
            block
            x-large
            class="primaryBtn"
            >Save & Continue</v-btn
          >
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>
      
      
      <script>
import { mapState } from "vuex";
import AppBar from "./AppBar.vue";
import AppSidebar from "./AppSidebar.vue";
import axios from "axios";

export default {
  data() {
    return {
      attest: "",
      loading:false
    };
  },
  components: { AppBar, AppSidebar },
  created(){
    window.scrollTo(0,0)
  },
  computed: {
    ...mapState({
      userData: "userData",
      application:"application"
    }),
  },

  methods: {
    updateApplication() {
      this.loading = true;
      const payload = {
        applicationId:this.application._id,
        attest:this.application.attest,
        signature:this.application.signature
      };
      console.log(payload);
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/update-application",
        headers:{
                Authorization:"Bearer "+sessionStorage.getItem("bmgaToken"),
                ContentType:"application/json",
                Accept:"*/*"
            },
        data: payload,
      }).then((response) => {
        console.log(response);
        this.loading = false;
        this.$router.push("/application/review")
      }).catch((error)=>{
        console.log(error)
      })
    },
  },
};
</script>
      
      
      
      
      <style scoped>
@media only screen and (max-width: 600px) {
  .topTitle {
    padding: 0px 0px 0px 15px;
  }
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 776px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 894px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 996px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

.v-text-field {
  height: 54px !important;
}

.instructions {
  font-size: 13px;
  margin: 0px 0px 10px 0px;
}

.checkBoxes {
  margin-top: -27px;
}

.descriptionBox {
  margin: 0px 0px 30px 0px;
}

.v-textarea,
textarea {
  resize: none !important;
}
textarea {
  width: 100%;
  outline: 1px solid #4c248a;
  border-radius: 5px;
  height: 250px;
  padding: 10px;
}

.information{
  color:red;
  font-style:italic ;
}
</style>