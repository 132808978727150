<template>
    <v-app>
        <v-main class="hidden-md-and-down">
      <v-row style="background:#F8F8F9;height:101%">
        <v-col cols="12" md="1" sm="1" lg="1">
          <AdminSideBar />
        </v-col>
        <v-col  cols="12" md="11" sm="11" lg="11">
            <AppBar />
            <DashboardComponent />
        </v-col>
      </v-row>
    </v-main>
    </v-app>
</template>


<script>
import AdminSideBar from "../../components/Admin/AdminSideBar.vue"
import DashboardComponent from "@/components/Admin/Dashboard/DashboardComponent.vue"
import AppBar from "@/components/Admin/AppBar.vue";
import { mapState } from "vuex";

export default {
    components:{
        AdminSideBar,
        DashboardComponent,
        AppBar
    },

    computed:{
      ...mapState([
        'admin'
      ])
    },
    
    created(){
       if(!JSON.parse(sessionStorage.getItem("stats"))){
        this.$store.dispatch("admin/fetchAdminStats")
       }

        this.$store.dispatch("admin/fetchAdminReviewers")
        this.$store.dispatch("admin/fetchAdminApplicantStats")    
        this.$store.dispatch("admin/fetchAdminUsers")
        this.$store.dispatch("admin/fetchAdminApplicants")

      
        if(sessionStorage.getItem("adminToken") == null){
          this.$router.push("/admin/login")
        }
    }
}
</script>

<style scoped>

</style>