import { render, staticRenderFns } from "./SignatureComponent.vue?vue&type=template&id=5dbd13eb&scoped=true"
import script from "./SignatureComponent.vue?vue&type=script&lang=js"
export * from "./SignatureComponent.vue?vue&type=script&lang=js"
import style0 from "./SignatureComponent.vue?vue&type=style&index=0&id=5dbd13eb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_vue-template-compiler@2.7.16_webpack@5.90.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dbd13eb",
  null
  
)

export default component.exports