<template>
  <v-app class="backgroundColor">
    <AppBar />
    <AppSidebar :page="$route.path" :userData="userData" />
    <v-container class="my-5 container">
      <h1 class="brandcolor">Supporting Documents</h1>
      <v-card flat class="pa-5 my-5">
        <div>
          Please enter your information in each of the sections. Fields with an
          asterisk (*) are required and must be completed before you can submit
          your application. Additional questions may become required based on
          your answers to a previous question. Please remember to save your
          application regularly to avoid losing your answers.
        </div></v-card
      >

      <v-card class="pa-4" flat>
        <div class="descriptionBox">
          <p class="instructions">
            Please tell us about yourself, your interests, motivations and objectives for participating in this program. (max. 250 words)
          </p>
          <textarea
            style="max-height: 500px"
            cols="120"
            color="#4c248a"
            outlined
            v-model="application.aboutYourself"
            @keyup="checkAboutYourself(application.aboutYourself, 250)"
          ></textarea>
        </div>

        <div class="descriptionBox">
          <p class="instructions">
            According to the
            <a
              target="_blank"
              href="https://www.weforum.org/agenda/2023/05/future-of-jobs-2023-skills/"
              >World Economic Forum</a
            >, Ten (10) skills are required to thrive in the future of work. Please provide candid feedback on the three skills you actively seek to possess and why. (max. 200 words)
          </p>
          <textarea
            @keyup="checkWefEssay(application.wefEssay, 201)"
            color="#4c248a"
            outlined
            v-model="application.wefEssay"
          ></textarea>
        </div>

        <!-- <div class="descriptionBox">
          <p class="instructions">
            Do you believe inequality undermines the career trajectory of women
            in Africa or Asia? If yes, how is this so and what do you believe
            can be done to address this issue? If not, what is the justification
            for your answer? (max. 150 words).
          </p>
          <textarea
            style="max-height: 500px"
            cols="120"
            color="#4c248a"
            outlined
            v-model="application.inequalityEssay"
            @keyup="checkInequEssay(application.inequalityEssay, 151)"
          ></textarea>
        </div> -->

        <div class="descriptionBox">
          <p class="instructions">
            Why do you want to be a BMGA Fellow, what do you hope to gain from your time in the program, and what will you bring to the BMGA Fellows Program? Discuss the relevance of your future goals to your participation in this program. (max. 200 words)
          </p>
          <textarea
            style="max-height: 500px"
            cols="120"
            color="#4c248a"
            outlined
            v-model="application.whyEssay"
            @keyup="checkWhyEssay(application.whyEssay, 201)"
          ></textarea>
        </div>

        <div class="descriptionBox">
          <p class="instructions">
            Describe the challenge(s) you expect concerning your participation in the program. [State any obstacles that may prevent your enrolment or completion of the BMGA Fellows Program.] (max. 100 words)
          </p>
          <textarea
            style="max-height: 500px"
            cols="120"
            color="#4c248a"
            outlined
            v-model="application.challenges"
            @keyup="checkChallenges(application.challenges, 101)"
          ></textarea>
        </div>

        <!-- <div class="descriptionBox">
          <p class="instructions">
            Is there anything about your family background or your community
            that has significantly impacted who you are? (max 100 words).
          </p>
          <textarea
            style="max-height: 500px"
            cols="120"
            color="#4c248a"
            outlined
            v-model="application.familyEssay"
            @keyup="checkFamilyEssay(application.familyEssay, 101)"
          ></textarea>
        </div> -->

        <!-- <div class="descriptionBox">
          <p class="instructions">
            Who do you hope to be in five years? (max. 100 words).
          </p>
          <textarea
            style="max-height: 500px"
            cols="120"
            color="#4c248a"
            outlined
            v-model="application.fiveYearsEssay"
            @keyup="checkFiveYearEssay(application.fiveYearsEssay, 101)"
          ></textarea>
        </div> -->

        <div>
          <h4 class="brandcolor">Video Question</h4>
          <div>
            Create a 3-minute (maximum) video of yourself that responds to the prompt below and upload it on 
            YouTube as an unlisted video. Click <a 
              target="_blank" 
              href="https://support.google.com/youtube/answer/57407?hl=en&co=GENIE.Platform%3DDesktop">here</a> for tutorials on how to upload your video to YouTube. 
            After uploading it on YouTube, insert the link in the space provided below. 
            (Ensure that there are no distractions or noise in your environment. 
            Do not add any background sound or effects to your video. 
            Also, ensure that your YouTube video link provided is correct and accessible).
          </div>


          <div>
            **For large video sizes, click
            <a
              href="https://www.freeconvert.com/video-compressor"
              target="_blank"
              >Video Compressor | Reduce Video File Size Online</a
            >
            to compress the video before uploading it.
          </div>
        </div>

        <ul>
          <li>
            What is your vision for social, economic, and political change in
            your country/continent? What would be some ideal outcomes, both in
            the short-term and the long-term? How will the BMGA Fellows Program help you actualize this vision?
          </li>
        </ul>

        <v-text-field
          v-model="application.videoLink"
          class="mt-5"
          color="#4c248a"
          outlined
          dense
          placeholder="e.g https://youtu.be/2eX2SFFI0Ig"
          />
          <!-- :rules="videoRules" --> 

        <div class="mt-4">
          <h4 class="brandcolor">Resume*</h4>
          <div>A recent copy of your resume in PDF.</div>
          <a
            class="my-2"
            v-if="application.resume != ''"
            :href="application.resume"
            target="_blank"
            ><u>{{ userData.firstName }}'s resume. View uploaded file</u></a
          >

          <input
            @change="uploadResume"
            class="my-1"
            type="file"
            accept=".pdf"
          />
          <v-progress-circular
            v-if="isCvUpload"
            :value="percentageUpload"
            color="#4c248a"
          >
            <span class="percentageSize"
              >{{ percentageUpload.toFixed(0) }} %</span
            ></v-progress-circular
          >
        </div>

        <div class="mt-4">
          <h4 class="brandcolor">Reference Letter*</h4>
          <div class="instructions">
            Recommendation letter in PDF (Please check the guideline for your
            recommendation letter below).
          </div>
          <a
            class="my-2"
            v-if="application.referenceLetter != ''"
            :href="application.referenceLetter"
            target="_blank"
            ><u>{{ userData.firstName }}'s reference. View uploaded file</u></a
          >
          <input
            @change="uploadReference"
            class="my-1"
            type="file"
            accept=".pdf"
          />
          <v-progress-circular
            v-if="isReferenceUpload"
            :value="percentageReferenceUpload"
            color="#4c248a"
          >
            <span class="percentageSize"
              >{{ percentageReferenceUpload.toFixed(0) }} %</span
            ></v-progress-circular
          >
          <div class="instructions mt-1">
            <u>Guidelines for the Referee:</u>
          </div>
          <div class="instructions">The referee should state:</div>
          <ul>
            <li>Full Name</li>
            <li>Occupation</li>
            <li>On what capacity they know the candidate</li>
            <li>How long they have known the candidate</li>
            <li>Contact Details – should we wish to clarify anything</li>
          </ul>

          <div class="instructions mt-2">
            <u>We would particularly want the referee’s views on:</u>
          </div>
          <ul>
            <li>
              The attributes they feel would make the candidate an exceptional
              leader in the corporate sector.
            </li>
            <li>
              The career and development opportunity this program provides for
              the candidate, noting any aspects of the candidate’s academic or
              leadership abilities that would particularly benefit from a
              program like this.
            </li>
            <li>
              How the candidate will contribute in the future by leading or
              facilitating positive change on their continent and improving the
              lives of its people,
            </li>
            <li>
              If the candidate is likely to be an engaged, positive, and active
              contributor to the program as well as to the alumni network after
              graduation, or if the candidate is more passive, individualistic
              or self-driven.
            </li>
            <li>
              Whether there are any factors which may inhibit the candidate from
              committing to the program.
            </li> <br>
            <h4>
              <strong>
                **Please note that references from friends and family members do not qualify.
              </strong>
            </h4>
          </ul>
        </div>

        <div class="mt-12">
          <v-btn
            @click="updateApplication"
            :loading="loading"
            block
            x-large
            class="primaryBtn"
          >
            Save & Continue</v-btn
          >
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>
    
    
    <script>
import { mapState } from "vuex";
import AppBar from "./AppBar.vue";
import AppSidebar from "./AppSidebar.vue";
import axios from "axios";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase";

export default {
  data() {
    return {
      loading: false,
      isCvUpload: false,
      percentageUpload: "",
      percentageReferenceUpload: "",
      isReferenceUpload: "",
      aboutYourself: "",
      challenges: "",
      wefEssay: "",
      inequEssay: "",
      whyEssay: "",
      familyEssay: "",
      fiveYearEssay: "",
      videoRules:[
        (v) => !!v || "Video link is required",
        (v) =>/^https:\/\//.test(v) || "Please enter a valid video link",
      ]
    };
  },
  components: { AppBar, AppSidebar },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      userData: "userData",
      application: "application",
    }),
  },

  methods: {
    uploadResume(event) {
      this.uploadCv(event.target.files[0]);
      console.log(event);
    },

    uploadReference(event) {
      this.uploadReferenceFile(event.target.files[0]);
      console.log(event);
    },

    uploadCv(image) {
      this.isCvUpload = true;
      console.log(image);
      // Create the file metadata
      /** @type {any} */
      const storageRef = ref(storage, this.userData.email + "_resume");
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("i run", snapshot);
          this.percentageUpload =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(snapshot.bytesTransferred);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log(downloadURL);
            this.isCvUpload = false;
            this.application.resume = downloadURL;
            this.$swal({
              icon: "success",
              title: "Resume uploaded successfully",
              text: "Kindly ensure to click on next to save your upload to your application",
              confirmButtonText: `<p class="brandcolor">Ok</p>`,
            });
          });
        }
      );
    },

    uploadReferenceFile(image) {
      this.isReferenceUpload = true;
      console.log(image);
      // Create the file metadata
      /** @type {any} */
      const storageRef = ref(storage, this.userData.email + "_reference");
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("i run", snapshot);
          this.percentageReferenceUpload =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(snapshot.bytesTransferred);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log(downloadURL);
            this.isReferenceUpload = false;
            this.application.referenceLetter = downloadURL;
            this.$swal({
              icon: "success",
              title: "Reference uploaded successfully",
              text: "Kindly ensure to click on next to save your upload to your application",
              confirmButtonText: `<p class="brandcolor">Ok</p>`,
            });
          });
        }
      );
    },

    updateApplication() {
      this.loading = true;
      console.log(this.application._id);
      this.loading = true;
      const payload = {
        applicationId: this.application._id,
        challenges: this.application.challenges,
        aboutYourself: this.application.aboutYourself,
        wefEssay: this.application.wefEssay,
        inequalityEssay: this.application.inequalityEssay,
        whyEssay: this.application.whyEssay,
        familyEssay: this.application.familyEssay,
        fiveYearsEssay: this.application.fiveYearsEssay,
        videoLink: this.application.videoLink,
        resume: this.application.resume,
        referenceLetter: this.application.referenceLetter,
      };
      console.log(payload);
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_URL + "/update-application",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("bmgaToken"),
          ContentType: "application/json",
          Accept: "*/*",
        },
        data: payload,
      })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.$router.push("/application/marketing-information");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkWefEssay(word, wordcount) {
      if (word.trim().split(/\s+/).length >= wordcount) {
        this.application.wefEssay = this.wefEssay;
      } else {
        this.wefEssay = word;
      }
    },

    checkInequEssay(word, wordcount) {
      if (word.trim().split(/\s+/).length >= wordcount) {
        this.application.inequalityEssay = this.inequEssay;
      } else {
        this.inequEssay = word;
      }
    },

    checkWhyEssay(word, wordcount) {
      if (word.trim().split(/\s+/).length >= wordcount) {
        this.application.whyEssay = this.whyEssay;
      } else {
        this.whyEssay = word;
      }
    },

    checkAboutYourself(word, wordcount) {
      if (word.trim().split(/\s+/).length >= wordcount) {
        this.application.aboutYourself = this.aboutYourself;
      } else {
        this.aboutYourself = word;
      }
    },

    checkChallenges(word, wordcount) {
      if (word.trim().split(/\s+/).length >= wordcount) {
        this.application.challenges = this.challenges;
      } else {
        this.challenges = word;
      }
    },

    checkFamilyEssay(word, wordcount) {
      if (word.trim().split(/\s+/).length >= wordcount) {
        this.application.familyEssay = this.familyEssay;
      } else {
        this.familyEssay = word;
      }
    },

    checkFiveYearEssay(word, wordcount) {
      if (word.trim().split(/\s+/).length >= wordcount) {
        this.application.fiveYearsEssay = this.fiveYearEssay;
      } else {
        this.fiveYearEssay = word;
      }
    },
  },
};
</script>
    
    
    
    
<style scoped>
@media only screen and (max-width: 600px) {
  .topTitle {
    padding: 0px 0px 0px 15px;
  }
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    max-width: 400px;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
}

@media only screen and (min-width: 776px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 894px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 996px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 0px 0px 0px 190px;
  }
}

.needHelpCard {
  border-top: 6px solid #4c248a !important;
}

.v-text-field {
  height: 54px !important;
}
.instructions {
  font-size: 16px;
  margin: 0px 0px 10px 0px;
}

.checkBoxes {
  margin-top: -27px;
}

p,
li {
  font-size: 12px;
}

.descriptionBox {
  margin: 0px 0px 30px 0px;
}

.v-textarea,
textarea {
  resize: none !important;
}
textarea {
  width: 100%;
  outline: 1px solid #4c248a;
  border-radius: 5px;
  height: 250px;
  padding: 10px;
}

.percentageSize {
  font-size: 8px;
}
ul,li{
  font-size:15px  !important
}
</style>