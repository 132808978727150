<template>
  <div>
    <v-navigation-drawer
      fixed
      absolute
      permanent
      left
      style="padding: 30px 30px 0px 30px"
      class="hidden-md-and-down"
    >
     

      <v-list>
        <v-list-item-group color="#0582D2">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
            link
          >
            <v-list-item-icon style="margin-right: 20px">
              <v-icon color="#310059">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content :to="item.link">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logOut()">
            <v-list-item-icon style="margin-right: 20px">
              <v-icon color="#310059">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>


  </div>
</template>

<script>

export default {
  name: "NavMenu",
  data: () => ({
    items: [
      { title: "Dashboard", icon: "mdi-view-grid-outline", link: "/reviewer/dashboard" },
      { title: "Applications", icon: "mdi-file-multiple", link: "/reviewer/applications" }
    ],
    drawer: false,
    group: null,
    userData: "",
    name: "",
    image: "",
    notifications:[
    { title: "Jumoke Kolade-Johnson has a pending action for you. Check it out.", time: "13h", image: "" },
      { title: "Service downtime. Our third party service is currently experiencing a downtime. Full service to be restored soon", icon: "mdi-cog-outline", link: "/settings" },
    ]
    
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },

  computed: {
  },

  props: {},

  created() {

  },

  methods: {
    goToDispute(title) {
      if (title == "Dispute") {
        window.open("mailto:disputes@kuleanpay.com", "_blank");
      }
    },
    logOut() {
      sessionStorage.removeItem("reviewerToken")
      sessionStorage.removeItem("reviewerEmail")
      this.$router.push("/reviewer/login")
    },
  },
};
</script>


<style scoped>
.line {
  height: 1px;
  border: none;
  background: #efefef;
}
</style>