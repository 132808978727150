<template>
   <v-main style="margin:50px 0px 0px 200px">
     <v-card flat class="pa-5">
       <div class="formWidth">       
        <h1 class="my-3" style="color:#310059">Applicant: {{ admin.applicant.firstName }} {{ admin.applicant.lastName }}</h1>
      <v-form>
        <label>First Name</label>
        <v-text-field outlined color="#310059" readonly dense :value="admin.applicant.firstName"/> 

        <label>Last Name</label>
        <v-text-field outlined color="#310059" readonly dense :value="admin.applicant.lastName"/>

        <label>Email address</label>
        <v-text-field outlined color="#310059" readonly dense :value="admin.applicant.email"/>

        <label>Phone Number</label>
        <v-text-field outlined color="#310059" readonly dense :value="admin.applicant.phone"/>
      </v-form>
    </div>
</v-card>
    </v-main>
</template>


<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState(['admin'])
    }
}
</script>


<style scoped>
.formWidth{
    width:500px
}
</style>