<template>
    <v-app>
      <v-main class="signupForm">
      <v-card elevation="8" width="500px" class="rounded-lg mx-auto">
        <div class="signupCard" tile>
          <v-alert v-model="alert" type="error">{{ message }}</v-alert>
          <v-btn @click="goBack" depressed fab x-small class="white--text mb-3" color="#4c248a"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h2 class="brandcolor">Forgot Password</h2>
          <div class="mb-4">Enter your email to reset your password</div>
          <v-form @submit.prevent="forgotPassword" ref="form" v-model="valid" lazy-validation>
            <label>Email address</label>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              dense
              color="#4c248a"
              single-line
              outlined
            />

          </v-form>
  
          <div class="my-3">
            <v-btn :loading="loading" @click="forgotPassword" block x-large color="#4c248a" class="white--text"
              >Reset Password</v-btn
            >
          </div>
      </div>
  </v-card>
      </v-main>
    </v-app>
  </template>
  
  
  
  <script>
  import axios from 'axios'
  
  export default {
    data() {
      return {
        valid: true,
        email: "",
        emailRules: [
          (v) => !!v || "Email address is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        alert:false,
        message:"",
        loading:false
      };
    },
    methods: {

      goBack(){
        this.$router.go(-1)
      },
  
      async forgotPassword() {
        await this.$refs.form.validate();
        if(!this.valid){
          return
        }

        this.loading = true
        axios({
          method:"POST",
          url:process.env.VUE_APP_API_URL + "/forgot-password",
          headers:{
              Accept:"*/*",
              ContentType:"application/json"
          },
          data:{
              email:this.email,
          }
        }).then(()=>{
          this.loading = false
            this.$swal({
              icon:"success",
              title:"Email Sent Successful",
              text:"An email has been sent to you with instructions on how to reset your password",
              confirmButtonText:`<p class="brandcolor">Open Email</p>` 
          }).then((result)=>{
            if(result.isConfirmed){
                window.open("https://gmail.com","_target")
            }
          })

         
        }).catch((error)=>{
          this.loading = false
          console.log(error)
          this.$swal({
              icon:"error",
              title:"Email Reset Unsuccessful",
              text:error.response.data.message,
              confirmButtonText:`<p class="brandcolor">Ok</p>` 
          })
        })
  
  
      },
    },
  };
  </script>
  
  
  <style scoped>
  .signupForm {
    background-image:linear-gradient(to bottom, #8b369473, #4d248a6e),
    url('../../../assets/background-image.jpg');
    background-size: cover;
    padding: 120px !important;
  }
  
  .signupCard {
    padding: 20px 40px;
    background:white
  }
  
  label {
    font-size: 14px;
    margin-top:10px
  }
  
  .v-text-field {
    height: 54px !important;
  }
  
  span {
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    line-height: 12px;
  }
  
  h2 {
    margin-bottom: -3px;
  }
  
  .image::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(120deg, #8b3694, #4c248a);
      opacity: .7;
  }
  
  
  
  @media screen and (max-width: 600px) {
    .signupForm {
      background: #feeaf3;
      padding: 60px 15px !important;
    }
    .signupCard {
      padding: 20px 20px;
    }
  }
  </style>